import styled from 'styled-components';

// interface StepCircleProps {
//     active: boolean;
//   }

export const Title = styled.h2`
  font-size: 30px;
  color: #dd2256;
  font-family: 'Inter', sans-serif;
  text-align: center;
  width: 90%;
  // line-height:40px;
  margin: 0 auto;
  margin-bottom: 15px;
  @media (max-width: 1480px) {
      width: 80%;
    margin: 0 auto;
    font-size: 30px;
    margin-bottom: 5px;
    line-height: 35px;
  }
  @media (max-width: 1280px) {
    line-height: 35px;

    width: 90%;
    margin-bottom: 10px;
    margin: 0 auto;
    font-size: 22px;
    margin-bottom: 5px;
  }
  @media (max-width: 1024px) {
    line-height: 35px;

    font-size: 20px;
    margin-bottom: 5px;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const Subtitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 25px;
  text-align:center;
  @media (max-width: 1480px) {
    width: 70%;
    margin: 0 auto;
    font-size: 14px;
    color: #666;
      margin-bottom: 15px;

  }
  @media (max-width: 1280px) {
    font-size: 12px;
    width: 75%;
  }
  @media (max-width: 1024px) {
    font-size: 12px;
    width: 75%;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;

export const Subtitlep = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  padding: 0 80px;
  @media (max-width: 1280px) {
    margin-bottom: 5px;
    padding: 0 50px;
    margin-bottom: 5px;

    font-size: 12px;
    padding: 0 20px;
  }
  @media (max-width: 780px) {
    margin-bottom: 5px;
    padding: 0 60px;

    font-size: 12px;
  }
`;
export const Titlembile = styled.h2`
  display: none; /* Hide by default */

  @media (max-width: 780px) {
    font-family: 'Inter', sans-serif;
    width: 100%;

    display: block; /* Show on mobile */
    display:flex;
    justify-content:center;
    color: #dd2256;
    font-family: 'Inter', sans-serif;
    text-align: left; /* Align text to the left */
    margin: 0; /* Reset margins if necessary */
    font-size: 24px;
    line-height:30px;
     margin-top:15px;
    margin-bottom:15px;

  }
    @media (max-width: 480px) {
      font-size: 24px;
      width: 85%;
      margin:0 auto;
      font-size: 18px;
      line-height:30px;
      margin-top:10px;
      margin-bottom:10px;
    }
    @media (max-width: 380px) {
      width: 75%;
      margin:0 auto;

      font-size: 18px;
    }
  }
`;
export const Subtitlemobile = styled.p`
  display: none;
  @media (max-width: 780px) {
    width: 78%;
    font-family: 'Inter', sans-serif;

    display: block;
    color: #666;
    text-align: left; /* Center the text */
    font-size: 14px;
    margin-bottom: 5px;
    margin: 0 auto;
  }

  @media (max-width: 380px) {
    padding: 0 20px;
    font-size: 12px;
    margin: 0 auto;
    width: 75%%;
  }
`;
export const BackButtondiv = styled.div`
  display: none;

  @media (max-width: 780px) {
    font-family: 'Inter', sans-serif;

    display: flex;
    width: 81%;
    position: fixed;
    bottom: 0;
    left: 50%; /* Positioning to the center */
    transform: translateX(-50%); /* Center alignment */
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 10px; /* Padding inside the container */
    z-index: 1000;
    min-height: 100px; /* Set the minimum height here */
  }

  @media (max-width: 480px) {
    padding: 0; /* Adjust padding for smaller screens */
  }
`;

export const BackButton = styled.button`
  // background-color: transparent;
  background-color: #ffd6df;
  font-family: 'Inter', sans-serif;
  color: #dd2256;
  width: 140px;
  height: 50px;
  font: bold;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px; /* Margin for spacing between buttons */

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 160px;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px; /* Adjust spacing for smaller screens */
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 40px;
    font-size: 186x;
    border-radius: 15px;
    margin: 0 5px; /* Adjust spacing for smaller screens */
    margin-bottom: 20px;
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px; /* Slightly smaller font for very small screens */
    border-radius: 15px;
    margin-bottom: 10px;
  }
`;

export const BackButtonnext = styled.button`
  font-family: 'Inter', sans-serif;

  color: #fff;
  width: 140px;
  height: 50px;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px; /* Margin for spacing between buttons */
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);

  &:hover {
    background-color: #c51e4a;
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 160px;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px; /* Adjust spacing for smaller screens */
    margin-bottom: 20px;
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 40px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px; /* Adjust spacing for smaller screens */
    margin-bottom: 20px;
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px; /* Slightly smaller font for very small screens */
    border-radius: 15px;
    margin-bottom: 10px;
  }
`;

export const StyledInput = styled.input`
  font-family: inter;
  width: 80%;
  margin: 0 auto;
  padding: 1rem 0.5rem;
  margin-bottom: 1.5rem;
  border-radius: 25px;
  border: none;
  background-color: #ffe6eb; // Light pinkish background
  font-size: 1.5rem;
  color: #dd2256;
  outline: none;

  &::placeholder {
    color: #dd2256;
  }

  &:focus {
    border-color: #f857a6;
  }
`;

// Style for the social icon within the button
export const SocialIcon = styled.span`
  margin-right: 10px;
  transition: color 0.3s ease; /* Smooth transition for color change */
`;

// Style for the social icon button
export const SocialIconconnect = styled.button`
  font-family: inter;
  font-size: 16px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  color: #fff;
  background-color: #dd2256;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #fff;
    color: #dd2256;
  }
`;
export const SocialButton = styled.button`
  display: flex;
  width: 90%;
  margin-top: 20px;
  margin: 0 auto;
  align-items: center;
  background-color: white;
  color: #dd2256;
  border: 1px solid #dd2256;
  margin-bottom: 15px;
  font-family: inter;
  font-size: 16px;
  padding: 12px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
  &:hover {
    background-color: #dd2256;
    color: white;
  }
  @media (max-width: 1280px) {
    margin-top: 0px;
    font-size: 20px;
    padding: 10px;

    margin-top: 0;
  }
  @media (max-width: 780px) {
    font-size: 20px;
    padding: 10px;
    margin-bottom: 10px;

    margin-top: 0;
  }
`;
export const SocialText = styled.span`
  flex-grow: 1;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;

  @media (max-width: 780px) {
    font-size: 18px;
  }
`;
export const ContentContainer = styled.div`
  padding-bottom: 40px; /* Adjust the padding to ensure space for the fixed button container */
  width: 100%;
  // overflow-y: auto;
  @media (max-width: 780px) {
    padding-bottom: 120px; /* More padding for mobile view */
  }
`;

export const DesktopBackButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  background: #fff;
  z-index: 1000;
  // border-radius: 15px 15px 0 0;
  border-radius: 15px;
  min-height: 100px;
  margin: 0 auto;
  @media (max-width: 780px) {
    display: none;
  }
`;

export const NextButtonContainer = styled.div`
  // flex: 1;  
  width: 100%;
  display:flex;
  justfy-content:center:
  border-radius: 15px;
  margin-left:15px;
`;

export const Button = styled.button`
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  font-family: 'Inter', sans-serif;

  color: white;
  border: none;
  border-radius: 15px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  padding: 13px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #c51e4a;
  }

  @media (max-width: 1220px) {
    padding: 10px;

  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const BackCancelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; /* Ensure container takes full width */
  margin-bottom: 30px;
  // gap: 10px;
`;

export const DesktopBackButton = styled.button`
  background-color: #fff;
  width: 30%;
  color: #dd2256;
  margin: 0 auto;
  padding: 15px 30px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #dd2256;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(0); /* Reset lift on click */
  }
`;

export const DesktopCancelButton = styled.button`
  background-color: #fff;
  color: #dd2256;
  padding: 15px 35px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #dd2256;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #dd2256;
    color: #fff;
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(0); /* Reset lift on click */
  }

  @media (max-width: 945px) {
    // padding: 12px 25px;
  }
`;

export const ToogleToolsWrapper = styled.div<{ backgroundColor?: string }>`
  width: 85%;
  padding-left: 30px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#f8f9fa'};
  border-radius: 10px;
  margin: 0 auto;

  @media (max-width: 780px) {
    padding: 15px;
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    margin-top: 10px;
  }
`;
