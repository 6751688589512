import axios from '../lib/axios';
import { notification, notificationError } from '../lib/notification';

export const changePassword = async (updatePassword: {
  oldPassword: string;
  password: string;
  repeatPassword: string;
}) => {
  try {
    const response = await axios.put('/users/change-password', {
      oldPassword: updatePassword.oldPassword,
      newPassword: updatePassword.password,
    });

    notification({
      type: 'success',
      title: 'Success',
      description: 'Password was changed',
    });
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Old password is wrong',
    });
    return error;
  }
};
