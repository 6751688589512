import axios from '../lib/axios';
import { notification, notificationError } from '../lib/notification';

interface UpdateUser {
  email: string;
  firstName: string;
  lastName: string;
}

export const updateUser = async (id: number, updatedData: UpdateUser) => {
  try {
    const response = await axios.put(`/users/${id}`, updatedData);
    notification({
      type: 'success',
      title: 'Success',
      description: 'User was updated',
    });
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'User could not be updated',
    });
    return error;
  }
};
