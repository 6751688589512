import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

export const getMedia = async (companyId: number | undefined) => {
  try {
    if (companyId === undefined) {
      return undefined;
    }
    const response = await axios.get(`/media/company/${companyId}`);

    return response;
  } catch (error) {
    return error;
  }
};

export const removeMedia = async (id: number | undefined) => {
  try {
    const response = await axios.delete(`/media/${id}`);
    return response;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: 'File could not be deleted',
    });
    return err;
  }
};

export const getMediaSizes = async (companyId: number | undefined) => {
  try {
    const response = await axios.get(`/media/sizes/${companyId}`);
    return response;
  } catch (error) {
    return error;
  }
};
