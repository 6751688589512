import axios from '../lib/axios';

export const getToolsCompany = async () => {
  try {
    const response = await axios.get(`/tools`);
    return response;
  } catch (error) {
    return error;
  }
};

export const getToolsCompanyObj = async (id: number | undefined) => {
  try {
    const response = await axios.get(`/tools/company/${id}/obj`);
    return response;
  } catch (error) {
    return error;
  }
};
