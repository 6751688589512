/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import { differenceInCalendarDays, isBefore } from 'date-fns';
import { useMutation, useQueryClient } from 'react-query';
import FacebookIcon from '../../assets/icons/facebookRed.svg';
import InstagramIcon from '../../assets/icons/instagramRed.svg';
import { formatPromotionDate } from '../../lib/format';
import { STATUS_PROMOTIONS } from '../../lib/globalValues';
import { getCurrencySymbol, getImage, stringToFloat } from '../../lib/helpers';
import { updateStatusAdvert } from '../../services/facebookTools';
import OutlineButton from '../outlineButton/OutlineButton';
import {
  AdItem,
  Container,
  Content,
  Image,
  ImageContainer,
  Platform,
  PlatformContainer,
  PlatformsContainer,
  Title,
  TitleContainer,
} from './Advert.styles';

interface Props {
  advert: Advert;
  currency: string;
  itemId: string;
}

interface PropsStatus {
  status:
    | 'ACTIVE'
    | 'PAUSED'
    | 'DELETED'
    | 'PENDING_REVIEW'
    | 'DISAPPROVED'
    | 'PREAPPROVED'
    | 'PENDING_BILLING_INFO'
    | 'CAMPAIGN_PAUSED'
    | 'ARCHIVED'
    | 'ADSET_PAUSED'
    | 'IN_PROCESS'
    | 'WITH_ISSUES';
  budget?: string;
  adId?: string;
  endDate: Date;
}

const promotionStatus = ({
  effectiveStatus,
  budgetRemaining,
  endDate,
}: {
  effectiveStatus: string;
  budgetRemaining: string;
  endDate: Date;
}) => {
  if (isBefore(endDate, new Date())) {
    return 'Completed';
  }

  switch (effectiveStatus) {
    case STATUS_PROMOTIONS.ACTIVE:
      return 'Active';

    case STATUS_PROMOTIONS.PENDING_REVIEW:
    case STATUS_PROMOTIONS.IN_PROCESS:
      return 'In Review';

    case STATUS_PROMOTIONS.CAMPAIGN_PAUSED:
      return budgetRemaining === '0' ? 'Completed' : 'Paused';

    case STATUS_PROMOTIONS.DISAPPROVED:
      return 'Disapproved';

    case STATUS_PROMOTIONS.WITH_ISSUES:
      return 'With issues';

    default:
      return 'Paused';
  }
};

const canStatusUpdated = ({ status, budget, endDate }: PropsStatus) => {
  if (
    (status === STATUS_PROMOTIONS.PAUSED ||
      status === STATUS_PROMOTIONS.ACTIVE ||
      status === STATUS_PROMOTIONS.IN_PROCESS ||
      status === STATUS_PROMOTIONS.PENDING_REVIEW) &&
    budget !== '0' &&
    !isBefore(endDate, new Date())
  ) {
    return true;
  }
  return false;
};

const reverseStatus = ({ status }: { status: string }) => {
  switch (status) {
    case STATUS_PROMOTIONS.ACTIVE:
    case STATUS_PROMOTIONS.PENDING_REVIEW:
    case STATUS_PROMOTIONS.IN_PROCESS:
      return 'Pause';

    default:
      return 'Unpause';
  }
};

const totalBudgetPerDays = ({
  start_time,
  stop_time,
  daily_budget,
}: {
  start_time: Date;
  stop_time: Date;
  daily_budget: string;
}) => {
  if (!stop_time || !start_time || !daily_budget) return null;
  const days = differenceInCalendarDays(stop_time, start_time);
  return (days * stringToFloat(daily_budget)).toFixed(2);
};

const platformIcon = {
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  messenger: '',
};

const Advert = ({ advert, currency, itemId }: Props) => {
  const queryClient = useQueryClient();
  const currencySymbol = getCurrencySymbol(currency);

  const { mutateAsync: handleUpdateStatus } = useMutation(
    ({ status, adId }: { status: string; adId: string }) =>
      updateStatusAdvert({ status, adId }),
    {
      onSuccess(response) {
        queryClient.setQueryData<AdAccount | undefined>(
          'ADVERTS',
          (oldData: AdAccount | undefined) => {
            if (oldData && response.adId) {
              const indice = oldData.ads.data.findIndex(
                (n: Advert) => n.id === response.adId,
              );

              oldData.ads.data[indice].effective_status = response.status;

              return oldData;
            }

            return oldData;
          },
        );
      },
    },
  );

  const { campaign } = advert;

  const totalSpend = advert.insights?.data[0].spend || '0';
  const { start_time, stop_time, daily_budget } = campaign;

  const lifetimeBudget = campaign.lifetime_budget
    ? stringToFloat(campaign.lifetime_budget).toFixed(2)
    : totalBudgetPerDays({ start_time, stop_time, daily_budget });

  return (
    <Container>
      <ImageContainer>
        <Image src={getImage(advert.creative?.thumbnail_url)} alt="Ad Image" />
        <PlatformsContainer>
          {advert.targeting?.publisher_platforms
            .filter(platform => platform !== 'messenger')
            .map(platform => (
              <PlatformContainer key={platform}>
                <Platform src={platformIcon[platform]} alt="Platform Icon" />
              </PlatformContainer>
            ))}
        </PlatformsContainer>
      </ImageContainer>
      <Content>
        <TitleContainer>
          <Title>{advert.name}</Title>
          <span className="status">
            {promotionStatus({
              effectiveStatus: advert.effective_status,
              budgetRemaining: campaign.budget_remaining,
              endDate: advert.campaign.stop_time,
            })}
          </span>
        </TitleContainer>
        <AdItem>
          <div className="left">
            <div className="row">
              <span>Start:</span>
              <p>
                {campaign.start_time
                  ? formatPromotionDate(campaign.start_time)
                  : 'N/A'}
              </p>
            </div>
            <div className="row">
              <span>End:</span>
              <p>
                {campaign.stop_time
                  ? formatPromotionDate(campaign.stop_time)
                  : 'N/A'}
              </p>
            </div>
            <div className="row">
              <span>Budget:</span>
              <p>
                {lifetimeBudget ? `${currencySymbol}${lifetimeBudget}` : 'N/A'}
              </p>
            </div>
            <div className="row">
              <span>Spent:</span>
              <p>
                <p>{`${currencySymbol}${parseFloat(totalSpend).toFixed(
                  2,
                )} `}</p>
              </p>
            </div>
          </div>
          <div className="right">
            <div className="row">
              <span>Reach:</span>
              <p>{advert.insights?.data[0].reach || 0}</p>
            </div>
            <div className="row">
              <span>Link Clicks:</span>
              <p>{advert.insights?.data[0].inline_link_clicks || 0}</p>
            </div>
            <div className="row">
              <span>CPC:</span>
              <p>
                {advert.insights?.data[0].cost_per_inline_link_click
                  ? `${currencySymbol}${parseFloat(
                      advert.insights?.data[0].cost_per_inline_link_click,
                    ).toFixed(2)}`
                  : 'N/A'}
              </p>
            </div>
            <div className="row">
              {canStatusUpdated({
                status: advert.effective_status,
                budget: campaign.budget_remaining,
                endDate: advert.campaign.stop_time,
              }) && (
                <OutlineButton
                  title={reverseStatus({ status: advert.effective_status })}
                  onClick={() =>
                    handleUpdateStatus({
                      status: advert.effective_status,
                      adId: advert.id,
                    })
                  }
                />
              )}
            </div>
          </div>
        </AdItem>
      </Content>
    </Container>
  );
};

export default Advert;
