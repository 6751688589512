const CLEANER_VALUES = [null, undefined, ''];

export const objectCleaner = (
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  _object: any,
  _CLEANER_VALUES = CLEANER_VALUES,
) => {
  const cleanedObj = { ..._object };
  Object.keys(cleanedObj).forEach(key => {
    if (_CLEANER_VALUES.includes(cleanedObj[key])) {
      delete cleanedObj[key];
    }
  });

  return cleanedObj;
};
