import { useState } from 'react';
import Close from '../../assets/icons/cancelRed.svg';
import { CloseIcon, Text, WarningContainer } from './Warning.styles';

interface IProps {
  text: string;
}

const Warning = ({ text }: IProps) => {
  const [show, setShow] = useState(true);

  if (show === false) return null;

  return (
    <WarningContainer>
      <CloseIcon
        src={Close}
        alt="close warning"
        onClick={() => setShow(false)}
      />
      <Text>{text}</Text>
    </WarningContainer>
  );
};

export default Warning;
