/* eslint-disable @typescript-eslint/no-unused-vars */

import { FlexboxGrid, Col } from 'rsuite';
import styled from 'styled-components';

// export const CardTool = styled(FlexboxGrid)`
//   padding: 15px;
//   border-radius: 20px;
//   background-color: #efefef;
//   border: 1px solid black;
// `;
interface StyledProps {
  signup?: boolean;
  show?: boolean;
  status?: number;
}

export const CardToolsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;

  .wrapper {
    position: relative;
  }

  .img-parent {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;

export const SmallSocialNetworkIcon = styled.img<StyledProps>`
  position: ${({ show }) => (show ? 'absolute' : '')};
  width: ${({ show }) => (show ? '20px' : '40px')};
  height: ${({ show }) => (show ? '20px' : '40px')};
  bottom: -4px;
  right: ${({ show }) => (show ? '-4px' : '')};
  /* background: ${({ show }) => (show ? 'black' : '')}; */
  border-radius: 50%;
  padding: ${({ show }) => (show ? '1px' : '')};
`;

export const CardTool = styled(FlexboxGrid)<StyledProps>`
  //  padding: 15px;
  border-radius: 20px;
  background-color: #efefef;
  border: 1px solid black;
  padding: ${({ signup }) =>
    signup ? '10px' : '15px'}; // Adjust padding if signup is true

  background-color: ${({ signup }) =>
    signup ? '#fff' : 'initial'}; // Different background color for signup
  border: ${({ signup }) =>
    signup ? '1px solid #DD2256' : 'intital'}; // Different border for signup
  // justify-content: ${({ signup }) =>
    signup ? 'center' : 'initial'}; // Center content for signup
  // align-items: ${({ signup }) =>
    signup ? 'center' : 'initial'}; // Align items center for signup

  @media (max-width: 1480px) {
    padding: ${({ signup }) =>
      signup ? '10px' : '15px'}; // Adjust padding if signup is true
    border: ${({ signup }) =>
      signup ? '1px solid #DD2256' : 'intital'}; // Different border for signup
    border-radius: ${({ signup }) =>
      signup ? '15px' : '20px'}; // Different border for signup
  }
`;

// export const Paragraph = styled.p`
//   font-size: ${props => props.theme.fonts.paragraph};
//   color: ${props => props.theme.colors.greyLetter};
//   font-family: 'Poppins';
//   text-transform: capitalize;
// `;
export const Paragraph = styled.p<StyledProps>`
  font-size: ${props => props.theme.fonts.paragraph};
  // color: ${props => props.theme.colors.greyLetter};
  font-family: 'Poppins';
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.fonts.paragraph};

  color: ${({ theme, signup }) => (signup ? 'blue' : theme.colors.greyLetter)};
  justify-content: ${({ signup }) =>
    signup ? 'center' : 'initial'}; // Center text for signup
  align-items: ${({ signup }) =>
    signup ? 'center' : 'initial'}; // Align text center for signup
`;

// export const ParagraphColor = styled(Paragraph)<{ status?: number }>`
//   color: ${props => props.status === 1 && props.theme.colors.darkGrey};
//   color: ${props => props.status === 0 && props.theme.colors.red};
// `;
export const ParagraphColor = styled.p<StyledProps>`
  color: ${({ theme, signup, status }) => {
    // if (signup) {
    //   return theme.colors.blue;
    // }
    if (status === 1) {
      return theme.colors.darkGrey;
    }
    return theme.colors.red;
  }};
  width: ${({ signup }) => (signup ? '20%' : 'auto')};
  font-size: ${({ signup }) => (signup ? '12px' : 'auto')};
  font-family: ${({ signup }) => (signup ? "'Inter', sans-serif" : 'inherit')};
  @media (max-width: 1480px) {
    width: ${({ signup }) => (signup ? '20%' : 'auto')};
    font-size: ${({ signup }) => (signup ? '12px' : 'auto')};
  }
`;

export const ParagraphColor4 = styled.p<StyledProps>`
  color: black;
  font-size: 10px;
  font-weight: 800;
`;

export const ParagraphColor2 = styled.p<StyledProps>`
  color: ${({ theme, signup, status }) => {
    // if (signup) {
    //   return theme.colors.blue;
    // }
    if (status === 1) {
      return theme.colors.darkGrey;
    }
    return theme.colors.red;
  }};
  font-size: 12px;
  margin-top: 5px;
  margin-left: 20px;
  /* Text wrapping settings */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* Limit to 2 lines */
  &::after {
    content: attr(title);
    position: absolute;
    left: 0;
    bottom: -20px; /* Adjust based on tooltip positioning needs */
    color: #fff;
    padding: 15px;
    // border-radius: 3px;
    font-size: 10px;
    white-space: nowrap;
    display: block;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none;
  }

  &:hover::after {
    opacity: 1;
  }

  font-weight: bold; /* Apply bold font weight */
  // // width: ${({ signup }) => (signup ? '20%' : 'auto')};
  // font-size: ${({ signup }) => (signup ? '12px' : 'auto')};
  // // font-family: ${({ signup }) =>
    signup ? "'Inter', sans-serif" : 'inherit'};
  // // @media(max-width:1480px){
  // //   width: ${({ signup }) => (signup ? '20%' : 'auto')};
  //   font-size: ${({ signup }) => (signup ? '10px' : 'auto')};

  // // }
  @media (max-width: 1020px) {
    -webkit-line-clamp: 1; /* Limit to 2 lines */
  }
  @media (max-width: 780px) {
    -webkit-line-clamp: 1; /* Limit to 2 lines */
  }
`;

// TooltipText styled component
export const TooltipText = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  /* Arrow styling */
  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;
export const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${TooltipText} {
    visibility: visible;
    opacity: 1;
  }
`;
// export const SocialNetworkIcon = styled.img`
//   width: 50px;
//   height: 50px;
// `;
export const SocialNetworkIcon = styled.img<StyledProps>`
  // width: 50px;
  // height: 50px;
  width: ${({ signup }) =>
    signup ? '40px' : '50px'}; // Adjust padding if signup is true

  height: ${({ signup }) => (signup ? '40px' : '50px')};

  @media (max-width: 1480px) {
    width: ${({ signup }) =>
      signup ? '40px' : '34px'}; // Adjust padding if signup is true

    height: ${({ signup }) => (signup ? '40px' : '34px')};
  }

  @media (max-width: 590px) {
    width: ${({ signup }) => (signup ? '35px' : '34px')};

    height: ${({ signup }) => (signup ? '35px' : '34px')};
  }
`;

export const StyledFlexboxItem = styled(FlexboxGrid.Item)<StyledProps>`
  ${({ signup }) =>
    signup
      ? `
        background-color: #f0f0f0; /* Example: Change background when signup is true */
        padding: 20px; /* Example: Add extra padding */
        border: 2px solid #007bff; /* Example: Add a border */
      `
      : `
        padding: 15px; /* Default padding */
        border: 1px solid #ccc; /* Default border */
      `}
`;
