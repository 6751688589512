import { Button, Modal } from 'rsuite';
import {
  ModalPosition,
  ModalSubtitle,
  ModalTitle,
} from '../modalTools/ModalTools.styles';

interface Props {
  show: boolean;
  description: string;
  title: string;
  onClose: () => void;
}

const ComingSoonModal = ({ show, title, description, onClose }: Props) => {
  return (
    <ModalPosition show={show} onHide={onClose}>
      <Modal.Header>
        <ModalTitle>{title}</ModalTitle>
        <ModalSubtitle>{description}</ModalSubtitle>
      </Modal.Header>
      <Modal.Body />
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Go back
        </Button>
      </Modal.Footer>
    </ModalPosition>
  );
};

export default ComingSoonModal;
