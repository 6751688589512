import { Button } from 'rsuite';
import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  background-color: white;
  padding: 20px 15px;
  border-bottom: thin solid ${props => props.theme.colors.grey};
  display: flex;
  align-items: center;
  
  @media screen and (max-width: 700px) {
    align-items: flex-start;
  }
`;

export const ReelImage = styled.img`
  width: 125px;
  max-height: 75px;
  object-fit: cover;
  cursor: pointer;

  @media ${props => props.theme.breakpoints.maxMedium} {
    width: 50px;
    max-height: 50px;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
  width: 125px;
  max-height: 75px;

  @media ${props => props.theme.breakpoints.maxMedium} {
    width: 50px;
    max-height: 50px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 15px;
  word-break: break-all;

  @media ${props => props.theme.breakpoints.maxMedium} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ImageTextContainer = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  margin-left: 15px;

  @media screen and (max-width: 700px) {
    margin-left: 0;
    align-items: flex-start;
  }
`;

export const NetworkIcon = styled.img`
  width: 25px;
`;

export const CtaContainer = styled.div`
  min-width: 80px;
  margin-right: 15px;
  position: relative;

  button {
    padding: 10px 15px;
    border-radius: 12px;
  }

  @media screen and (max-width: 700px) {
    width: 50px;

    button {
      padding: 5px 10px;
      font-size: 0.85em;
      position: absolute;
      bottom: 0px;
      left: -5px;
    }
  }
`;

export const DateContainer = styled.div`
  min-width: 165px;
  @media screen and (max-width: 1300px) {
    margin: 10px auto;
    margin-left: 75px;
  }
`;

export const TextContainer = styled.div`
  margin: 0 25px;
  display: flex;
  flex-grow: 1;
`;

export const ButtonNotification = styled(Button)`
  background-color: #e74138; //Theme red
  padding: 12px 20;
  color: #ffffff; // Theme white
  margin-top: 15px;
`;

export const ReelDate = styled.span`
  font-size: 12px;
`;

export const ReelText = styled.p`
  font-size: 12px;
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const VideoImage = styled.img`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 20px;
`;
