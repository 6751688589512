import Cropper from 'react-cropper';
import styled from 'styled-components';

export const CropperSize = styled(Cropper)<{ isInvalid: boolean }>`
  width: 100%;
  max-height: 500px;

  .cropper-point {
    background-color: ${props =>
      props.isInvalid ? props.theme.colors.red : props.theme.colors.green};
  }
`;
