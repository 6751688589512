import { format } from 'date-fns';
import { useQuery } from 'react-query';
import { FlexboxGrid, Loader, Table } from 'rsuite';
import axios from '../../lib/axios';
import OrderStatus from '../orderStatus/OrderStatus';

const fetchOrders = async (): Promise<Order[]> => {
  try {
    const { data } = await axios.get('/orders');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const Orders = () => {
  const {
    isLoading,
    isError,
    data: orders,
  } = useQuery(`ORDERS`, fetchOrders, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
    retry: false,
  });

  if (isLoading) return <Loader content="loading" />;

  if (!orders?.length) {
    return <p>No orders</p>;
  }

  return (
    <div>
      {!isLoading && !isError && (
        <Table
          height={600}
          data={orders}
          rowHeight={60}
          style={{ marginTop: 20 }}>
          {/* @ts-ignore */}
          <Table.Column flexGrow={1} verticalAlign="middle" align="left">
            <Table.HeaderCell>Order</Table.HeaderCell>
            <Table.Cell dataKey="orderId">
              {(rowData: Order) => (
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={12}>
                    <p>#{rowData.orderId}</p>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              )}
            </Table.Cell>
          </Table.Column>
          {/* @ts-ignore */}
          <Table.Column flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.Cell dataKey="date">
              {(rowData: Order) => (
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={12}>
                    <p>{format(rowData.orderedAt, 'DD MMMM, hh:mm')}</p>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              )}
            </Table.Cell>
          </Table.Column>
          {/* @ts-ignore */}
          <Table.Column flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>Accepted</Table.HeaderCell>
            <Table.Cell dataKey="accepted">
              {(rowData: Order) => (
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={12}>
                    <OrderStatus status={rowData.status} />
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
      )}
    </div>
  );
};

export default Orders;
