/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useState } from 'react';
import { Input, InputGroup } from 'rsuite';
import { ErrorMessage, HintMessage, InputIcon } from '../../forms/Forms.styles';
import {
  InputContainer2,
  Label,
  LabelContainer,
} from '../../forms/formSignUp/fieldInput/FieldInput.styles';
import { EyeSpan } from './PasswordInput.styles';
import eyeicon from '../../../assets/icons/hide.png';

interface Props {
  signUp: boolean;
  name: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  error?: any;
  onKeyUp?: () => void;
  label?: string;
  showPasswordRequirements?: boolean;
}

const PasswordInput = ({
  signUp,
  error,
  onKeyUp,
  onChange,
  onBlur,
  name,
  placeholder,
  label,
  showPasswordRequirements,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputContainer2>
      <LabelContainer>
        {label && <Label htmlFor={name}>{label}</Label>}
      </LabelContainer>
      <InputGroup inside>
        <InputIcon
          signUp
          placeholder={placeholder}
          type={showPassword ? 'text' : 'password'}
          name={name}
          error={error}
          onChange={(e: any) => {
            if (onChange) onChange(e);
          }}
          onBlur={onBlur}
          onKeyUp={onKeyUp}
          style={{ paddingRight: '80px', boxShadow: 'none' }}
        />
        <InputGroup.Button
          onClick={() => setShowPassword(!showPassword)}
          style={{
            cursor: 'pointer',
            backgroundColor: 'transparent',
            position: 'absolute',
            left: '85%',
            top: '50%',
            transform: 'translateY(-50%)',
          }}>
          <img
            src={eyeicon}
            alt={showPassword ? 'Hide password' : 'Show password'}
            style={{ width: '20px', display: 'block' }}
          />
        </InputGroup.Button>
      </InputGroup>
      {/* {error && <ErrorMessage signUp={signUp}>{error}</ErrorMessage>} */}
      {(!error || error === '') && showPasswordRequirements && (
        <HintMessage>
          Password must be between 8 and 32 characters and it requires a capital
          letter, a lower case letter, and a number in any order.
        </HintMessage>
      )}
    </InputContainer2>
  );
};

export default PasswordInput;
