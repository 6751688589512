import styled from 'styled-components';

export const Title = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: 550;
  margin-top: 10%;

  @media ${props => props.theme.breakpoints.xs} {
    margin-top: 50%;
  }
`;
