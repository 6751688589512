import { Formik } from 'formik';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { FormContainer } from '../../components/forms/Forms.styles';
import ForwordIcon from '../../assets/images/arrow_forward.png';
import {
  EvaluatePassword,
  FragmentError,
} from '../../components/forms/formSignUp/FormSignUp.styles';
import PasswordInput from '../../components/inputs/PasswordInput/PasswordInput';
import axios from '../../lib/axios';
import { PASSWORD_ERROR_MESSAGE } from '../../lib/globalValues';
import { notification, notificationError } from '../../lib/notification';
import {
  ButtonContainer,
  ButtonSign,
  ResetFormContainer,
} from './ResetPassword.style';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

const DInabiteResetPassword = () => {
  const { width } = useWindowDimensions();
  const [secure, setSecure] = useState(0);
  const location = useLocation();
  const history = useHistory();

  const token = location.search.split('=')[1];

  const lowerCaseRegExp = /[a-z]/;
  const capitalRegExp = /[A-Z]/;
  const numbersRegExp = /[0-9]/;

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .matches(lowerCaseRegExp, PASSWORD_ERROR_MESSAGE)
      .matches(capitalRegExp, PASSWORD_ERROR_MESSAGE)
      .matches(numbersRegExp, PASSWORD_ERROR_MESSAGE)
      .min(8, PASSWORD_ERROR_MESSAGE)
      .max(32, PASSWORD_ERROR_MESSAGE)
      .required('Password is required'),
    repeatPassword: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match',
    ),
  });

  const validatePassword = (pass: string) => {
    let value = 0;

    if (lowerCaseRegExp.test(pass)) {
      value += 1;
    }
    if (capitalRegExp.test(pass)) {
      value += 1;
    }
    if (numbersRegExp.test(pass)) {
      value += 1;
    }

    if (pass.length >= 8) {
      value += 1;
    }

    setSecure(value);
  };

  const initialValues = useMemo(
    () => ({
      password: '',
      repeatPassword: '',
    }),
    [],
  );

  return (
    <ResetFormContainer>
      <span className="form-title">Enter New Password</span>
      <Formik
        initialValues={initialValues}
        validationSchema={resetPasswordSchema}
        validateOnChange
        onSubmit={async values => {
          try {
            await axios.post('/auth/change-password', {
              token,
              password: values.password,
            });
            notification({
              type: 'success',
              title: 'Success',
              description: 'You can log in with new password',
            });
            history.push('/login');
          } catch (err) {
            notificationError({
              title: 'Error',
              description:
                err?.response?.data?.message || 'Could not update password',
            });
          }
        }}
        validateOnMount>
        {({ touched, errors, values, handleSubmit }) => (
          <>
            <FormContainer>
              <PasswordInput
                signUp={false}
                placeholder="Password"
                name="password"
                error={touched.password && errors.password}
                onKeyUp={() => validatePassword(values.password)}
                showPasswordRequirements
                label="Password"
              />

              <EvaluatePassword percent={secure} justify="space-around">
                <FragmentError colspan={4} />
                <FragmentError colspan={4} />
                <FragmentError colspan={4} />
                <FragmentError colspan={4} />
              </EvaluatePassword>

              <PasswordInput
                signUp={false}
                placeholder="Repeat password"
                name="repeatPassword"
                error={touched.repeatPassword && errors.repeatPassword}
                label="Confirm Password"
              />
            </FormContainer>
            <ButtonContainer>
              <ButtonSign block type="submit" onClick={handleSubmit}>
                <span>Reset Password</span>

                {width && width > 768 && (
                  <img src={ForwordIcon} alt="Forword Icon" />
                )}
              </ButtonSign>
            </ButtonContainer>
          </>
        )}
      </Formik>
    </ResetFormContainer>
  );
};

export default DInabiteResetPassword;
