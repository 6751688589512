import { Button } from 'rsuite';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  border-radius: 15px;
  padding: 30px;
  margin-top: 25px;

  h2 {
    text-transform: uppercase;
    color: ${props => props.theme.colors.greyLetter};
    font-size: ${props => props.theme.fonts.subtitle};
    font-family: 'Poppins';
  }

  h1 {
    color: ${props => props.theme.colors.black};
    font-size: 20px;
    margin-bottom: 35px;
    font-family: 'Poppins';
    font-weight: 600;
  }

  @media ${props => props.theme.breakpoints.xs} {
    width: 100%;
  }
`;

export const ParentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;

  @media ${props => props.theme.breakpoints.xs} {
    height: 50vh;
  }
`;

export const ButtonAdd = styled(Button)`
  width: 100%;
  background-color: ${props => props.theme.colors.red};
  padding: 12px 20px;
  border-radius: 100px;
  color: ${props => props.theme.colors.white};

  span:first-child {
    padding-right: 10px;
    font-weight: bolder;
  }
`;
