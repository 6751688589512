import styled from 'styled-components';

export const Container = styled.div`
    position:relative;
  height: 100%; 
  
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #fff; /* Darker color on hover */
  }

  &::-webkit-scrollbar-track {
    background: #fff; /* Background of the scrollbar track */
  }
`;