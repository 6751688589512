/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Col, FlexboxGrid, Loader, Row } from 'rsuite';
import Add from '../../assets/icons/add.svg';
import SocialCard from '../../components/cards/socialCard/SocialCard';
import DrawerPost from '../../components/drawers/DrawerPost/DrawerPost';
import Post from '../../components/post/Post';
import ScheduledPost from '../../components/scheduledPost/ScheduledPost';
import usePosts from '../../hooks/usePosts';
import useScheduledPosts, {
  KEY_SCHEDULED_POSTS,
} from '../../hooks/useScheduledPosts';
import { notificationError } from '../../lib/notification';
import { fetchFollowers } from '../../services/followers';
import { removePost } from '../../services/posts';
import { Container } from '../../styles/generalStyles';
import {
  ButtonAdd,
  FollowersContainer,
  Header,
  HeaderFlexbox,
  PostsContainer,
  Test,
} from './Posts.styles';
import { useCurrentPostStore } from '../../store';

const TABLE_TYPES_POSTS = {
  SCHEDULED: 'SCHEDULED',
  PREVIOUS: 'PREVIOUS',
};

const Posts = () => {
  const history = useHistory();
  const currentPostStore = useCurrentPostStore();
  const { isLoading, isError, isFetching, data: posts } = usePosts();
  const {
    isLoading: isLoadingScheduled,
    isError: isErrorScheduled,
    data: scheduledPosts,
  } = useScheduledPosts();
  const [dataPostSelected, setDataPostSelected] = useState<Post | null>(null);
  const [showTable, setShowTable] = useState(TABLE_TYPES_POSTS.PREVIOUS);
  const [updatePost, setUpdatePost] = useState(0);

  useEffect(() => {
    currentPostStore.setPost(dataPostSelected);
  }, [dataPostSelected]);

  const {
    isLoading: isLoadingFollowers,
    isError: isErrorFollowers,
    data: followers,
  } = useQuery('followers', fetchFollowers, {
    refetchOnWindowFocus: false,
    onError: (err: Error) => {
      notificationError({ description: err.message, title: 'Error' });
    },
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading: isLoadingRemovePost } = useMutation(
    (selectedPostId: number) => removePost(selectedPostId),
    {
      onSuccess(response) {
        queryClient.setQueryData(KEY_SCHEDULED_POSTS, (oldData: any) => {
          return [
            ...oldData.filter(
              (post: ScheduledPosts) => post.id !== response.id,
            ),
          ];
        });
      },
    },
  );

  const handleRemoveScheduledPost = (scheduledPostId: number) => {
    mutate(scheduledPostId);
  };

  const handleUpdate = async () => {
    queryClient.invalidateQueries('POSTS');
  };

  useEffect(() => {
    if (updatePost) {
      handleUpdate();
    }
  }, [updatePost]);

  return (
    <Container>
      <FlexboxGrid align="middle" style={{ height: '80px'}}>
      <Col xs={24} md={17}>
          <FlexboxGrid align="middle" justify="space-around">
            <FlexboxGrid.Item
              componentClass={Col}
              xs={24}
              sm={7}
              md={5}
              style={{ marginBottom: 20 }}>
              <ButtonAdd
                type="button"
                onClick={() => setShowTable(TABLE_TYPES_POSTS.PREVIOUS)}>
                <span>Previous</span>
              </ButtonAdd>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              componentClass={Col}
              xs={24}
              sm={7}
              md={5}
              style={{ marginBottom: 20 }}>
              <ButtonAdd
                type="button"
                onClick={() => setShowTable(TABLE_TYPES_POSTS.SCHEDULED)}>
                <span>Scheduled</span>
              </ButtonAdd>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              componentClass={Col}
              xs={24}
              sm={10}
              md={12}
              style={{ marginBottom: 20 }}>
              <ButtonAdd onClick={() => history.push('posts/new')}>
                <span>New Post</span>
                <img src={Add} alt="add post" />
              </ButtonAdd>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Col>
      </FlexboxGrid>
      <Row style={{ height: 'calc(100% - 80px)'}}>
        <Test style={{ height: '100%'}}>
          <Col xs={24} lg={17} style={{height: '100%'}}>
            {showTable === TABLE_TYPES_POSTS.SCHEDULED && (
              <>
                {isLoadingScheduled && <Loader center content="loading" />}
                {!isLoadingScheduled &&
                  !isErrorScheduled &&
                  !isLoadingRemovePost &&
                  scheduledPosts && (
                    <>
                      <HeaderFlexbox align="middle" style={{ height: '80px' }}>
                        <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={5}>
                          Social networks
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={5}>
                          Image
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={5}>
                          Text
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={6}>
                          Scheduled date
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={3}>
                          Cancel
                        </FlexboxGrid.Item>
                      </HeaderFlexbox>
                      <PostsContainer style={{ height: 'calc(100% - 80px)'}}>
                        {scheduledPosts?.map(post => (
                          <ScheduledPost
                            post={post}
                            handleRemoveScheduledPost={
                              handleRemoveScheduledPost
                            }
                          />
                        ))}
                      </PostsContainer>
                    </>
                  )}
              </>
            )}
            {showTable === TABLE_TYPES_POSTS.PREVIOUS && (
              <>
                {(isLoading || isFetching) && (
                  <Loader center content="loading" />
                )}
                {!isLoading && !isFetching && !isError && posts && (
                  <>
                    <Header style={{ height: '80px' }}>
                      <div className="image-header">Image</div>
                      <div className="text-header">Text</div>
                      <div className="posted-header">Posted</div>
                    </Header>
                    <PostsContainer style={{ height: 'calc(100% - 80px)' }}>
                      {posts.map(post => (
                        <Post
                          key={post.id}
                          post={post}
                          setDataPostSelected={setDataPostSelected}
                        />
                      ))}
                    </PostsContainer>
                  </>
                )}
              </>
            )}
          </Col>
          {isLoadingFollowers && <Loader content="loading" />}
          <Col xs={24} lg={7} style={{height: 'auto',marginBottom: '40px' }}> 
            <FollowersContainer>
              {!isLoadingFollowers &&
                !isErrorFollowers &&
                followers?.map((totalCard: Followers) => (
                  <SocialCard
                    key={totalCard.type + totalCard.followers}
                    data={totalCard}
                  />
                ))}
            </FollowersContainer>
          </Col>
        </Test>
      </Row>
      <DrawerPost
        showDrawer={dataPostSelected}
        setShowDrawer={setDataPostSelected}
        setUpdatePost={setUpdatePost}
      />
    </Container>
  );
};

export default Posts;
