import { FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

export const CellImg = styled(FlexboxGrid.Item)<{ isimage: boolean }>`
  justify-content: flex-start;
  img {
    background-color: ${props => props.theme.colors.red};
    width: 50px;
    object-fit: cover;
    border-radius: 10px;
  }

  video {
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
  }
  
  @media (max-width: 768px) {
    img, video {
      width: 100%;
      height: auto;
    }
  }
`;

export const ImageLink = styled.a`
  color: ${props => props.theme.colors.red};
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 14px;           // Adjust font size for mobile
    text-align: center;        // Center align text
    display: block;            // Ensure the link behaves like a block-level element
    width: 100%;  
}
`;

export const PreviewVideo = styled.div`
  position: relative;
  
  img {
    position: absolute;
    top: 40%;
    left: 50%;
    background-color: transparent;
    width: 20px;
    border-radius: 0;
  }

  video {
    height: 50px;
    object-fit: cover;
    border-radius: 10px;
  }
  @media (max-width: 768px) {
    img {
      display: none; // Hide video icon on mobile if needed
    }
  }
`;

export const CardContainer = styled.div`
  display: none; /* Hide by default */

  @media (max-width: 768px) {
    display: flex;               /* Use flexbox */
    flex-direction: row;         /* Align items horizontally */
    overflow-y: auto;            /* Allow horizontal scrolling if needed */
    height: 40rem;              /* Fixed height for the container */
    padding: 10px;              /* Optional: Add padding */
    gap: 10px;                  /* Space between cards */
    align-items: flex-start;    /* Align cards to the start of the container */
    }
`;
export const Card = styled.div`
  flex: 0 0 auto;               /* Prevent cards from shrinking or growing */
  width: 300px;                 /* Fixed width for cards */
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;       /* Ensure padding and border are included in width/height */
    background-color: #efefef;  

  img, video {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    margin: 0 10px;              /* Add horizontal margin between cards */
    padding: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  }
 @media (max-width: 430px) {
}

`;

export const DesktopTable = styled.div`
  display: block;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileCards = styled.div`
  display: none;  /* Hide by default */

  @media (max-width: 768px) {
    display: block;         /* Show on mobile */
    height: 40rem;          /* Adjust height as needed */
    overflow-y: auto;       /* Allow vertical scrolling */
    padding: 10px;          /* Optional: Add padding */
  }
`;

export const StyledDiv = styled.div`
  text-align: center};
  width: 100%;
`;
export const StyledLink = styled.a`
  color: blue; /* Example style: set the link color to blue */
  text-decoration: none; /* Example style: remove underline from link */
  
  &:hover {
    text-decoration: underline; /* Example style: underline on hover */
  }
`;