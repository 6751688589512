import styled from 'styled-components';
import { Modal, Button } from 'rsuite';

export const ContainerCropImage = styled.div`
  text-align: center;

  button {
    margin: 20px 0px;
    width: 50%;
    @media ${props => props.theme.breakpoints.xs},
      ${props => props.theme.breakpoints.sm} {
      width: 100%;
    }
  }
`;

export const ModalPosition = styled(Modal)`
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    position: absolute;
    bottom: 0px;
    width: 100%;

    div.rs-modal-dialog {
      margin: 0px;
    }
  }
`;

export const ButtonRed = styled(Button)`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  border-radius: 50px;
  padding: 20px;
  width: 100%;
`;

export const ButtonGrey = styled(ButtonRed)`
  background-color: ${props => props.theme.colors.grey};
  color: ${props => props.theme.colors.white};
`;
