/* eslint-disable @typescript-eslint/no-explicit-any */
import { TYPES_POSTS } from '../actions/postsActions';

export const initialPostsState: {
  type: 'image' | 'video';
  id: null | number;
  name: string;
  originalUrl: null | string;
  previewUrl: null | string;
  file: null | File;
  files: Array<{
    type: 'image' | 'video';
    name: string;
    urlFile: string;
    file: {
      preview: string;
    };
  }>;
} = {
  type: 'image',
  name: 'defaultImage.jpeg',
  id: null,
  originalUrl: null,
  previewUrl: null,
  file: null,
  files: [],
};

export function postsReducer(state: any, action: any) {
  switch (action.type) {
    case TYPES_POSTS.ADD_ID:
      return {
        ...state,
        id: action.payload,
      };

    case TYPES_POSTS.ADD_FILE:
      return {
        ...state,
        file: action.payload,
        name: action.payload.name || 'default',
      };

    case TYPES_POSTS.SET_IS_MULTIPLE:
      return {
        ...state,
        files: [...state.files, action.payload],
      };

    case TYPES_POSTS.REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter(
          (_: any, index: number) => index !== action.payload.index,
        ),
      };

    case TYPES_POSTS.ADD_VIDEO_URL:
      return {
        ...state,
        type: 'video',
        file: null,
        previewUrl: action.payload,
      };

    case TYPES_POSTS.ADD_IMAGE_URL:
      return {
        ...state,
        id: action.payload.id || null,
        type: 'image',
        name: action.payload.name || 'defaultImage.jpeg',
        file: null,
        previewUrl: action.payload.urlFile,
        originalUrl: action.payload.urlFile,
      };

    case TYPES_POSTS.CROP_IMAGE:
      return {
        ...state,
        previewUrl: action.payload,
        id: null,
      };

    default:
      return state;
  }
}
