import { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { getReviewsTools } from '../services/googleBusinessTools';

const useReviews = () => {
  const { companySelected } = useAuth();
  const [dataReviews, setDataReviews] = useState<Reviews | null>(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (!companySelected?.id) {
      return;
    }

    const getReviews = async () => {
      const { data } = await getReviewsTools(companySelected.id);

      if (data) {
        setDataReviews(data);
      }

      setLoading(false);
    };
    getReviews();
  }, [companySelected]);

  return {
    loading,
    dataReviews,
    setDataReviews,
    companyId: companySelected?.id,
  };
};

export default useReviews;
