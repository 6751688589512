import { Link } from 'react-router-dom';
import { Button, FlexboxGrid, InputGroup } from 'rsuite';
import styled from 'styled-components';

export const HiddenGroup = styled(InputGroup)<{ page: boolean }>`
  ${props => (props.page ? 'display: none;' : 'display: block;')};
`;

export const HiddenDiv = styled.div<{ page: boolean }>`
  > *:not(.rs-input-group-inside) {
    margin-bottom: 20px;
     @media (max-width: 375px) {
         margin-bottom: 10px;

    }
  }
  border: none;
  ${props => (props.page ? 'display: none;' : 'display: block;')}
`;

export const ButtonPage = styled(Button)<{ page: boolean }>`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  border-radius: 50px;
  padding: 20px;

  ${props => (props.page ? 'display: none;' : 'display: block;')}

  background-color: ${props =>
    props.next ? props.theme.colors.red : props.theme.colors.grey};
`;

export const EvaluatePassword = styled(FlexboxGrid)<{ percent: number }>`
  > div {
    color: grey;
  }

  ${props =>
    props.percent
      ? ` div:nth-child(-n + ${props.percent}) {
        border-color: ${props.theme.colors.red};
      }`
      : null}
`;

export const FragmentError = styled(FlexboxGrid.Item)`
  border: 2px solid ${props => props.theme.colors.grey};
`;

export const SignUpFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
  height: auto;
  gap: 25px;

  /* @media (max-width: 900px) {
    max-width: 390px;
  } */

  .form-title {
    color: #db1d5a;
    font-weight: 700;
    font-size: 20px;
    line-height: 22.3px;
    bottom: 0;

    @media ${props => props.theme.breakpoints.mm} {
      text-align: center;
    }

    @media ${props => props.theme.breakpoints.xs} {
      font-size: 14px;
      padding-bottom: 7px;
    }
  }

  @media ${props => props.theme.breakpoints.mm} {
    margin: 0 auto;
    gap: 0;
    padding: 0px 10px;
  }
`;

export const SignUpParagraph = styled(Link)`
  display: flex;
  gap: 5px;
  font-size: ${props => props.theme.fonts.paragraph};
  font-weight: 800;
  text-decoration: none !important;
  width: 100%;

  span:nth-child(1) {
    color: ${props => props.theme.colors.black};
  }
  span:nth-child(2) {
    color: ${props => props.theme.colors.red};
  }
`;
