import * as Yup from 'yup';
import { PASSWORD_ERROR_MESSAGE } from '../../lib/globalValues';
import {
  capitalRegExp,
  emailRegExp,
  lowerCaseRegExp,
  numbersRegExp,
  // phoneRegExp,
} from '../../lib/regExp';
import { findUser } from '../../services/findUser';

export const userInfoSchema = (initialEmail: string | undefined) =>
  Yup.object().shape({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    email: Yup.string()
      .email('Not valid email')
      .required('Email is required')
      .test(
        'checkUniqueEmail',
        'User with that email already exists',
        async value => {
          if (value && emailRegExp.test(value)) {
            try {
              if (initialEmail !== value) {
                const { data } = await findUser(value);
                if (data?.length) {
                  return false;
                }
              }

              return true;
            } catch (err) {
              return false;
            }
          }
          return false;
        },
      ),
  });

// export const changePasswordSchema = Yup.object().shape({
//   password: Yup.string()
//     .matches(lowerCaseRegExp, PASSWORD_ERROR_MESSAGE)
//     .matches(capitalRegExp, PASSWORD_ERROR_MESSAGE)
//     .matches(numbersRegExp, PASSWORD_ERROR_MESSAGE)
//     .min(8, PASSWORD_ERROR_MESSAGE)
//     .max(32, PASSWORD_ERROR_MESSAGE),
//   repeatPassword: Yup.string().oneOf(
//     [Yup.ref('password'), null],
//     'Passwords must match',
//   ),
// });
const validateNewPassword = (value: string | undefined, context: Yup.TestContext) => {
  const { oldPassword } = context.parent as { oldPassword: string };
  return value !== undefined && value !== oldPassword;
};

export const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Old password is required'),
  password: Yup.string()
    .matches(lowerCaseRegExp, PASSWORD_ERROR_MESSAGE)
    .matches(capitalRegExp, PASSWORD_ERROR_MESSAGE)
    .matches(numbersRegExp, PASSWORD_ERROR_MESSAGE)
    .min(8, PASSWORD_ERROR_MESSAGE)
    .max(32, PASSWORD_ERROR_MESSAGE)
    .required('New password is required')
    .test('not-same-as-old-password', 'New password must be different from old password', validateNewPassword),
  repeatPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Repeat password is required'),
});
export const userCompanySchema = Yup.object().shape({
  name: Yup.string().required('Company is required'),
  // phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid'),
  phone: Yup.string()
  .matches(/^\d+$/, 'Phone number must only contain Numbers')
  .min(7, 'Phone number must be at least 7 digits')
  .max(15, 'Phone number must not exceed 15 digits')
  .required('Phone is required'),
  //   .required('Phone is required'),
  phoneCode: Yup.string().required('Country code is required'),
  country: Yup.string().required('Country is required'),
  // address: Yup.string().required('Address is required'),
  // city: Yup.string().required('City is required'),
  // zipCode: Yup.string().required('Post code is required'),
  webLink: Yup.string(),
});

export const billingEmailSchema = Yup.object().shape({
  billingEmail: Yup.string().email('Invalid email format'),
});
// export const zipCodeSchema = Yup.string()
//   .matches(/^\d+$/, 'Post code must be numeric')
//   .required('Post code is required');