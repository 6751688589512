import { useMutation, useQueryClient } from 'react-query';
import { removeSocialPost } from '../services/posts';

const useDeleteSocialPost = () => {
  const queryClient = useQueryClient();
  const mutate = useMutation(
    ({ postId, socialNetwork }: { postId: string; socialNetwork: string }) =>
      removeSocialPost(postId, socialNetwork),
    {
      //  staleTime: 5000,
      //   notifyOnChangePropsExclusions: ['isStale'],
      //   refetchOnWindowFocus: false,
      onSuccess(response) {
        if (response instanceof Error) {
          // return { status: false };
          // console.log("error");
        } else if (response.status !== true) {
          // console.log("error");
        } else if (response && 'postId' in response) {
          queryClient.setQueryData('POSTS', (oldData: Post[] | undefined) => {
            return [
              ...(oldData?? []).filter((post: Post) => post.id !== response.postId),
            ];
          });
          // return { status: true };
        } else {
          // return { status: false };
        }
      },
    },
  );
  return mutate;
};

export default useDeleteSocialPost;
