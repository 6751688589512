import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Field, Form } from 'formik';
import { Button, InputGroup } from 'rsuite';

interface SocialButtonProps {
  signUP?: boolean;  // Define the prop type here
}
export const LoginFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
  height: auto;
  gap: 25px;

  /* @media (max-width: 900px) {
    max-width: 390px;
  } */

  .form-title {
    color: #db1d5a;
    font-weight: 700;
    font-size: 20px;
    line-height: 22.3px;
    bottom: 0;

    @media ${props => props.theme.breakpoints.mm} {
      text-align: center;
    }

    @media ${props => props.theme.breakpoints.xs} {
      font-size: 14px;
      padding-bottom: 7px;
    }
  }

  @media ${props => props.theme.breakpoints.mm} {
    margin: 0 auto;
    padding: 0px 10px;
    gap: 0;
  }
`;

export const InputIcon = styled(Field)<{ error: boolean }>`
  width: 100%;
  background-color: transparent;
  color: black;

  height: 50px;

  box-shadow: 0px 3px 10px 5px #0000000a inset;

  ${props =>
    props.error
      ? `border: 2px solid ${props.theme.colors.red};`
      : `border: 2px solid ${props.theme.colors.grey};`}

  border: 1px solid #dc2058;

  border-radius: 10px;
  padding: 7px 11px;
  padding-right: 12px;
  font-size: ${props => props.theme.fonts.paragraph};
  line-height: 1.42857143;

  :focus,
  :hover {
    /* border-color: ${props => props.theme.colors.gradient}; */
    outline: 0;
  }

  ::placeholder {
    color: ${props => props.theme.colors.darkGrey};
    opacity: 1;
  }

  ::-ms-input-placeholder {
    color: ${props => props.theme.colors.darkGrey};
    opacity: 1;
  }
`;

export const IconInInput = styled(InputGroup.Addon)`
  /* padding: 18px !important; */
`;

export const FormContainer = styled(Form)`
  > *:not(.rs-input-group-inside) {
    margin-bottom: 20px;
  }
  position: relative;
  /* margin-bottom: 60px; */
`;

export const ButtonSign = styled(Button)`
  display: flex;
  justify-content: space-around;
  align-items: center;

  background: ${props =>
    props.disabled
      ? props.disabled
      : 'linear-gradient(104.42deg, #db1d5a 23.85%, #ea4435 87.27%)'};
  /* background: linear-gradient(104.42deg, #db1d5a 23.85%, #ea4435 87.27%); */
  border: ${props => (props.disabled ? props.disabled : '1px solid #ea4435')};
  /* border: 1px solid #ea4435; */
  /* background-color: ${props => props.theme.colors.red}; */
  color: ${props => props.theme.colors.white};
  /* font-weight: bolder; */
  border-radius: 10px;
  padding: 14px 0px;
  /* font-size: ${props => props.theme.fonts.subtitle}; */
  max-width: 207px;

  /* margin: 65px auto; */

  span {
    transform: translate(0px, 1px);
    color: ${props => (props.disabled ? props.disabled : '#fff')};
    /* color: #fff; */
    font-size: 16px;
  }
  img {
    transform: translate(7px, 0px);
  }
  :hover {
    color: ${props => props.theme.colors.white};
  }

  @media ${props => props.theme.breakpoints.mm} {
    max-width: none;
  }
`;

export const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fonts.paragraph};
  text-align: left;
  margin-top: 5px;

   @media (max-width: 370px) {
    margin-top: 0;
    font-size: 0.75rem; /* Adjust the font size as needed for better readability */
    line-height: 1.2; /* Optional: Adjust line height for better spacing */
  }

`;

export const HintMessage = styled.p`
  color: ${props => props.theme.colors.darkGrey};
  font-size: ${props => props.theme.fonts.paragraph};
  text-align: left;
  margin-top: 5px;
`;

export const OrContainer = styled.div<{ message?: boolean | string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 12px 0px;
  gap: 15px;

  span {
    width: 74px;
    height: 1px;
    border-radius: 1px;
    background: #00000099;
  }
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;

  @media ${props => props.theme.breakpoints.mm} {
    justify-content: space-between;
  }

  @media ${props => props.theme.breakpoints.xs} {
    flex-wrap: wrap;
  }
`;

export const SocialButton = styled.button<SocialButtonProps>`
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center;     /* Center content vertically */
    width: ${({ signUP }) => (signUP ? '70%' : '95%')}; /* Conditional width */
    padding: ${({ signUP }) => (signUP ? '15px' : '20px')}; /* Conditional padding */

  margin: 15px auto;
  background-color: white;
  color: #DD2256;
  border: 1px solid #DD2256;
  // padding: 20px;
  border-radius: 15px;
  cursor: pointer;
  // font-size: 2rem;
  
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
  text-align: center; /* Center text horizontally within button */
  gap: 5px; /* Space between icon and text */

  &:hover {
    background-color: #DD2256;
    color: white;
  }

  img {
    width: 24px;
    height: 24px;
  }

  .button-title {
    /* No need for flex-grow here */
    text-align: center; /* Center text horizontally within button */
    // font-size: 1rem; /* Adjust font size if needed */
    font-size: ${({ signUP }) => (signUP ? '1rem' : '1rem')}; /* Conditional width */
      @media (max-width: 1480px) {
        font-size: ${({ signUP }) => (signUP ? '0.9rem' : '1rem')}; /* Conditional width */
      }
      @media (max-width: 1480px) {
        font-size: ${({ signUP }) => (signUP ? '0.8rem' : '1rem')}; /* Conditional width */
      }
      @media (max-width: 1280px) {
        font-size: ${({ signUP }) => (signUP ? '0.8rem' : '1rem')}; /* Conditional width */
      }
      @media (max-width: 1020px) {
        font-size: ${({ signUP }) => (signUP ? '0.8rem' : '1rem')}; /* Conditional width */
      }
      @media (max-width: 720px) {
        font-size: ${({ signUP }) => (signUP ? '0.8rem' : '1rem')}; /* Conditional width */
      }
      @media (max-width: 480px) {
        font-size: ${({ signUP }) => (signUP ? '0.8rem' : '1rem')}; /* Conditional width */
      }
      @media (max-width: 320px) {
        font-size: ${({ signUP }) => (signUP ? '0.8rem' : '1rem')}; /* Conditional width */
      }
  }

  @media (max-width: 1480px) {
    width: ${({ signUP }) => (signUP ? '60%' : '95%')}; /* Conditional width */
    padding: ${({ signUP }) => (signUP ? '7px' : '20px')}; /* Conditional padding */
    border-radius: ${({ signUP }) => (signUP ? '12px' : '15px')}; /* Conditional width */
    
    }
  @media (max-width: 1280px) {
      width: ${({ signUP }) => (signUP ? '65%' : '95%')}; /* Conditional width */
      padding: ${({ signUP }) => (signUP ? '10px' : '20px')}; /* Conditional padding */
  }
  @media (max-width: 780px) {
      // padding: ${({ signUP }) => (signUP ? '10px' : '15px')}; /* Conditional padding */
      // font-size: ${({ signUP }) => (signUP ? '0.6rem' : '1rem')}; /* Conditional width */

  }

  @media (max-width: 480px) {
    width: ${({ signUP }) => (signUP ? '80%' : '95%')}; /* Conditional width */

    padding: 10px;
    font-size: 12px;
  }
  @media (max-width: 380px) {
    padding: 10px;
    font-size: 12px;
  }
`;

export const LoginParagraph = styled(Link)`
  display: flex;
  gap: 5px;
  font-size: ${props => props.theme.fonts.paragraph};
  font-weight: 800;
  text-decoration: none !important;

  span:nth-child(1) {
    color: ${props => props.theme.colors.black};
  }
  span:nth-child(2) {
    color: ${props => props.theme.colors.red};
  }
`;

export const LinkForm = styled(Link)`
  /* font-size: ${props => props.theme.fonts.subtitle}; */
  font-size: 12px;
  color: ${props => props.theme.colors.red};
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 700;
  font-family: 'Comformaa', Helvetica, Sans-Serif;

  :hover {
    color: ${props => props.theme.colors.red};
  }
`;
