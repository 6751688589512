import CancelIcon from '../../assets/icons/cancel.svg';
import CheckIcon from '../../assets/icons/check.svg';
import { Container, Icon } from './OrderSstatus.styles';

interface Props {
  status: string;
}

const OrderStatus = ({ status }: Props) => {
  const STATUS_ICON = {
    1: <Container />,
    2: <Icon src={CheckIcon} />,
    3: <Icon src={CancelIcon} />,
  };

  return STATUS_ICON[parseInt(status, 10) as keyof typeof STATUS_ICON];
};

export default OrderStatus;
