import axios from '../lib/axios';

export const fetchFollowers = async (): Promise<Followers[]> => {
  try {
    const { data } = await axios.get('/tools/followers');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
