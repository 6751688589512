import styled from 'styled-components';

export const PostContainer = styled.div`
  display: flex; /* Ensure this is a flex container */
  padding: 10px;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  // transition: transform 0.4s ease-in-out, box-shadow 0.4s ease-in-out;
  height:auto;
  border-radius: 10px;
  position: relative; /* Use relative positioning if needed */
  border: 1px solid #e5e1e1;
  margin-bottom: 0.5rem;
  @media (max-width: 1024px) {
  }
  @media (max-width: 768px) {
    padding: 15px; /* Adjust padding for smaller screens */
  }

  @media (max-width: 480px) {
    padding: 10px; /* Further reduce padding if necessary */
    max-width: 100%; /* Allow full width on smaller screens */
  }
`;

export const Networkdiv = styled.div`
  flex: 0 0 5%; /* Adjust this value if necessary */
  display: flex; /* Make sure it behaves like a flex container */
`;
export const NetworkIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  @media (max-width: 992px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 576px) {
    width: 25px;
    height: 25px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1; /* Allow to grow and shrink */
    min-height: 150px;
  width: 95%;
`;
export const ImageTextContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center; /* Align items vertically */
  width: 100%;
`;
export const ImageContainer = styled.div`
  // background-color:blue;
    min-height: 150px;
  cursor: pointer;
  flex: 0 0 40%; /* Fixed size with flexbox */

  @media (max-width: 1280px) {
    flex: 0 0 40%;
  }
  @media (max-width: 1024px) {
    flex: 0 0 40%; /* Adjust for medium screens */
  }

  @media (max-width: 768px) {
    flex: 0 0 30%; /* Adjust for small screens */
    margin-bottom: 10px; /* Add some space below the image */
  }
  @media (max-width: 428px) {
    flex: 0 0 30%; /* Adjust for small screens */
    margin-bottom: 10px; /* Add some space below the image */
  }
`;
export const PostImage = styled.img`
  width: 100%;
  max-height: 150px;
  max-width: 150px;
  object-fit: cover;
  border-radius: 2px;

  @media (max-width: 1024px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 992px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 768px) {
    width: 150px;
    height: 150px;
  }
  @media (max-width: 576px) {
    width: 150px;
    height: 120px;
  }
`;

export const VideoImage = styled.img`
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  @media (max-width: 1024px) {
    width: 15px;
    height: 15px;
  }
`;

export const TextContainer = styled.div`
  // background-color: green;
  flex: 1; /* Allow to grow and fill remaining space */

  @media (max-width: 1280px) {
    flex: 1; /* Allow to grow and fill remaining space */
  }
  @media (max-width: 1024px) {
    flex: 1; /* Allow to grow and fill remaining space */
  }
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 1.5;
    -webkit-line-clamp: 6; /* Limit to 6 lines */
  }
  @media (max-width: 768px) {
    flex: 1; /* Allow to grow on small screens */
  }
`;

export const PostText = styled.p`
  font-family: 'Comformaa', Helvetica, Sans-Serif;
  color: black;
  line-height: 1.5;
  font-size: 12px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5; /* Limit to 5 lines */
  overflow: hidden; /* Hide overflow text */

  @media (max-width: 1280px) {
    font-size: 10px;
    line-height: 1.3;
  }

  @media (max-width: 1024px) {
    font-size: 10px;
  }
  @media (max-width: 992px) {
    font-size: 14px;
    line-height: 1.5;
    -webkit-line-clamp: 6; /* Limit to 6 lines */
  }
  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 1.3;
    -webkit-line-clamp: 4; /* Limit to 4 lines */
  }
  @media (max-width: 576px) {
    font-size: 10px;
    line-height: 1.3;
    -webkit-line-clamp: 3; /* Limit to 3 lines */
  }
`;

export const DateContainer = styled.div`
  margin-top: 10px;
`;

export const PostDate = styled.p`
  color: ${props => props.theme.colors.grey};
  font-size: 12px;
`;

export const CtaContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 8px;
    color: ${props => props.theme.colors.red};
  }
`;

export const ButtonNotification = styled.button`
  background-color: ${props => props.theme.colors.red};
  border: none;
  color: ${props => props.theme.colors.white};
  padding: 8px 16px;
  border-radius: 100px;
  cursor: pointer;
`;
export const IconContainer = styled.div`
  display: inline-block; /* Ensure it behaves like a block element */

  &:hover img {
    filter: brightness(0); /* Change to black on hover */
  }
`;

export const Iconstyle = styled.img`
  width: 20px;
  height: 20px;
  filter: brightness(0) saturate(100%) invert(27%) sepia(9%) saturate(325%)
    hue-rotate(153deg) brightness(99%) contrast(8%);
  transition: filter 0.3s ease; /* Smooth transition */
`;
