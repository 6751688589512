import styled, { createGlobalStyle } from 'styled-components';
import { Button, ButtonGroup, DatePicker, Loader } from 'rsuite';
import { POSITION } from '../../lib/globalValues';

export const ButtonNotification = styled(Button)`
  background-color: #e74138; //Theme red
  padding: 12px 20;
  color: #ffffff; // Theme white
  margin-top: 15px;
`;

export const ButtonAdd = styled(Button)`
  width: 100%;
  background-color: ${props => props.theme.colors.red};
  padding: 12px 0px;
  border-radius: 100px;
  color: ${props => props.theme.colors.white};

  span:first-child {
    padding-right: 10px;
    font-weight: bolder;
  }
`;
export const ButtonAdd2 = styled(Button)`
  min-width: 8rem;
  background-color: ${props => props.theme.colors.red};
  padding: 12px 0px;
  border-radius: 100px;
  color: ${props => props.theme.colors.white};

  span:first-child {
    padding-right: 10px;
    font-weight: bolder;
  }
`;

export const ButtonWrapper = styled('div')`
  display: flex;
  gap: 20px;
  span:first-child {
    font-size: 11px;
  }
`;

export const AiTextDisplayContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  span {
    font-weight: 700;
    font-size: 14px;
    padding: 0px 6px;
  }

  button {
    /* background: transparent; */
    p {
      color: ${props => props.theme.colors.black};
      text-align: justify;
      font-size: 16px;
    }
  }
`;

export const TextWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ButtonTags = styled(Button)`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  padding: 12px 20px;
  border-radius: 100px;
  margin: 30px 0px 8px;

  &:disabled {
    background-color: #dbdbdb;
    color: #65676b;
  }

  span:first-child {
    font-weight: bolder;
    font-size: 14px;
  }
`;

const BasicTitle = styled.p`
  color: ${props => props.theme.colors.black};
  font-weight: bolder;
`;

export const TagsDisplayContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px;

  .span {
    font-weight: 700;
    font-size: 14px;
    padding: 0px 6px;
  }
`;

export const TagsDisplayWrapper = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button span {
    color: ${props => props.theme.colors.facebook};
  }

  button:hover span {
    text-decoration: underline;
  }
`;

export const Subtitle = styled(BasicTitle)`
  font-size: ${props => props.theme.fonts.bigSubtitle};
  margin-bottom: 25px;
`;

export const TitleForm = styled(BasicTitle)`
  font-size: ${props => props.theme.fonts.paragraph};
  text-transform: uppercase;
  margin: 25px 0px;
`;

export const TitleButton = styled(Button)`
  margin: 25px 0px;
  padding: 15px;
  background-color: ${props => props.theme.colors.grey};
`;

export const ClickableTitleForm = styled(TitleForm)`
  margin: 0;
  padding: 0;
`;

export const ContainerPreview = styled.div`
  height: 500px;
  width: 500px;
  position: relative;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    max-width: 90vw;
    max-height: 90vw;
  }
`;

export const TopContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

export const DateInputContainer = styled.div`
  display: flex;

  @media only screen and (min-width: 1200px) and (max-width: 1380px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 990px) {
    margin-bottom: 15px;
  }
`;

export const ContainerEdit = styled.div<{
  bgColor: string;
  hasImage: boolean;
}>`
  background-color: ${props =>
    props.hasImage ? props.theme.colors.white : props.bgColor};

  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;

  figure {
    position: relative;
    ${props => !props.hasImage && 'height: 100%; width: 100%;'};
    margin: 0px;
  }
`;

export const DivImg = styled.img<{ sendingImg: boolean }>`
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  max-height: 500px;
  max-width: 500px;

  ${props =>
    props.sendingImg &&
    `
    max-height: 1000px;
    max-width: 1000px;
    height: auto;
    width: auto;
    `}
`;

export const DivPosition = styled.div<{ position: string; align?: string }>`
  position: absolute;
  margin: 50px;
  ${props => props.align && 'width: calc(100% - 100px);'}

  ${props => props.position === POSITION.TOP && 'top: 0px; '}
  ${props => props.position === POSITION.CENTER && 'top: 45%;  '}
  ${props => props.position === POSITION.BOT && 'bottom: 0px; '}
  ${props =>
    props.position === POSITION.CENTER &&
    !props.align &&
    'top: 40%; left: 40%; '}

  

  ${props => props.align === POSITION.LEFT && 'text-align: left;'}
  ${props => props.align === POSITION.CENTER && 'text-align: center;'}
  ${props => props.align === POSITION.RIGHT && 'text-align: right;'}

  overflow: hidden;
`;

export const TextImg = styled.p<{ color?: string }>`
  color: ${props => props.color || props.theme.colors.black};
  font-weight: bold;
`;

export const BigTitleImg = styled(TextImg)`
  font-size: ${props => props.theme.fonts.title};
`;

export const SubtitleImg = styled(TextImg)`
  font-size: ${props => props.theme.fonts.bigSubtitle};
`;

export const DateTimeRounded = styled(DatePicker)`
  border-radius: 50px;
  border: 2px solid ${props => props.theme.colors.greyLetter};
  margin-right: 10px;
  margin-top: 5px;
  width: 200px;
  padding: 4px;
  .rs-picker-menu .rs-calendar {
    width: 100px !important;
    display: block !important;
    margin: 0 auto !important;
  }

  .rs-picker-toggle {
    background-color: transparent !important;
    border: none !important;

    .rs-picker-toggle-value {
      color: ${props => props.theme.colors.greyLetter};
    }
  }

  @media (max-width: 480px) {
    width: 200px;
    margin-right: 2px;
    margin-top: 2px;
    padding: 2px;
  }
`;

export const ButtonDashed = styled.button`
  background-color: transparent;
  width: 100%;
  padding: 25px 0px;

  border: 2px dashed ${props => props.theme.colors.grey};

  img {
    transform: scaleX(-1);
  }
  p {
    color: ${props => props.theme.colors.greyLetter};
    font-weight: bold;
  }
`;

export const ButtonGroupCenter = styled(ButtonGroup)`
  left: 50%;
  transform: translateX(-50%);
`;

export const DivForm = styled.div`
  width: max-content;
  max-width: 100%;
  margin: 20px auto;

  div > div {
    margin: 5px 0px;
  }
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;

  .EmojiPickerReact {
    position: absolute !important;
    transform: translate(0px, 22px);
    right: 0;
    z-index: 9;
  }
`;

export const IconSmiley = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 13px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: none;
  resize: none;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.subtitle};
  padding: 5px 10px;
  font-family: Helvetica;
  position: relative;
  border-radius: 8px;
`;

export const CounterTextarea = styled.div`
  float: right;
`;

export const ImgTools = styled.img`
  cursor: pointer;
`;

export const LoaderCenter = styled(Loader)`
  padding: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  min-height: 25px;
`;

export const ParagraphAdvert = styled.p`
  margin-top: 7px;
`;

export const GlobalStyle = createGlobalStyle`
  @media (max-width: 480px) {
    .rs-picker-menu .rs-calendar {
      width: 200px;
      display: block;
      margin: 0 auto;
    }
    .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
      display: block;
      text-align: center;
      width: 72%;
    }
    .rs-calendar-time-dropdown {
      display: none;
      position: absolute;
      top: 40px;
      padding-left: 12px;
      padding-right: 12px;
      width: 94%;
      background-color: #fff;
      overflow: hidden;
      z-index: 1;
    }
  }

  @media (max-width: 430px) {
    .rs-picker-menu .rs-calendar {
      width: 210px;
      display: block;
      margin: 0 auto;
    }
    .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
      display: block;
      text-align: center;
      width: 70%;
    }
    .rs-calendar-time-dropdown {
      display: none;
      position: absolute;
      top: 40px;
      padding-left: 10px;
      padding-right: 10px;
      width: 92%;
      background-color: #fff;
      overflow: hidden;
      z-index: 1;
    }
  }

  @media (max-width: 390px) {
    .rs-picker-menu .rs-calendar {
      width: 190px;
      display: block;
      margin: 0 auto;
    }
    .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
      display: block;
      text-align: center;
      width: 68%;
    }
    .rs-calendar-time-dropdown {
      display: none;
      position: absolute;
      top: 40px;
      padding-left: 8px;
      padding-right: 8px;
      width: 90%;
      background-color: #fff;
      overflow: hidden;
      z-index: 1;
    }
  }

  @media (max-width: 360px) {
    .rs-picker-menu .rs-calendar {
      width: 185px;
      display: block;
      margin: 0 auto;
    }
    .rs-calendar-header-has-month:not(.rs-calendar-header-has-time) .rs-calendar-header-month-toolbar {
      display: block;
      text-align: center;
      width: 56%;
    }
    .rs-calendar-time-dropdown {
      display: none;
      position: absolute;
      top: 40px;
      padding-left: 6px;
      padding-right: 6px;
      width: 78%;
      background-color: #fff;
      overflow: hidden;
      z-index: 1;
    }
      
  }
    
`;
