import { useQuery } from 'react-query';
import { fetchScheduledPosts } from '../services/posts';

export const KEY_SCHEDULED_POSTS = 'SCHEDULED_POSTS';
const useScheduledPosts = () => {
  return useQuery(KEY_SCHEDULED_POSTS, fetchScheduledPosts, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });
};

export default useScheduledPosts;
