import styled from 'styled-components';

interface StepCircleProps {
  active: boolean;
}

// export const Container = styled.div`
//   display: flex;
//   flex-direction: row;
//   height: 100vh;
//   // background: linear-gradient(45deg, #f857a6, #dd2256);
// `;

// export const LeftPane = styled.div`
//   width: 40%;
//   position:relative;
//   display: flex;
//   flex-direction: column;
//   background-color: #fff;
//   overflow-y:auto;
//   overflow-x:hidden;
//   @media (max-width: 1024px) {
//     width: 50%; /* Equal width at 1024px screen width */
//   }

//   @media (max-width: 780px) {
//     width: 100%;
//   }
// `;

// export const RightPane = styled.div`
//   width: 60%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow-x: hidden;

//   @media (max-width: 1024px) {
//     width: 50%; /* Adjust width at 1024px screen width */
//   }

//   @media (max-width: 780px) {
//     display: none; /* Hide on small screens */
//   }
// `;

// const moveUpDown = keyframes`
//   0%, 100% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(-10px);
//   }
// `;

// export const ImageContainer = styled.div`
//   height: auto;
//   width: 80%;
//   display: flex;
//   flex-direction: column;
//   // position: relative;
//   margin: 0 auto;
//   justify-content: center;

//   img {
//     animation: ${moveUpDown} 2s ease-in-out infinite;
//   }
// `;
// export const StyledImage = styled.img`
//   width: 60%;
//     margin:0 auto;
//   object-fit: cover;
//       margin-bottom:40px;

//   @media (max-width: 1480px) {
//     width: 60%;
//     margin-bottom:20px;
//   }
//   @media (max-width: 1280px) {
//     width: 50%;
//   }
//   @media (max-width: 1020px) {
//     width: 50%;
//   }
//   @media (max-width: 780px) {
//     width: 80%;
//   }
//   @media (max-width: 480px) {
//     width: 90%;
//   }
// `;

// export const DinabiteText = styled.p`
// height:30%;
//   font-size: 1.4rem;
//   color: #dd2256;
//   text-align: center;
//   font-family: 'Inter', sans-serif;

//   @media (max-width: 1480px) {
//     margin-top:40px;

//     font-size: 1.1rem;
//   }
//   @media (max-width: 1280px) {
//     margin-top:40px;

//     font-size: 1.1rem;
//   }
//   @media (max-width: 1024px) {
//   margin-top:60px;
//     font-size: 0.9rem;
//   }
//   @media (max-width: 780px) {
//     display: none;
//   }
// `;

export const StepHeaderContainer = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  margin:0px;
  @media (max-width: 1080px) {
    position: relative;
    padding: 0;
    background-color: transparent;
    margin-bottom: 0px;
  }
  @media (max-width: 780px) {
    position: relative;
    padding: 0;
    background-color: transparent;
    margin-bottom: 5px;
  }
`;

export const CreateAccountTitle = styled.h1`
  font-size: 1.5rem;
  font-family: 'Inter', sans-serif;
  color: #dd2256;
  text-align: center;
  margin:0 auto;
  @media (max-width: 1480px) {
    font-size: 1.2rem;
  }
  @media (max-width: 1280px) {
    font-size: 1.1rem;
  }
  @media (max-width: 780px) {
    font-size: 1rem;
    margin-bottom: 0px;

  }

  @media (max-width: 320px) {
    font-size: 1rem;
          margin-bottom: 0px;

  }
`;

export const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  @media (max-width: 780px) {
      margin-bottom: 0px;


  }
  
`;

export const StepCircle = styled.div<StepCircleProps>`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#DD2256" : "#FFC0CB")};
  transition: background-color 0.3s ease-in-out;
   @media (max-width: 780px) {
    width: 7px;
  height: 7px;

  }
`;



// export const ContentContainer = styled.div`
//   // flex: 1;
//   width: 100%;
//   overflow-y: auto; /* Allow scrolling for content */
//   display: flex;
//   flex-direction: column;
//   align-items: center; /* Center all children horizontally */
//   justify-content: center; 
  
//   @media (max-width: 780px) {
//     padding-bottom: 150px;
//     margin:0;
//   }
// `;



// export const StyledErrorMessage = styled.div`
//   color: red;
//   font-size: 14px;
//   margin-top: 5px;
//   margin-bottom: 10px;
//   font-weight: bold;
//   width:80%;
//   @media (max-width: 780px) {
//   margin-top: 1px;
//   margin-bottom: 1px;
//   }
// `;
// const slideIn = keyframes`
//   0% {
//     transform: translateY(100%);
//     opacity: 0;
//   }
//   100% {
//     transform: translateY(0);
//     opacity: 1;
//   }
// `;
interface MobileImageProps {
  step: number;
}
export const MobileImage = styled.div<MobileImageProps>`
  display: none; /* Hide by default */

  @media (max-width: 780px) {
    display: block;
    text-align: center;
    height: 16%;
    width: 25%;
    width: ${({ step }) => (step === 3 ? '50%' : '20%')}; /* Adjust width based on step */

    margin: 0 auto;
    margin-bottom:10px;
  }

  @media (max-width: 480px) {
    padding: 0;
    height: 18%;
    // width: 45%;
    width: ${({ step }) => (step === 3 ? '60%' : '40%')}; /* Adjust width based on step */

  }
  @media (max-width: 380px) {
    padding: 0;
    height: 22%;
    width: 40%;
  }

  &:active {
    transform: translateY(0); /* Reset lift on click */
  }
`;

// Optional: You can use this for additional styling
export const MobileImageContent = styled.img`
  max-width: 90%; /* Responsive width */
  
  // height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Center the image */
  padding:0px;
  margin-top:10px;
  
`;
export const CancelButton = styled.button`
  display: none; /* Hide by default */
  @media (max-width: 780px) {
    display: block; 
    position: absolute; /* Position it on the right */
    top: 17px;
    right: 40px;
    width: auto;
    padding: 8px 16px; /* Add some padding for better appearance */
    font-family: 'Inter', sans-serif;
    font-size: 16px; /* Adjust font size for better readability */
    font-weight: 500; /* Add some weight to the font */
    color: #dd2256;
    background-color: transparent;
    border-radius: 15px; /* Slightly round the corners */
    cursor: pointer;
    transition: all 0.3s ease; /* Smooth transition for hover effects */
        font-weight: bold; /* Make text bold */

    &:hover {
  background: linear-gradient(180deg, #DD2256 0%, #E43C39 100%);
      color: #fff; /* Change text color on hover */
    }

    &:active {
      transform: scale(0.98); /* Slightly scale down on click */
    }
        @media (max-width: 480px) {
            right: 5px;

  }
        @media (max-width: 375px) {
            right: 0px;

  }
  }
`;
