import { Col, FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

export const Section = styled(FlexboxGrid)`
  margin-bottom: 20px;
`;

export const ColSpace = styled(Col)`
  padding: 0px;
`;
