// @ts-nocheck 

import { FlexboxGrid } from 'rsuite';
import { Card } from '../Cards.styles';
import { Title, Value } from './BasicCard.styles';

interface Props {
  title: string;
  value: string;
}

const BasicCard = ({ title, value }: Props) => {
  return (
    <Card>
      <FlexboxGrid>
        <FlexboxGrid.Item>
          <Title>{title}</Title>
          <Value>{value}</Value>
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Card>
  );
};

export default BasicCard;
