/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */

import axios from '../lib/axios';
import { capitalize } from '../lib/helpers';
import { notification, notificationError } from '../lib/notification';
import { postPhotosToTiktok, postVideoToTikTok } from './postToTikTok';

interface Props {
  title: string;
  mediaId: number | number[];
  socialNetworks: string[];
  scheduledAt: any;
  isPrivacyLevel?: string;
  disableComment?: boolean;
  allowComments?: boolean;
  allowDuet?: boolean;
  allowStitch?: boolean;
  disclosePostContent?: boolean;
  isAiGenerated?: boolean;
  autoAddMusic?: boolean;
  brandContentToggle?: boolean;
  brandOrganicToggle?: boolean;
  mediaType?: string;
}

export const postsSocial = async ({
  title,
  mediaId,
  socialNetworks,
  scheduledAt,
  isPrivacyLevel,
  disableComment,
  allowComments,
  allowDuet,
  allowStitch,
  disclosePostContent,
  isAiGenerated,
  autoAddMusic,
  brandContentToggle,
  brandOrganicToggle,
  mediaType,
}: Props): Promise<any> => {
  try {
    let tiktokData: any = {};
    let otherNetworksData: any = {};

    const promises = [];

    // Handle TikTok post
    if (socialNetworks.includes('tiktok')) {
      if (mediaType === 'video') {
        promises.push(
          postVideoToTikTok({
            title,
            mediaId,
            socialNetworks: ['tiktok'],
            isPrivacyLevel: isPrivacyLevel!,
            allowComments: allowComments!,
            allowDuet: allowDuet!,
            allowStitch: allowStitch!,
            brandOrganicToggle: brandOrganicToggle!,
            brandContentToggle: brandContentToggle!,
            disclosePostContent: disclosePostContent!,
            isAiGenerated: isAiGenerated!,
          }).then(data => {
            tiktokData = data;
          }),
        );
      } else if (mediaType === 'image') {
        promises.push(
          postPhotosToTiktok({
            title,
            mediaId,
            socialNetworks: ['tiktok'],
            isPrivacyLevel: isPrivacyLevel!,
            allowComments: disableComment!,
            autoAddMusic: autoAddMusic!,
            brandContentToggle: brandContentToggle!,
            brandOrganicToggle: brandOrganicToggle!,
          }).then(data => {
            tiktokData = data;
          }),
        );
      }
    }

    // Handle Facebook/Instagram post
    const otherNetworks = socialNetworks.filter(
      network => network !== 'tiktok',
    );
    if (otherNetworks.length > 0) {
      promises.push(
        axios
          .post('/posts', {
            title,
            mediaId,
            socialNetworks: otherNetworks,
            scheduledAt,
          })
          .then(response => {
            const { data } = response; // Using destructuring here
            otherNetworksData = data;
          }),
      );
    }

    await Promise.all(promises);

    const finalData = { ...tiktokData, ...otherNetworksData };

    if (finalData) {
      Object.entries(finalData).forEach(
        ([socialName, content]: [string, any]) => {
          if (content === true) {
            notification({
              type: 'success',
              title: capitalize(socialName),
              description: 'Post was successfully published',
            });
          } else {
            notificationError({
              title: capitalize(socialName),
              description: content || 'The post could not be made.',
            });
          }
        },
      );
    }

    return finalData;
  } catch (error: any) {
    if (error.response?.status === 403) {
      notificationError({
        title: 'Error',
        description: 'API Key not correct. Please provide a valid API key.',
      });
    } else {
      notificationError({
        title: 'Error',
        description: 'Something went wrong',
      });
    }

    return error;
  }
};
