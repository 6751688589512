import styled from 'styled-components';

export const Container = styled.div`
  background-color: ${props => props.theme.colors.red};
  border-radius: 100%;
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
