import axios from '../lib/axios';


export const sendWelcomeEmail = async (userId: number, email: string) => {

  try {
    const response = await axios.post('/mail/welcome', {
      userId,
      email,
    });


    return response;
  } catch (error) {

    // notificationError({
    //   title: 'Error',
    //   description: 'Failed to send the welcome email.',
    // });

    return error;
  }
};
