import React, { useState, useEffect } from 'react'; // Make sure to import useState
import styled from 'styled-components';

import { Icon } from '@rsuite/icons';
// import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useQuery } from 'react-query';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { OrdersIcon } from '../../assets';
import { ReactComponent as DashboardIcon } from '../../assets/icons/navicons/dashboard.svg';
import { ReactComponent as Dashboard2Icon } from '../../assets/icons/navicons/dashboard2.svg';
import { ReactComponent as AnalyticsIcon } from '../../assets/icons/navicons/analytics.svg';
import { ReactComponent as Analytics2Icon } from '../../assets/icons/navicons/analytics2.svg';
import { ReactComponent as PlatformIcon } from '../../assets/icons/navicons/platform.svg';
import { ReactComponent as Platform2Icon } from '../../assets/icons/navicons/platform2.svg';
import { ReactComponent as PostsIcon } from '../../assets/icons/navicons/posts.svg';
import { ReactComponent as Post2Icon } from '../../assets/icons/navicons/post2.svg';
import { ReactComponent as PromotionsIcon } from '../../assets/icons/navicons/promotions.svg';
import { ReactComponent as Promotions2Icon } from '../../assets/icons/navicons/promotions2.svg';
import { ReactComponent as ReelsIcon } from '../../assets/icons/navicons/reels.svg';
import { ReactComponent as Reels2Icon } from '../../assets/icons/navicons/reels2.svg';
import { ReactComponent as ReviewsIcon } from '../../assets/icons/navicons/reviews.svg';
import { ReactComponent as Reviews2Icon } from '../../assets/icons/navicons/reviews2.svg';
import { ReactComponent as IndicatorSVG } from '../../assets/icons/navicons/indicator.svg'; // Import your indicator SVG

import { useAuth } from '../../context/AuthContext';
// import useUser from '../../hooks/useUser';
import axios from '../../lib/axios';
import { useMobileStore } from '../../store';
import {
  DividerSpace,
  SidenavStyled,
  StyledNavLink,
  Navigation,
  MenuItem,
  UpGradeButton,
} from './NavUser.styles';

const fetchTools = async () => {
  try {
    const { data } = await axios.get<Tool[]>('/tools');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
const Indicator = styled(IndicatorSVG)`
  width: 8px;
  height: auto;
  /* Add custom styles for positioning */
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

type Props = {
  isMenuClose?: boolean;
};

const NavUser = ({ isMenuClose }: Props) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  // const { handleLogout } = useUser();
  const { user } = useAuth();
  const { hideMobileMenu } = useMobileStore();
  const { pathname } = useLocation(); // Get the current pathname
  const [activeItem, setActiveItem] = useState('');
  const [blockUser, setBlockUser] = useState(false);

  const { data: tools } = useQuery(`TOOLS`, fetchTools, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });

  const hasConnectedTool = tools?.find(tool => tool.enabled);

  const isUberEatsEnabled = tools?.find(
    tool => tool.type === 'uberEats' && tool.enabled,
  );
  useEffect(() => {
    // Update activeItem based on current pathname
    if (pathname.includes('dashboard')) {
      setActiveItem('dashboard');
    } else if (pathname.includes('tools')) {
      setActiveItem('tools');
    } else if (pathname.includes('posts')) {
      setActiveItem('posts');
    } else if (pathname.includes('reels')) {
      setActiveItem('reels');
    } else if (pathname.includes('promotions')) {
      setActiveItem('promotions');
    } else if (pathname.includes('reviews')) {
      setActiveItem('reviews');
    } else if (pathname.includes('analytics')) {
      setActiveItem('analytics');
    } else {
      setActiveItem(''); // Default case if none match
    }
  }, [pathname]); // Dependency array
  // const logoutUser = () => {
  //   confirmAlert({
  //     message: `Do you want to logout ${user?.email}?`,
  //     buttons: [
  //       {
  //         label: 'Confirm',
  //         onClick: () => {
  //           handleLogout();
  //           history.push('/');
  //         },
  //       },
  //       {
  //         label: 'Cancel',
  //         onClick: () => null,
  //       },
  //     ],
  //   });
  // };

  const handleRoute = () => {
    history.push(`${url}/account`);
  };

  const payment = user?.companies?.[0]?.payment;

  useEffect(() => {
    if (
      payment?.subscriptionStatus === 'trial_expired' &&
      !payment?.hasAccess
    ) {
      console.log('yes now ', hasConnectedTool);
      setBlockUser(true);
    } else {
      setBlockUser(false);
    }
  }, [payment]);

  return (
    <SidenavStyled toolsLength={tools?.length || 0}>
      <Navigation isMenuClose={isMenuClose} toolsLength={tools?.length || 0}>
        <MenuItem
          onClick={() => {
            hideMobileMenu();
            setActiveItem('dashboard');
          }}
          componentClass={StyledNavLink}
          isMenuClose={isMenuClose}
          to={`${url}/dashboard`}
          activeClassName="current">
          {!isMenuClose && activeItem === 'dashboard' && <Indicator />}
          <Icon
            as={activeItem === 'dashboard' ? DashboardIcon : Dashboard2Icon}
          />
          {!isMenuClose && <b>Dashboard</b>}
        </MenuItem>
        <MenuItem
          onClick={() => {
            hideMobileMenu();
            setActiveItem('tools');
          }}
          componentClass={StyledNavLink}
          isMenuClose={isMenuClose}
          to={`${url}/tools`}
          activeClassName="current">
          {!isMenuClose && activeItem === 'tools' && <Indicator />}
          <Icon as={activeItem === 'tools' ? PlatformIcon : Platform2Icon} />
          {!isMenuClose && <b>Accounts</b>}
        </MenuItem>
        {hasConnectedTool && !blockUser && (
          <>
            <MenuItem
              onClick={() => {
                hideMobileMenu();
                setActiveItem('posts');
              }}
              componentClass={StyledNavLink}
              to={`${url}/posts`}
              isMenuClose={isMenuClose}
              activeClassName="current">
              {!isMenuClose && activeItem === 'posts' && <Indicator />}

              <Icon as={activeItem === 'posts' ? PostsIcon : Post2Icon} />
              {!isMenuClose && <b>Posts</b>}
            </MenuItem>
            <MenuItem
              onClick={() => {
                hideMobileMenu();
                setActiveItem('reels');
              }}
              componentClass={StyledNavLink}
              isMenuClose={isMenuClose}
              to={`${url}/reels`}
              activeClassName="current">
              {!isMenuClose && activeItem === 'reels' && <Indicator />}

              <Icon as={activeItem === 'reels' ? ReelsIcon : Reels2Icon} />
              {!isMenuClose && <b>Reels</b>}
            </MenuItem>
            <MenuItem
              onClick={() => {
                hideMobileMenu();
                setActiveItem('promotions');
              }}
              componentClass={StyledNavLink}
              isMenuClose={isMenuClose}
              to={`${url}/promotions`}
              activeClassName="current">
              {!isMenuClose && activeItem === 'promotions' && <Indicator />}

              <Icon
                as={
                  activeItem === 'promotions' ? PromotionsIcon : Promotions2Icon
                }
              />
              {!isMenuClose && <b>Promotions</b>}
            </MenuItem>
            <MenuItem
              onClick={() => {
                hideMobileMenu();
                setActiveItem('reviews');
              }}
              componentClass={StyledNavLink}
              isMenuClose={isMenuClose}
              to={`${url}/reviews`}
              activeClassName="current">
              {!isMenuClose && activeItem === 'reviews' && <Indicator />}

              <Icon
                as={activeItem === 'reviews' ? ReviewsIcon : Reviews2Icon}
              />
              {!isMenuClose && <b>Reviews</b>}
            </MenuItem>
            <MenuItem
              onClick={() => {
                hideMobileMenu();
                setActiveItem('analytics');
              }}
              componentClass={StyledNavLink}
              isMenuClose={isMenuClose}
              to={`${url}/analytics`}
              activeClassName="current">
              {!isMenuClose && activeItem === 'analytics' && <Indicator />}

              <Icon
                as={activeItem === 'analytics' ? AnalyticsIcon : Analytics2Icon}
              />
              {!isMenuClose && <b>Analytics</b>}
            </MenuItem>
          </>
        )}
        {/* <DividerSpace /> */}
        {isUberEatsEnabled && (
          <>
            <MenuItem
              onClick={hideMobileMenu}
              componentClass={StyledNavLink}
              to={`${url}/delivery`}
              activeClassName="current">
              {!isMenuClose && activeItem === 'delivery' && <Indicator />}

              <Icon as={OrdersIcon} />
              {!isMenuClose && <b>Delivery</b>}
            </MenuItem>
            <DividerSpace />
          </>
        )}
        {/* <MenuItem
          onClick={hideMobileMenu}
          componentClass={StyledNavLink}
          to={`${url}/account`}
          activeClassName="current">
          {!isMenuClose && activeItem === 'account' && <Indicator />}

          <Icon as={AccountIcon} />
          {!isMenuClose && <b>Account</b>}
        </MenuItem> */}
        {/* <MenuItem
          onClick={hideMobileMenu}
          componentClass={StyledNavLink}
          to={`${url}/help`}
          activeClassName="current">
          {!isMenuClose && activeItem === 'help' && <Indicator />}

          <Icon as={HelpIcon} />
          {!isMenuClose && <b>Feedback/Help</b>}
        </MenuItem> */}
        {/* <DividerSpace />
        <Menu onClick={logoutUser}>
          <Icon as={LogoutIcon} />
          {!isMenuClose && <b>Logout</b>}
        </Menu> */}
      </Navigation>
      <UpGradeButton onClick={handleRoute} isMenuClose={isMenuClose}>
        {payment?.subscriptionStatus === 'trialing' ||
        payment?.subscriptionStatus === 'trial_expired'
          ? 'Upgrade'
          : 'Manage'}
      </UpGradeButton>
    </SidenavStyled>
  );
};

export default NavUser;
