import { useEffect, useReducer, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Loader } from 'rsuite';
import {
  DropdownToggleButton,
  DownImg,
  DropdownContainer,
  DropdownContent,
  DropdownIconWrapper,
} from './SelectToolsDropdown.style';
import ToogleTools from '../toogleTools/ToogleTools';
import { initialToolsState, toolsReducer } from '../../reducers/toolsReducer';
import { useAuth } from '../../context/AuthContext';
import { TYPES_TOOLS } from '../../actions/toolsActions';
import { getToolsCompanyObj } from '../../services/getToolsCompany';
import { objectCleaner } from '../../lib/objects';
import { notificationWithNodes } from '../../lib/notification';
import { selectImgStatus } from '../../lib/toogleImgStatus';
import { SocialNetworkIcon } from '../cards/toolToogleCard/toolToogleCard.styles';
import ArrowImage from '../../assets/icons/dropdown-arrow.svg';

interface DataTools {
  [key: string]: DataToolItem[];
}

interface DataToolItem {
  enabled: boolean;
}

const SelectToolsDropdown = ({
  signup = true,
  hide,
}: {
  signup?: boolean;
  hide?: boolean;
}) => {
  const queryClient = useQueryClient();
  const { companySelected } = useAuth();
  const [stateTool, dispatchTool] = useReducer(toolsReducer, initialToolsState);
  // State to toggle dropdown visibility
  const [isOpen, setIsOpen] = useState(false);
  const notificationKey = 'dinabite_notification_shown';

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const showNotification = () => {
    notificationWithNodes({
      type: 'success',
      title: '',
      description: (
        <>
          <p>Welcome to dinabite!</p>
          <p>Please connect your tools</p>
        </>
      ),
    });
    // Store flag in localStorage
    localStorage.setItem(notificationKey, 'true');
  };
  // console.log('stateTool', stateTool);

  useEffect(() => {
    const updateData = async () => {
      if (companySelected?.id) {
        const { data: dataTools } = await getToolsCompanyObj(
          companySelected.id,
        );

        const cleanedStateTool = objectCleaner(dataTools);

        const isAnyToolConnected = Object.keys(cleanedStateTool).length > 0;

        // if (!isAnyToolConnected) {
        //   notificationWithNodes({
        //     type: 'success',
        //     title: '',
        //     description: (
        //       <>
        //         <p>Welcome to dinabite!</p>
        //         <p>Please connect your tools</p>
        //       </>
        //     ),
        //   });
        // }
        const notificationShown = localStorage.getItem(notificationKey);

        if (!isAnyToolConnected && !notificationShown) {
          showNotification();
        }
        const filteredDataTools = Object.keys(dataTools).reduce<
          Record<string, DataToolItem>
        >((acc, key) => {
          // Ensure dataTools is treated as DataTools type
          const tools: DataTools = dataTools;

          if (Array.isArray(tools[key])) {
            const enabledObject = tools[key].find(
              (item: DataToolItem) => item.enabled,
            );
            if (enabledObject) {
              acc[key] = enabledObject;
            }
          }
          return acc;
        }, {});

        // console.log('filteredDataTools | useEffect', filteredDataTools);

        //     dispatchTool({ type: TYPES_TOOLS.INIT_TOOLS, payload: dataTools });
        dispatchTool({
          type: TYPES_TOOLS.INIT_TOOLS,
          payload: filteredDataTools,
        });
      }
    };
    updateData();
  }, [companySelected]);

  if (!companySelected?.id) {
    return <Loader center content="loading" />;
  }

  const filteredStateTool = signup
    ? Object.fromEntries(
        Object.entries(stateTool).filter(([socialName]) =>
          ['FACEBOOK', 'INSTAGRAM', 'GOOGLE', 'TIKTOK', 'TWITTER'].includes(
            socialName.toUpperCase(),
          ),
        ),
      )
    : stateTool;

  // console.log('filteredStateTool', filteredStateTool);

  return (
    <DropdownContainer hide={hide}>
      {/* Display social media icons */}
      <DropdownToggleButton onClick={toggleDropdown}>
        <DropdownIconWrapper>
          {Object.entries(filteredStateTool).map(
            ([socialName, content]: [string, any]) => {
              return (
                <SocialNetworkIcon
                  key={socialName}
                  src={selectImgStatus(socialName, content?.enabled).image}
                  alt="Social Network Icon"
                  signup={signup}
                />
              );
            },
          )}
        </DropdownIconWrapper>
        <div>
          <DownImg src={ArrowImage} alt="down" isOpen={isOpen} />
        </div>
      </DropdownToggleButton>

      <DropdownContent isOpen={isOpen}>
        <ToogleTools
          stateTool={stateTool}
          dispatchTool={dispatchTool}
          invalidateQuery={() => {
            queryClient.invalidateQueries(['MESSAGES']);
            queryClient.invalidateQueries(['TOOLS']);
          }}
          companyId={companySelected.id}
          newFormat
        />
      </DropdownContent>
    </DropdownContainer>
  );
};

export default SelectToolsDropdown;
