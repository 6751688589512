// @ts-nocheck
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useState } from 'react';

import { confirmAlert } from 'react-confirm-alert';
import { useMutation, useQueryClient } from 'react-query';
import { FlexboxGrid, IconButton, Table } from 'rsuite';
import DeleteIcon from '../../../assets/icons/delete.svg';
import VideoIcon from '../../../assets/icons/video.svg';
import { formatDate } from '../../../lib/format';
import { isImageType } from '../../../lib/mediaType';
import { notification } from '../../../lib/notification';
import { removeMedia } from '../../../services/mediaFiles';
import {
  CellImg,
  ImageLink,
  PreviewVideo,
  CardContainer,
  Card,
} from './tableFiles.styles';

interface Props {
  getDataFiles: Media[];
  isLoading: boolean;
}

const TableFiles = ({ getDataFiles, isLoading }: Props) => {
  let idMedia: number;
  const queryClient = useQueryClient();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isImageError, setImageError] = useState(false);
  const [isVideoError, setVideoError] = useState(false);

 
  const handleDeleteMedia = (id: number, name: string) => {
    confirmAlert({
      message: `Do you want to delete ${name}?`,
      buttons: [
        {
          label: 'Confirm',
          onClick: () => {
            mutate(id);
            idMedia = id;
          },
        },
        {
          label: 'Cancel',
          onClick: () => null,
        },
      ],
    });
  };
  useEffect(() => {
    // Update window width on resize
    const handleResize = () => setWindowWidth(window.innerWidth);

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const { mutate, isLoading: isLoadingDeleteMedia } = useMutation(
    (id: number) => removeMedia(id),
    {
      onSuccess() {
        queryClient.setQueryData('MEDIA', (oldData: any) => {
          const removedFile = oldData.data.filter(
            (file: Media) => file.id !== idMedia,
          );
          return {
            ...oldData,
            data: removedFile,
          };
        });
        notification({
          title: 'Success',
          description: 'File was deleted',
          type: 'success',
        });
        queryClient.fetchQuery('MEDIA_SIZES');
      },
    },
  );

  return (
    <>
      <div className="desktop-table">
        <Table
          height={400}
          data={getDataFiles}
          rowHeight={75}
          loading={isLoading || isLoadingDeleteMedia}
          style={{
            display: windowWidth < 441 ? 'none' : 'block',
          }}>
          <Table.Column verticalAlign="middle">
            <Table.HeaderCell>File</Table.HeaderCell>
            <Table.Cell dataKey="title">
              {(rowData: Media) => (
                <FlexboxGrid>
                  <CellImg isimage={isImageType(rowData.type) ? 1 : 0}>
                    <>
                      {isImageType(rowData.type) ? (
                        <img src={rowData.url} alt={rowData.name} />
                      ) : (
                        <PreviewVideo>
                          <img src={VideoIcon} alt={rowData.name} />
                          <video>
                            <source src={rowData.url} type="video/mp4" />
                          </video>
                        </PreviewVideo>
                      )}
                    </>
                  </CellImg>
                </FlexboxGrid>
              )}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={2} verticalAlign="middle">
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.Cell dataKey="title">
              {(rowData: Media) => (
                <FlexboxGrid>
                  <ImageLink href={rowData?.url}>{rowData?.name}</ImageLink>
                </FlexboxGrid>
              )}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>Uploaded</Table.HeaderCell>
            <Table.Cell dataKey="date">
              {(rowData: Media) => (
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={16}>
                    <span>{formatDate(rowData.createdAt)}</span>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              )}
            </Table.Cell>
          </Table.Column>

          <Table.Column flexGrow={1} verticalAlign="middle">
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.Cell dataKey="media">
              {(rowData: Media) => (
                <FlexboxGrid>
                  <FlexboxGrid.Item colspan={16}>
                    <span>{isImageType(rowData.type) ? 'Image' : 'Video'}</span>
                  </FlexboxGrid.Item>
                </FlexboxGrid>
              )}
            </Table.Cell>
          </Table.Column>

          <Table.Column fixed="right">
            <Table.HeaderCell />
            <Table.Cell>
              {(rowData: Media) => (
                <IconButton
                  appearance="link"
                  onClick={() => {
                    handleDeleteMedia(rowData.id, rowData.name);
                  }}
                  icon={<img height="100%" src={DeleteIcon} alt="Delete row" />}
                />
              )}
            </Table.Cell>
          </Table.Column>
        </Table>
      </div>

      <CardContainer
        className="mobile-cards"
        style={{
          display: windowWidth > 440 ? 'none' : 'block',
        }}>
        {getDataFiles.map(rowData => (
          <Card key={rowData.id}>
            <div>
              {isImageType(rowData.type) ? (
                <div>
                  {!isImageError ? (
                    <img
                      src={rowData.url}
                      alt={rowData.name}
                      style={{ width: '100%' }}
                      onError={() => setImageError(true)}
                    />
                  ) : (
                    <p>Image not available</p>
                  )}
                </div>
              ) : (
                <PreviewVideo>
                  <img src={VideoIcon} alt="Video placeholder" />
                  {!isVideoError ? (
                    <video
                      style={{ width: '100%' }}
                      onError={() => setVideoError(true)}
                      controls>
                      <source src={rowData.url} type="video/mp4" />
                    </video>
                  ) : (
                    <p>Video not available</p>
                  )}
                </PreviewVideo>
              )}
            </div>
            <div>
              <a href={rowData.url}>{rowData.name}</a>
            </div>
            <div>{formatDate(rowData.createdAt)}</div>
            <div>{isImageType(rowData.type) ? 'Image' : 'Video'}</div>
            <IconButton
              appearance="link"
              onClick={() => handleDeleteMedia(rowData.id, rowData.name)}
              icon={<img height="100%" src={DeleteIcon} alt="Delete" />}
            />
          </Card>
        ))}
      </CardContainer>
    </>
  );
};

export default TableFiles;
