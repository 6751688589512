import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

export const generateAiTextFromImage = async ({
  selectedFile,
  url
}: {
  selectedFile?: File;
  url?: string
}) => {
  const formData = new FormData();
  if(selectedFile) {
    formData.append('file', selectedFile);
  }
  formData.append('selectedUrl', url ?? "");
  try {
    const response = await axios.post('/chatbot/generate-text', formData);
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: error.message || 'Could not generate post suggestion',
    });
    return error;
  }
};
