import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

export const likePostById = async (postId: string | undefined) => {
  try {
    const like = await axios.post(`/posts/fb-like/${postId}`);
    return like;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const unLikePostById = async (postId: string | undefined) => {
  try {
    const unLike = await axios.delete(`/posts/fb-unLike/${postId}`);
    return unLike;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};
