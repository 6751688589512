import { useEffect, useRef, useState } from 'react';
import { Loader } from 'rsuite';
import usePosts from '../../hooks/usePosts';
import Post2 from '../../components/post/Post2';
import { ContentContainer, SeeMoreButton } from './Dashboard.styles';

const POSTS_PER_PAGE = 6;

const Posts = ({ handleLoadMore, onPostsUpdate, update }: any) => {
  const [dataPostSelected, setDataPostSelected] = useState<Post | null>(null);

  const {
    isLoading: isLoadingPosts,
    isError: isErrorPosts,
    isFetching: isFetchingPosts,
    data: posts,
    refetch: refetchPosts,
  } = usePosts();

  const initialPosts = useRef(posts);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (posts && posts !== initialPosts.current) {
      initialPosts.current = posts;
      onPostsUpdate(posts);
    }
  }, [posts, onPostsUpdate]);

  useEffect(() => {
    const interval = setInterval(() => refetchPosts(), 600 * 1000);
    return () => clearInterval(interval);
  }, [refetchPosts]);

  useEffect(() => {
    if (update) {
      refetchPosts();
    }
  }, [update]);

  const startIndex = (currentPage - 1) * POSTS_PER_PAGE;
  const endIndex = startIndex + POSTS_PER_PAGE;
  const currentPosts = posts ? posts.slice(startIndex, endIndex) : [];

  if (!initialPosts.current && (isLoadingPosts || isFetchingPosts || update)) {
    return <Loader content="Loading posts..." />;
  }

  return (
    <>
      {!isErrorPosts &&
        currentPosts.map(post => (
          <Post2
            key={post.id}
            post={post}
            setDataPostSelected={setDataPostSelected}
          />
        ))}
      {posts && posts.length >= 4 && (
        <ContentContainer>
          <SeeMoreButton type="button" onClick={handleLoadMore}>
            See More Posts
          </SeeMoreButton>
        </ContentContainer>
      )}
    </>
  );
};

export default Posts;
