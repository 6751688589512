import axios from '../lib/axios';
import { PRIVILEGIES_SCOPE_INSTAGRAM } from '../lib/initFacebookSdk';
import {
  notification,
  notificationError,
  notificationWithNodes,
} from '../lib/notification';

export const instagramPageSelected = async (page: FacebookPage[]) => {
  try {
    const response = await axios.post('/tools/instagram-specific', {
      pageData: page,
    });
    notification({
      type: 'success',
      title: 'Success',
      description: 'Instagram was enabled',
    });

    return response;
  } catch (error) {
    if (error?.response?.data?.message === 'The page could not be activated.') {
      notificationWithNodes({
        type: 'error',
        title: 'error',
        description: (
          <>
            <p>
              Please connect your Instagram page to your Facebook business page.
            </p>
            <a
              href="https://www.facebook.com/business/help/connect-instagram-to-page"
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here for instructions
            </a>
          </>
        ),
        duration: 0,
      });
    } else {
      notificationError({
        title: 'Error',
        description:
          error?.response?.data?.message || 'The page could not be added.',
      });
    }
    return error;
  }
};

export const instagramLogin = async () => {
  return new Promise((resolve, reject) => {
    FB.login(
      response => {
        if (response.authResponse) return resolve(response);
        return reject(Error());
      },
      { scope: PRIVILEGIES_SCOPE_INSTAGRAM },
    );
  });
};
