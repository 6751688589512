import { addDays, addHours, format } from 'date-fns';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import {
  Checkbox,
  Col,
  FlexboxGrid,
  FormGroup,
  InputGroup,
  InputNumber,
  Loader,
  Radio,
  RadioGroup,
  Row,
  SelectPicker,
  Slider,
} from 'rsuite';
import AddressAutocomplete from '../../components/addressAutocomplete/AddressAutocomplete';
import { ButtonAdd } from '../../components/createPost/CreatePost.styles';
import Warning from '../../components/warning/Warning';
import axios from '../../lib/axios';
import { getCurrencySymbol, getImage } from '../../lib/helpers';
import { notification, notificationError } from '../../lib/notification';
import { Container } from '../../styles/generalStyles';
import {
  BudgetContainer,
  BudgetInputContainer,
  ButtonContainer,
  ErrorMessage,
  FormItem,
  PostContainer,
  RadiusContainer,
  Subtitle,
  TitleForm,
} from './CreatePromotion.styles';
import { adValidationSchema } from './validation';

interface AdForm {
  budget: string;
  address: string;
  radius: number;
  postId: string;
  goal: string;
  showOnInstagram: boolean;
  payOption: 'day' | 'total';
  days: string;
}

interface Coordinates {
  latitude: number;
  longitude: number;
}

const fetchAdAccountInfo = async () => {
  try {
    const { data } = await axios.get<AdAccountInfo>('/promotions/account');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const GOALS = [
  { label: 'Engagement', value: 'engagement' },
  { label: 'Website visits', value: 'websiteVisits' },
];

const CreatePromotion = () => {
  const history = useHistory();
  const params = useParams<{ id: string }>();
  const [showWarning, setShowWarning] = useState(false);
  const [coords, setCoords] = useState<Coordinates | null>(null);

  useEffect(() => {
    (async () => {
      const { data } = await axios.get<{ hasInternalPromotions: boolean }>(
        '/promotions/internal',
      );

      setShowWarning(!data.hasInternalPromotions);
    })();
  }, []);

  const [pageId, postId] = params.id.split('_');

  const createAdFormik = useFormik<AdForm>({
    initialValues: {
      address: '',
      radius: 10,
      postId: params.id,
      goal: '',
      showOnInstagram: true,
      payOption: 'day',
      days: '7',
      budget: '10.00',
    },
    onSubmit: async () => {
      try {
        const { address, radius, goal, budget, showOnInstagram, payOption } =
          createAdFormik.values;

        const newPromotion = {
          coords,
          address,
          radius,
          goal,
          budget: parseFloat(budget),
          endDate: addHours(
            addDays(new Date(), parseInt(createAdFormik.values.days, 10)),
            1,
          ),
          postId: params.id,
          showOnInstagram,
          payOption,
        };

        await axios.post('/promotions', newPromotion);

        notification({
          type: 'success',
          title: 'Success',
          description: 'Ad was created',
        });

        history.push('/user-panel/promotions');
      } catch (err) {
        if (
          err.response?.data?.message ===
          `The link in this ad is either missing or invalid for the Link Clicks ad optimisation. This could be because this ad needs to link to external content (e.g. an advertiser's website), but it currently doesn't (e.g. it links to a Facebook Page). Fix this error by adding a valid link.`
        ) {
          notificationError({
            title: 'Error',
            description:
              'Your post does not include a target website link. Please add a link to the post or instead select "Engagement" as the objective of this campaign',
          });
        } else {
          notificationError({
            title: 'Error',
            description: err.response?.data?.message || 'Something went wrong',
          });
        }
      }
    },
    validationSchema: adValidationSchema,
    enableReinitialize: true,
  });

  const { isLoading, isError, data } = useQuery('adInfo', fetchAdAccountInfo, {
    refetchOnWindowFocus: false,
    onError: (err: Error) => {
      notificationError({
        description: err.message,
        title: 'Error',
      });
    },
  });

  const getCurrency = () => getCurrencySymbol(data?.accountInfo.currency || '');

  const handleMinus = () => {
    const currentValue = createAdFormik.values.days;

    if (parseInt(currentValue, 10) === 1) {
      return;
    }

    const newValue = parseInt(currentValue, 10) - 1;

    createAdFormik.setFieldValue('days', newValue);
  };

  const handlePlus = () => {
    const currentValue = createAdFormik.values.days;

    const newValue = parseInt(currentValue, 10) + 1;

    createAdFormik.setFieldValue('days', newValue);
  };

  const getEndDate = () => {
    const result = addDays(
      new Date(),
      parseInt(createAdFormik.values.days, 10),
    );

    return format(result, 'DD MMM YYYY');
  };

  const getTotalBudget = () => {
    const { days, budget, payOption } = createAdFormik.values;
    if (payOption === 'day') {
      return (parseInt(days, 10) * parseInt(budget, 10)).toFixed(2);
    }

    return budget;
  };

  return (
    <Container>
      <Row>
        <Col xs={24} md={12}>
          <Subtitle>New promotion on Facebook (and Instagram)</Subtitle>
          {isLoading && <Loader center content="loading" />}

          {!isLoading && !isError && (
            <>
              <FormItem>
                <TitleForm>Page</TitleForm>
                <img
                  src={getImage(data?.pageInfo.picture.data.url)}
                  alt="Page logo"
                />
                <span className="hasMargin">{data?.pageInfo.name}</span>
              </FormItem>

              <FormItem>
                <TitleForm>Ad Account</TitleForm>

                <p>
                  {data?.accountInfo.business_name} - {data?.accountInfo.id}
                </p>

                {showWarning && (
                  <Warning text="Please note that if you have not used this Ad Account recently, you should set up a new Ad in Facebook to avoid errors" />
                )}
              </FormItem>

              <FormItem>
                <TitleForm>Payment Method</TitleForm>
                <span>
                  <p>
                    {data?.accountInfo.funding_source_details.display_string}
                  </p>
                </span>
              </FormItem>

              <FormItem>
                <TitleForm>Goal</TitleForm>
                <SelectPicker
                  block
                  searchable={false}
                  data={GOALS}
                  onChange={value =>
                    createAdFormik.setFieldValue('goal', value)
                  }
                />
                <ErrorMessage>{createAdFormik.errors.goal}</ErrorMessage>
              </FormItem>

              <FormItem>
                <TitleForm>Audience</TitleForm>

                {showWarning && (
                  <div style={{ marginBottom: '25px' }}>
                    <Warning text="Please enter the address of your target location" />
                  </div>
                )}

                <AddressAutocomplete
                  onPlaceSelected={(place: string) => {
                    createAdFormik.setFieldValue('address', place);
                    createAdFormik.setFieldTouched('address', true, false);
                  }}
                  setCoords={setCoords}
                />
                {createAdFormik.touched.address &&
                  createAdFormik.errors.address && (
                    <ErrorMessage>{createAdFormik.errors.address}</ErrorMessage>
                  )}

                <RadiusContainer>
                  <Col md={10}>
                    <span>Radius of the promotion in km</span>
                  </Col>
                  <Col md={10}>
                    <Slider
                      progress
                      value={createAdFormik.values.radius}
                      onChange={value => {
                        createAdFormik.setFieldValue('radius', value);
                      }}
                      min={1}
                      max={80}
                    />
                  </Col>
                  <Col md={4}>
                    <InputNumber
                      min={1}
                      max={80}
                      value={createAdFormik.values.radius}
                      onChange={value =>
                        typeof value === 'number'
                          ? createAdFormik.setFieldValue('radius', value)
                          : createAdFormik.setFieldValue(
                              'radius',
                              parseInt(value, 10),
                            )
                      }
                    />
                  </Col>
                </RadiusContainer>
              </FormItem>

              <FormItem>
                <TitleForm>Instagram placement</TitleForm>
                <Checkbox
                  checked={createAdFormik.values.showOnInstagram}
                  onChange={(_value, checked) =>
                    createAdFormik.setFieldValue('showOnInstagram', checked)
                  }
                >
                  Show ad on Instagram
                </Checkbox>
              </FormItem>

              <FormItem>
                <TitleForm>Duration (Days)</TitleForm>
                <InputGroup>
                  <InputGroup.Button onClick={handleMinus}>-</InputGroup.Button>
                  <InputNumber
                    min={1}
                    onChange={value =>
                      createAdFormik.setFieldValue('days', value)
                    }
                    value={createAdFormik.values.days}
                  />
                  <InputGroup.Button onClick={handlePlus}>+</InputGroup.Button>
                </InputGroup>
                <p>End date: {getEndDate()}</p>
              </FormItem>

              <FormItem>
                <TitleForm>Budget</TitleForm>
                <BudgetContainer>
                  <BudgetInputContainer>
                    <InputNumber
                      step={0.01}
                      prefix={getCurrency()}
                      value={createAdFormik.values.budget}
                      onChange={value =>
                        createAdFormik.setFieldValue('budget', value)
                      }
                    />
                    <ErrorMessage>{createAdFormik.errors.budget}</ErrorMessage>
                  </BudgetInputContainer>
                  <div>
                    <FormGroup controlId="payOption">
                      <RadioGroup
                        inline
                        name="payOption"
                        value={createAdFormik.values.payOption}
                        onChange={value =>
                          createAdFormik.setFieldValue('payOption', value)
                        }
                      >
                        <Radio value="day">Per Day</Radio>
                        <Radio value="total">Total</Radio>
                      </RadioGroup>
                    </FormGroup>
                  </div>
                </BudgetContainer>
              </FormItem>
              <ButtonContainer>
                <FlexboxGrid.Item componentClass={Col} xs={24} md={10}>
                  <ButtonAdd
                    onClick={() => createAdFormik.submitForm()}
                    disabled={
                      !createAdFormik.isValid || createAdFormik.isSubmitting
                    }
                  >
                    <span>Boost post now</span>
                  </ButtonAdd>
                </FlexboxGrid.Item>
              </ButtonContainer>
            </>
          )}
        </Col>
        <Col xs={24} md={12}>
          <PostContainer>
            <div>
              <div
                className="fb-post"
                data-href={`https://www.facebook.com/${pageId}/posts/${postId}/`}
              />
              <div className="payment-summary">
                <h3>Payment Summary</h3>
                <p>Your add will run for {createAdFormik.values.days} days.</p>
                <p>
                  Total Budget: {getTotalBudget()} {getCurrency()}
                </p>
              </div>
            </div>
          </PostContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default CreatePromotion;
