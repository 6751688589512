import styled from 'styled-components';

export const WarningContainer = styled.div`
  background-color: white;
  color: ${props => props.theme.colors.red};
  padding: 10px 15px;
  border: 1px solid ${props => props.theme.colors.red};
  border-radius: 10px;
  font-family: 'Poppins';
  position: relative;
  display: flex;
  margin-top: 15px;
`;

export const CloseIcon = styled.img`
  width: 12px;
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
`;

export const Text = styled.p`
  margin-right: 25px;
`;
