/* eslint-disable @typescript-eslint/no-namespace */
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, FlexboxGrid } from 'rsuite';
import { Title } from './RegistrationComplete.styles';
import { Container } from '../../styles/generalStyles';

const RegistrationComplete = () => {
  const history = useHistory();

  useEffect(() => {
    const delay = 3000; // 3 seconds

    // firing the below google tag manager event (GA4 event) for google analytics
    // @ts-ignore
    window.dataLayer.push({
      event: 'complete_registration',
    });

    const timeoutId = setTimeout(() => {
      history.push('/user-panel/dashboard');
    }, delay);

    //    Clean up the timeout when the component is unmounted
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = '//cdn.trackdesk.com/tracking.js';
    script1.async = true;
    document.head.appendChild(script1);

    const script2 = document.createElement('script');
    script2.innerText = `
      (function(t,d,k){(t[k]=t[k]||[]).push(d);t[d]=t[d]||t[k].f||function(){(t[d].q=t[d].q||[]).push(arguments)}})(window,"trackdesk","TrackdeskObject");
      trackdesk("dinabite", "conversion", { "conversionType": "freetrial" });
    `;
    document.head.appendChild(script2);
  }, []);

  return (
    <Container>
      <FlexboxGrid componentClass={Col} xs={24} justify="space-around">
        <Title>Registration complete, Thank you</Title>
      </FlexboxGrid>
    </Container>
  );
};

export default RegistrationComplete;
