import axios from 'axios';

interface GeolocationResponse {
  location: {
    lat: number;
    lng: number;
  };
}

export const getUserCoords = async (): Promise<
  GeolocationResponse | undefined
> => {
  try {
    const geocodeUrl = `https://www.googleapis.com/geolocation/v1/geolocate?key=${process?.env?.REACT_APP_GOOGLE_API_KEY}`;
    const body = {
      considerIp: true,
    };

    const response = await axios.post(geocodeUrl, body);

    const { location } = response.data;

    return { location };
  } catch (error) {
    return undefined;
  }
};
