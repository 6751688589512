import styled from 'styled-components';

const BasicTitle = styled.p`
  color: ${props => props.theme.colors.black};
  font-weight: bolder;
`;

export const Subtitle = styled(BasicTitle)`
  font-size: ${props => props.theme.fonts.bigSubtitle};
  margin-bottom: 25px;
`;

export const TitleForm = styled(BasicTitle)`
  font-size: ${props => props.theme.fonts.paragraph};
  text-transform: uppercase;
  margin: 25px 0px;
`;

export const FormItem = styled.div`
  .hasMargin {
    margin-left: 10px;
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 25px;
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
`;

export const PostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;

  .payment-summary {
    margin-top: 25px;
  }
`;

export const ErrorMessage = styled.p`
  text-align: right;
  color: ${props => props.theme.colors.red};
`;

export const BudgetContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const BudgetInputContainer = styled.div`
  flex-grow: 1;
`;

export const RadiusContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
