import styled from 'styled-components';

export const Text = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: 550;
  text-align: center;
`;

export const Container = styled.div`
  background-color: ${props => props.theme.colors.pinkRed};
  height: 100vh;
  padding: 5%;
`;

export const Logo = styled.img`
  width: 25%;
  display: block;
  margin: auto;
  padding-bottom: 5%;
`;
