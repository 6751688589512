import axios from '../lib/axios';
import { notification, notificationError } from '../lib/notification';

export const sendverifyingEmail = async (email: string) => {
  try {
    const response = await axios.post('/users/resend-activation', { email });

      notification({
        type: 'success',
        title: 'Success',
        description: 'A verification email link has been sent. Please check your email.',
    });
    return response;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: 'Failed to send the verification email.',
    });
    return err;
  }
};
