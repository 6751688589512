import Error404 from '../views/error404/Error404';
import SignPanel from '../views/signPanel/SignPanel';
import SignUpSuccess from '../views/signUpSuccess/SignUpSuccess';
import TikTok from '../views/tiktok/TikTok';
import Twitter from '../views/twitter/Twitter';
import UberEats from '../views/uberEats/UberEats';
import UserPanel from '../views/userPanel/UserPanel';
import DinabiteSignpanel from '../views/DinabiteSignupPanel/dinabiteSignpanel';
import DinabiteSignup from '../views/DinabiteSignup/dinabite.signup';
import DinabiteSignIn from '../views/DinabiteSignin/dinabitesignin';
import DinabiteForgetPass from '../views/forgetPass/DinabiteForgetpass';
import DInabiteResetPassword from '../views/resetPassword/DinabiteResetPassword';
import AccountActivation from '../views/signUpSuccess/emailverifification'
import AppSumo from '../views/appsumo/appsumo';

const routes = [
  {
    path: '/active-account',
    // component: SignPanel,
    // component: DinabiteSignIn,
    component: AccountActivation,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/reset-password',
    component: DInabiteResetPassword,
    isPrivate: false,
    exact: true,
  },
  // {
  //   path: '/reset-password',
  //   component: SignPanel,
  //   isPrivate: false,
  //   exact: true,
  // },
  // {
  //   path: '/forget-pass',
  //   component: SignPanel,
  //   isPrivate: false,
  //   exact: true,
  // },
  {
    path: '/forget-pass',
    component: DinabiteForgetPass,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/appsumo',
    component: AppSumo,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/unsubscribe',
    component: SignPanel,
    isPrivate: false,
    exact: true,
  },
  // {
  //   path: '/sign-up',
  //   component: SignPanel,
  //   isPrivate: false,
  //   exact: true,
  // },
  {
    path: '/sign-up',
    component: DinabiteSignup,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/sign-up/success',
    component: SignUpSuccess,
    isPrivate: false,
    exact: true,
  },
  // {
  //   path: '/',
  //   component: SignPanel,
  //   isPrivate: false,
  //   exact: true,
  // },
  {
    path: '/',
    component: DinabiteSignpanel,
    isPrivate: false,
    exact: true,
  },
  // {
  //   path: '/login',
  //   component: SignPanel,
  //   isPrivate: false,
  //   exact: true,
  // },
  {
    path: '/login',
    component: DinabiteSignIn,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/user-panel',
    component: UserPanel,
    isPrivate: true,
    exact: false,
  },
  {
    path: '/twitter',
    component: Twitter,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/callback',
    component: TikTok,
    isPrivate: false,
    exact: true,
  },
  {
    path: '/uber-eats/redirect_uri',
    component: UberEats,
    isPrivate: false,
    exact: true,
  },
  {
    path: '*',
    component: Error404,
    isPrivate: false,
    exact: false,
  },
];

export default routes;
