import create from 'zustand';

interface MobileMenu {
  showMobileMenu: boolean;
  hideMobileMenu: () => void;
  toggleMobileMenu: () => void;
}

export const useMobileStore = create<MobileMenu>(set => ({
  showMobileMenu: false,
  hideMobileMenu: () => set(() => ({ showMobileMenu: false })),
  toggleMobileMenu: () =>
    set(state => ({ showMobileMenu: !state.showMobileMenu })),
}));

interface CurrentPost {
  post?: Post | null;
  comments: unknown[];
  likedByMe: boolean;
  setComments: (data: unknown[]) => void;
  setLikes: (likes: number) => void;
  setLikeByMe: (like: boolean) => void;
  setPost: (data?: Post | null) => void;
}

export const useCurrentPostStore = create<CurrentPost>(set => ({
  post: null,
  comments: [],
  likedByMe: false,
  setComments: (data: unknown[]) => set(state => ({ ...state, comments: data })),
  setLikeByMe: (like: boolean) => set(state => ({ ...state, likedByMe: like })),
  setLikes: (likes: number) =>
    set(state =>
      state.post
        ? { ...state, post: { ...state.post, totalLikes: likes } }
        : state,
    ),
  setPost: (data?: Post | null) =>
    set(state => ({...state, post: data ?? null, comments: [], likedByMe: false,})),
}));