import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Col, FlexboxGrid } from 'rsuite';
import ComingSoonModal from '../../components/modals/comingSoonModal/ComingSoonModal';
import Orders from '../../components/orders/Orders';
import SelectButtons from '../../components/selectButtons/SelectButtons';
import { Container } from '../../styles/generalStyles';
import { FakeButton, Title } from './delivery.styles';

const CONTENT_SELECT_BUTTONS = [
  {
    value: '/user-panel/delivery',
    content: 'Orders',
  },
];

const Delivery = () => {
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const history = useHistory();

  return (
    <Container>
      <ComingSoonModal
        description="This feature is coming soon"
        title="Coming Soon"
        show={showModal}
        onClose={() => setShowModal(false)}
      />
      <FlexboxGrid align="middle" justify="space-between">
        <FlexboxGrid.Item componentClass={Col} xs={24} md={7}>
          <Title>Delivery</Title>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item componentClass={Col} xs={24} md={17}>
          <SelectButtons
            content={CONTENT_SELECT_BUTTONS}
            defaultValue={location.pathname}
            setPage={value => history.push(value as string)}
          />
          <FakeButton onClick={() => setShowModal(true)}>
            <p>Menu</p>
          </FakeButton>
          <FakeButton onClick={() => setShowModal(true)}>
            <p>Promotions</p>
          </FakeButton>
        </FlexboxGrid.Item>
      </FlexboxGrid>

      {location.pathname === CONTENT_SELECT_BUTTONS[0].value && <Orders />}
    </Container>
  );
};

export default Delivery;
