import { useHistory } from 'react-router-dom';
import FacebookIcon from '../../assets/icons/facebook.svg';
import GoogleIcon from '../../assets/icons/google.svg';
import InstagramIcon from '../../assets/icons/instagram.png';
import TwitterIcon from '../../assets/icons/x.svg';
import TikTokIcon from '../../assets/icons/TikTok.svg';
import VideoIcon from '../../assets/icons/video.svg';
import DefaultImage from '../../assets/images/default.jpg';


// import { formatDate } from '../../lib/format';
import {
  ReelContainer,
  NetworkIcon,
  ContentContainer,
  ImageContainer,
  ImageTextContainer,
  ReelImage,
  ReelText,
  TextContainer,
  VideoImage,
} from './Reel2.styles';

interface Props {
  reel: Reel;
  setDataReelSelected?: React.Dispatch<React.SetStateAction<Reel | null>>;
}

const iconMapper = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  google: GoogleIcon,
  tiktok: TikTokIcon,
};

const Reel2 = ({ reel, setDataReelSelected }: Props) => {
  const history = useHistory();

  const handleLoadMore = () => {
    history.push(`/user-panel/reels`);
  };
  return (
    <ReelContainer>
      <NetworkIcon src={iconMapper[reel.network]} alt="Network" />
      <ContentContainer>
        <ImageTextContainer onClick={handleLoadMore}>
          <ImageContainer>
            {reel?.media.image ? (
              <>
                <ReelImage src={reel.media.image} alt="Reel" />
                {reel.media.type === 'reel' && (
                  <VideoImage
                    src={VideoIcon}
                    alt="Video Indicator"
                    style={{ position: 'absolute' }}
                  />
                )}
              </>
            ) : (
              <ReelImage src={DefaultImage} alt="Default Reel" />
            )}
          </ImageContainer>
          <TextContainer>
            <ReelText>{reel.text}</ReelText>
          </TextContainer>
        </ImageTextContainer>
        {/* <CtaContainer>
          <IconContainer>
            <Iconstyle
              src={eye} // Make sure to import or define your icon source
              alt="Delete"
            />
            <Iconstyle
              src={DeleteIcon} // Make sure to import or define your icon source
              alt="Delete"
            />
          </IconContainer>
        </CtaContainer> */}

        {/* <DateContainer>xx
          <ReelDate>{formatDate(reel.publishedAt)}</ReelDate>
        </DateContainer> */}
      </ContentContainer>
    </ReelContainer>
  );
};

export default Reel2;
