import styled from 'styled-components';

export const InputContainer = styled.div`
  margin-bottom: 40px;
  margin-right: 40px;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin-bottom: 10px;
    margin-right: 0px;
  }

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-bottom: 2px solid #d1d3d440;
  }

  input {
    width: 100%;
    outline: none;
    border: none;
    padding: 10px 25px;
    color: ${props => props.theme.colors.black};
    font-size: ${props => props.theme.fonts.paragraph};
    font-family: 'Poppins';

    :disabled {
      background-color: ${props => props.theme.colors.white};
    }
  }

  p {
    text-align: right;
    color: ${props => props.theme.colors.red};
  }
`;

export const EyeImage = styled.img`
  cursor: pointer;
`;

export const DivBottomBorder = styled(InputContainer)`
  margin-bottom: 10px;
  margin-right: 0px;
  input {
    background-color: transparent;
    border-bottom: 2px solid;
    border-color: ${props => props.theme.colors.grey};

    :focus {
      border-color: ${props => props.theme.colors.red};
    }
  }
`;
