/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import {
  CommentCardWrapper,
  DisplayRecentCommentsSection,
  ImageTextContainer,
  ModalCommentButton,
  SingleCommentWrapper,
} from './InstagramPreview.style';
import InstagramHeartLike from '../../../assets/icons/instagramHeartLikeLogo.svg';
import InstagramGrayHeartImage from '../../../assets/icons/instagramHeartGray.svg';

const DisplayRecentComments = ({ comments }: any) => {
  const [recentComments, setRecentComments] = useState([]);

  useEffect(() => {
    const filterRecentComments = () => {
      const now = new Date().getTime();
      const fiveMinutesAgo = now - 5 * 60000; // 5 minutes = 5 * 60000 milliseconds

      const filteredComments = comments?.filter(({ timestamp }: any) => {
        const commentTime = new Date(timestamp).getTime();
        return commentTime >= fiveMinutesAgo;
      });

      setRecentComments(filteredComments);
    };

    filterRecentComments();
    const interval = setInterval(filterRecentComments, 10000); // Update every 10 seconds

    return () => clearInterval(interval);
  }, [comments]);

  return (
    <>
      {recentComments?.length > 0 && (
        <DisplayRecentCommentsSection>
          {recentComments?.map(({ username, text, id, like_count }: any) => (
            <CommentCardWrapper key={id}>
              <ImageTextContainer>
                <SingleCommentWrapper>
                  <span>{username}</span>
                  <p>{text}</p>
                </SingleCommentWrapper>
              </ImageTextContainer>
              <ModalCommentButton>
                <img
                  src={
                    like_count > 0
                      ? InstagramHeartLike
                      : InstagramGrayHeartImage
                  }
                  alt="Like Icon"
                />
              </ModalCommentButton>
            </CommentCardWrapper>
          ))}
        </DisplayRecentCommentsSection>
      )}
    </>
  );
};

export default DisplayRecentComments;
