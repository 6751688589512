/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  Container,
  ImageContainer,
  ImagePreview,
  RemoveIcon,
  GridContainer,
} from './ImageSlider.style';
import { TYPES_POSTS } from '../../actions/postsActions';

interface ImageSliderProps {
  images: any;
  dispatchPost: any;
}

const ImageSlider = ({ images, dispatchPost }: ImageSliderProps) => {
  const handleRemoveImage = (index: number) => {
    dispatchPost({
      type: TYPES_POSTS.REMOVE_FILE,
      payload: { index },
    });
  };
  return (
    <Container>
      <GridContainer>
        {images?.length > 0 &&
          images.map((image: any, index: number) => (
            <ImageContainer key={image.name}>
              <ImagePreview src={image.urlFile} alt={image.name} />
              <RemoveIcon onClick={() => handleRemoveImage(index)}>
                &times;
              </RemoveIcon>
            </ImageContainer>
          ))}
      </GridContainer>
    </Container>
  );
};

export default ImageSlider;
