import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import {
  Redirect,
  Route,
  RouteComponentProps,
  useLocation,
} from 'react-router-dom';
import { TOKEN } from '../lib/globalValues';

interface Props {
  component: React.FC<RouteComponentProps>;
  path: string;
  isPrivate: boolean;
  exact: boolean;
}

const PrivateRoute = ({ path, component, isPrivate, exact }: Props) => {
  const token = localStorage.getItem(TOKEN);

  const location = useLocation();

  const isLoggedIn = localStorage.getItem(TOKEN);

  // if user is logged in, track visited page for GA
  useEffect(() => {
    if (isLoggedIn) {
      ReactGA.pageview(location.pathname);
    }
  }, [location]);

  return isPrivate && !token ? (
    <Redirect to="/" />
  ) : (
    <Route exact={exact} path={path} component={component} />
  );
};
export default PrivateRoute;
