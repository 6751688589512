import { Carousel } from 'rsuite';
import { CardWrapper, CarouselSliderContainer } from './ImageCarousel.style';
import scheduleImg from '../../assets/images/carousel/schedule.png';
import reviewsImg from '../../assets/images/carousel/reviews.png';
import messagesImg from '../../assets/images/carousel/messages.png';
import moneyImg from '../../assets/images/carousel/money.png';

const data = [
  {
    id: 1,
    img: scheduleImg,
    description: 'Post on Social Media',
  },
  {
    id: 2,
    img: reviewsImg,
    description: 'Respond to Reviews',
  },
  {
    id: 3,
    img: messagesImg,
    description: 'Answer Social Messages',
  },
  {
    id: 4,
    img: moneyImg,
    description: 'Make More Money',
  },
];

const ImageCarousel = () => {
  return (
    <CarouselSliderContainer>
      <Carousel autoplay placement="bottom" shape="bar">
        {data.map(item => (
          <CardWrapper key={item.id}>
            <img src={item.img} alt="imagess" />
            <span>{item.description}</span>
          </CardWrapper>
        ))}
      </Carousel>
    </CarouselSliderContainer>
  );
};

export default ImageCarousel;
