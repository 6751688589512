import { Col, Modal, Row } from 'rsuite';
import CropperImage from '../../cropper/ CropperImage';
import {
  ButtonGrey,
  ButtonRed,
  ContainerCropImage,
  ModalPosition,
} from './ModalCropperImage.styles';

interface Props {
  showCropImage: boolean;
  toggleShowCropImage: () => void;
  originalUrl: string;
  setCropper: React.Dispatch<React.SetStateAction<Cropper | undefined>>;
  getCropData: () => void;
  cropper: Cropper | undefined;
}

const ModalCropperImage = ({
  showCropImage,
  toggleShowCropImage,
  originalUrl,
  setCropper,
  getCropData,
  cropper,
}: Props) => {
  return (
    <div>
      <ModalPosition show={showCropImage} onHide={toggleShowCropImage}>
        <Modal.Body>
          <ContainerCropImage>
            <CropperImage
              originalUrl={originalUrl}
              setCropper={setCropper}
              cropper={cropper}
            />
          </ContainerCropImage>
        </Modal.Body>
        <Modal.Footer>
          <Row gutter={5}>
            <Col md={12} />
            <Col xs={12} md={6}>
              <ButtonGrey
                onClick={toggleShowCropImage}
                appearance="subtle"
                size="lg">
                Cancel
              </ButtonGrey>
            </Col>

            <Col xs={12} md={6}>
              <ButtonRed
                onClick={() => {
                  getCropData();
                  toggleShowCropImage();
                }}
                appearance="subtle"
                size="lg">
                <span>CROP</span>
              </ButtonRed>
            </Col>
          </Row>
        </Modal.Footer>
      </ModalPosition>
    </div>
  );
};

export default ModalCropperImage;
