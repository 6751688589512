/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  ButtonsContainer,
  CommentCardWrapper,
  ImageTextContainer,
  SingleCommentWrapper,
} from './InstagramPreview.style';
import { formatTimeDifference } from '../../../lib/format';
import userIcon from '../../../assets/icons/user.svg';

const DisplayRepliesComments = ({ replies }: any) => {
  return (
    <>
      {replies?.map(
        ({ username, id, user, timestamp, text, like_count }: any) => (
          <CommentCardWrapper key={id} style={{ paddingTop: '20px' }}>
            <img src={userIcon} alt={user?.username} className="image" />
            <ImageTextContainer>
              <SingleCommentWrapper>
                <span>{username}</span>
                <p>{text}</p>
              </SingleCommentWrapper>
              <ButtonsContainer>
                <span>{formatTimeDifference(timestamp)}</span>
                {like_count === 1 && like_count > 0 && (
                  <span>{like_count} like</span>
                )}
                {like_count > 0 && like_count > 1 && (
                  <span>{like_count} likes</span>
                )}
              </ButtonsContainer>
            </ImageTextContainer>
          </CommentCardWrapper>
        ),
      )}
    </>
  );
};

export default DisplayRepliesComments;
