import Select from 'react-select';
import styled from 'styled-components';

interface InputContainerProps {
  menuPlacement: boolean;
}

// export const CountrySelect = styled(Select)<{ error: boolean }>`
export const CountrySelect = styled(Select)<InputContainerProps>`
  background-color: #ffd6df; /* Light pinkish background */
  // padding: 1.4rem 1rem; /* Padding inside the input */
  margin: 0 auto;
  width: 85%; /* Width of the input */

  ${props =>
    props.error ? `border: 2px solid ${props.theme.colors.red};` : ``}

  // border: 1px solid #dc2058;

  border-radius: 10px; /* Rounded corners */
  padding: 12px 11px;
  /* padding-left: 40px; */
  // padding-right: 12px;
  // color: #DD2256;

  .css-26l3qy-menu {
    box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1) !important;
    z-index: 1000;
  }
  &::placeholder {
    color: #dd2256;
  }

  &:focus {
    border-color: #f857a6;
  }

  // color: ${props => props.theme.colors.greyLetter};

  div {
    color: ${props => props.theme.colors.greyLetter};
    border: none;
    box-shadow: none;
    background-color: transparent;
    font-weight: 500;
    color: #dd2256;

    :first-child {
      padding: 0px 10px;
    }

    :nth-child(3) {
      background-color: white;
      width: auto;
      div:first-child {
        padding: 8px;
        margin: 0px;
        font-weight: 900;
      }
    }
  }
  ${({ menuPlacement }) =>
    menuPlacement &&
    `
      font-family: 'Inter', sans-serif;
      color: #dd2256;
      width: 74%;               /* Width of the input */
      padding: 0.4rem 0.6rem;     /* Padding inside the input */
      font-weight: 100;
      font-size: 14px;  /* Custom font-size for signup */
      margin-bottom:10px;
      margin-top:0px;
      @media(max-width:1480px){
        width: 74%;               /* Width of the input */
        padding: 0rem 0.5rem;     /* Padding inside the input */
        font-weight: 900;
        margin-bottom:10px;

      }
      @media(max-width:1020px){
        width: 75%;               /* Width of the input */
        padding: 0rem 0.5rem;     /* Padding inside the input */
        font-weight: 900;
        margin-bottom:10px;

      }
      @media(max-width:780px){
        width: 79%;               /* Width of the input */
        // padding: 0.1rem 0.6rem; 
        padding: 7px 0.6rem;
        font-size: 16px;  /* Custom font-size for signup */
         margin-bottom:none;

      }
      @media(max-width:380px){
          width: 83%;               /* Width of the input */
        padding: 3px 0.6rem;
        font-size: 12px;  /* Custom font-size for signup */
         margin-bottom:none;

      }
      `}
`;

export const Placeholder = styled.div`
  // color: ${props => props.theme.colors.grey} !important

`;
