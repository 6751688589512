import { useHistory } from 'react-router-dom';
import { FlexboxGrid } from 'rsuite';
import { ButtonAdd, Card, ParentContainer } from './PaymentCancel.styles';

const PaymentCancel = () => {
  const history = useHistory();
  return (
    <ParentContainer>
      <Card>
        <h2>Your Payment has been canceled</h2>
        <FlexboxGrid>
          <ButtonAdd
            type="button"
            onClick={() => history.push('/user-panel/account')}
          >
            <span>Go to account</span>
          </ButtonAdd>
        </FlexboxGrid>
      </Card>
    </ParentContainer>
  );
};

export default PaymentCancel;
