import FacebookIcon from '../assets/icons/facebook.svg';
import FacebookGrey from '../assets/icons/facebookGrey.svg';
import GoogleIcon from '../assets/icons/google.svg';
import GoogleGrey from '../assets/icons/googleGrey.svg';
import InstagramIcon from '../assets/icons/instagram.png';
import InstagramGrey from '../assets/icons/instagramGrey.svg';
import JustEatIcon from '../assets/icons/justEat.svg';
import JustEatGrey from '../assets/icons/justEatGrey.svg';
import TripadvisorIcon from '../assets/icons/tripadvisor.svg';
import TripadvisorGrey from '../assets/icons/tripadvisorGrey.svg';
import TwitterIcon from '../assets/icons/x.svg';
import TwitterGrey from '../assets/icons/xGray.svg';
import UberEatsIcon from '../assets/icons/uberEats.svg';
import UberEatsGrey from '../assets/icons/uberEatsGrey.svg';
import TikTokGrey from '../assets/icons/TikTokGrey.svg';
import TikTokIcon from '../assets/icons/TikTok.svg';
import LinkedInGreyIcon from '../assets/icons/linkedin-grey.svg';
import LinkedInIcon from '../assets/icons/linkedin.svg';
import WhatsAppGreyIcon from '../assets/icons/WhatsApp-grey.svg';
import WhatsAppIcon from '../assets/icons/Watsapp-01.svg';

import { TYPES_OF_SOCIAL_NETWORKS } from './globalValues';

export const selectImgStatus = (type: string, checked: boolean) => {
  let image;
  let disabled = false;
  switch (type) {
    case TYPES_OF_SOCIAL_NETWORKS.FACEBOOK:
      image = checked ? FacebookIcon : FacebookGrey;
      break;

    case TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM:
      image = checked ? InstagramIcon : InstagramGrey;
      break;

    case TYPES_OF_SOCIAL_NETWORKS.TWITTER:
      image = checked ? TwitterIcon : TwitterGrey;
      break;

    case TYPES_OF_SOCIAL_NETWORKS.TRIPADVISOR:
      image = checked ? TripadvisorIcon : TripadvisorGrey;
      disabled = true;
      break;

    case TYPES_OF_SOCIAL_NETWORKS.GOOGLE:
      image = checked ? GoogleIcon : GoogleGrey;
      break;

    case TYPES_OF_SOCIAL_NETWORKS.TIKTOK:
      image = checked ? TikTokIcon : TikTokGrey;
      break;

    case TYPES_OF_SOCIAL_NETWORKS.JUST_EAT:
      image = checked ? JustEatIcon : JustEatGrey;
      disabled = true;
      break;

    case TYPES_OF_SOCIAL_NETWORKS.UBER_EATS:
      image = checked ? UberEatsIcon : UberEatsGrey;
      break;

    case TYPES_OF_SOCIAL_NETWORKS.LINKEDIN:
      image = checked ? LinkedInIcon : LinkedInGreyIcon;
      disabled = true;
      break;

    case TYPES_OF_SOCIAL_NETWORKS.WHATSAPP:
      image = checked ? WhatsAppIcon : WhatsAppGreyIcon;
      disabled = true;
      break;

    default:
      image = '';
      break;
  }

  return { image, disabled };
};

export const showPageName = (
  type: string,
  pageName: string,
  enabled: boolean,
) => {
  let name;
  let disabled = false;

  switch (type) {
    case TYPES_OF_SOCIAL_NETWORKS.FACEBOOK:
      name = enabled && pageName ? pageName : 'Facebook';
      break;
    case TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM:
      name = enabled && pageName ? pageName : 'Instagram';
      break;
    case TYPES_OF_SOCIAL_NETWORKS.TWITTER:
      name = enabled && pageName ? pageName : 'Twitter';
      break;
    case TYPES_OF_SOCIAL_NETWORKS.TIKTOK:
      name = enabled && pageName ? pageName : 'TikTok';
      break;
    case TYPES_OF_SOCIAL_NETWORKS.LINKEDIN:
      name = enabled && pageName ? pageName : 'LinkedIn';
      disabled = true;
      break;
    case TYPES_OF_SOCIAL_NETWORKS.WHATSAPP:
      name = enabled && pageName ? pageName : 'What&#39;s App';
      disabled = true;
      break;
    case TYPES_OF_SOCIAL_NETWORKS.GOOGLE:
      name = enabled && pageName ? pageName : 'Google';
      break;
    case TYPES_OF_SOCIAL_NETWORKS.TRIPADVISOR:
      name = enabled && pageName ? pageName : 'Tripadvisor';
      disabled = true;
      break;
    default:
      name = 'Unknown Network';
      break;
  }

  return { name, disabled };
};
