import styled from 'styled-components';

interface DropdownMenuProps {
  isOpen: boolean;
}

// export const SelectToolsDropDownContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   position: relative;
//   width: 100%;
// `;

export const DropdownContainer = styled.div<{ hide?: boolean }>`
  position: relative;
  display: inline-block;
  width: 100%;
  padding-left: 5px;

  ${({ hide }) =>
    hide
      ? `
        @media (max-width: 991px) {
          display: block;
          x
        }
        @media (min-width: 992px) {
          display: none;
        }
      `
      : `
        @media (max-width: 991px) {
          display: none;
        }
        @media (min-width: 992px) {
          display: block;
        }
      `}
`;

export const ToolsWrapper = styled.div`
  display: flex;
  gap: 5px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
`;

export const DropdownContent = styled.div<DropdownMenuProps>`
  position: absolute;
  top: 4.1rem;
  margin-top: 0.5rem;
  width: 99%;
  opacity: ${props => (props.isOpen ? '1' : '0')};
  visibility: ${props => (props.isOpen ? 'visible' : 'hidden')};
  transform: scaleY(${props => (props.isOpen ? '1' : '0')});
  transform-origin: top right;
  background: #ffffff;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  z-index: 99;
  padding: 10px;

  @media (max-width: 1480px) {
    top: 2.8rem;
    padding: 0px 5px;
  }
`;

export const DropdownToggleButton = styled.div`
  display: flex;
  gap: 5px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;

  @media (max-width: 1480px) {
    padding: 0px 5px;
  }
  @media (max-width: 991px) {
    margin-bottom: 0px;
  }
`;

export const DropdownIconWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

export const DownImg = styled.img<DropdownMenuProps>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;
