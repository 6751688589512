import React from 'react';

type DisplayTrialEndProps = {
  trialEndDate: string | null;
  payment: {
    subscriptionStatus: string;
    selectedPlan: string;
  };
};

const DisplayTrialEnd = ({ trialEndDate, payment }: DisplayTrialEndProps) => {
  const calculateDaysLeft = (endDate: string): number => {
    const now = new Date();
    const end = new Date(endDate);
    const difference = end.getTime() - now.getTime();
    return Math.ceil(difference / (1000 * 60 * 60 * 24)); // Convert to days
  };

  const daysLeft = trialEndDate ? calculateDaysLeft(trialEndDate) : null;

  const annualPriceId = 'price_1QRhc2G2PRFcrotTujWFqQ5F';
  const monthlyPriceId = 'price_1QRhbvG2PRFcrotTs7YddG1g';

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  if (payment?.subscriptionStatus === 'active_lifetime') {
    return <p>You have Lifetime access.</p>;
  }

  if (
    payment.selectedPlan === annualPriceId &&
    daysLeft !== null &&
    daysLeft > 0
  ) {
    const formattedDate = trialEndDate ? formatDate(trialEndDate) : '';
    return (
      <p>
        Your Annual subscription ends in {daysLeft} day
        {daysLeft > 1 ? 's' : ''}, on {formattedDate}.
      </p>
    );
  }

  if (
    payment.selectedPlan === monthlyPriceId && payment.subscriptionStatus !== 'trialing' &&
    daysLeft !== null &&
    daysLeft > 0
  ) {
    const formattedDate = trialEndDate ? formatDate(trialEndDate) : '';
    return (
      <p>
        Your Monthly Subscription ends in {daysLeft} day
        {daysLeft > 1 ? 's' : ''}, on {formattedDate}.
      </p>
    );
  }

  if (trialEndDate && daysLeft !== null && daysLeft > 0) {
    const formattedDate = formatDate(trialEndDate);
    return (
      <p>
        Your Free Trial ends in {daysLeft} day
        {daysLeft > 1 ? 's' : ''}, on {formattedDate}.
      </p>
    );
  }

  return <p>Your trial has ended.</p>;
};

export default DisplayTrialEnd;
