import { RadioButton, RadioGroupSelected } from './SelectButtons.styles';

interface Props {
  content: {
    value: string;
    content: string;
  }[];
  defaultValue: string;
  setPage: React.Dispatch<React.SetStateAction<string>>;
}

const SelectButtons = ({ content, defaultValue, setPage }: Props) => {
  return (
    <RadioGroupSelected
      name="radioList"
      inline
      appearance="picker"
      defaultValue={defaultValue}>
      {content.map(button => (
        <RadioButton
          key={button.value}
          value={button.value}
          onChange={(value: string) => setPage(value)}>
          {button.content}
        </RadioButton>
      ))}
    </RadioGroupSelected>
  );
};

export default SelectButtons;
