export const CLIENT_ID = process?.env?.REACT_APP_API_GOOGLE || '';
export const SCOPE_BUSINESS =
  'https://www.googleapis.com/auth/business.manage https://www.googleapis.com/auth/userinfo.profile openid email';

export const COOKIE_POLICY = 'single_host_origin';

export const getStarRating = (rating: string) => {
  switch (rating) {
    case 'ONE':
      return 1;
    case 'TWO':
      return 2;
    case 'THREE':
      return 3;
    case 'FOUR':
      return 4;
    case 'FIVE':
      return 5;
    default:
      return 0;
  }
};
