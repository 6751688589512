import styled from 'styled-components';

export const Title = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: 550;
`;

export const FakeButton = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;

  p {
    margin-bottom: 0;
    text-align: center;
    padding: 8px 12px;
    font-size: 14px;
    color: #8e8e93;
    padding: 7px 10px;
  }
`;
