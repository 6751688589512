import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {Button} from './appsumo.styles'

const AppSumo: React.FC = () => {
  const history = useHistory();
  const [popupMessage, setPopupMessage] = useState<string | null>(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    const licenseKey = urlParams.get('licenseKey');
    const status = urlParams.get('status');
    const error = urlParams.get('error');

    if (success === 'true') {
      console.log("License processed successfully:", { licenseKey, status });

      // Show appropriate popup message
      if (status === 'already_subscribed') {
        setPopupMessage('Already Subscribed');
      } else {
        setPopupMessage('Welcome to dinbaite from AppSumo!');
      }

      // Navigate to sign-up page after showing the popup
      history.push('/sign-up');
    } else if (error) {
      console.error("Error occurred during AppSumo OAuth process:", error);
      // Handle error: show an error message to the user
    }
  }, [history]);

  // Function to close the popup with explicit MouseEventHandler type
  const handleClosePopup: React.MouseEventHandler<HTMLButtonElement> = () => {
    setPopupMessage(null);
  };
  

  return (
    <>
      <div>
        Hello AppSumo
      </div>

      {/* Show popup if there's a message */}
      {popupMessage && (
        <div className="popup">
          <div className="popup-message">
            {popupMessage}
            <Button onClick={handleClosePopup}>Close</Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AppSumo;
