import { Radio, RadioGroup } from 'rsuite';
import styled from 'styled-components';

export const RadioButton = styled(Radio)`
  border-radius: 100px;
  margin: 0px 10px;
`;

export const RadioGroupSelected = styled(RadioGroup)`
  border: none;

  > .rs-radio-checked {
    background-color: ${props => props.theme.colors.red};
  }

  .rs-radio-checked .rs-radio-checker > label {
    color: white;
    font-weight: bolder;
    line-height: 30px;
  }

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin-top: 2em;
  }
`;
