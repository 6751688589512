import axios from '../lib/axios';
import { notification, notificationError } from '../lib/notification';

export const assignAdAccount = async (
  companyId: number,
  adAccountId: string,
) => {
  try {
    const response = await axios.post('/tools/assign-ad-account', {
      companyId,
      adAccountId,
    });
    notification({
      type: 'success',
      title: 'Success',
      description: 'Ad account connected',
    });
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description:
        error?.response?.data?.message || 'The ad account could not be added.',
    });
    return error;
  }
};
