import { Button, Modal, Row } from 'rsuite';
import styled from 'styled-components';

export const RedSubtitle = styled.p`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fonts.subtitle};
  font-weight: bold;
`;

export const BolderParagraph = styled.p`
  font-weight: bolder;
`;

export const ImgSizes = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
`;

export const TextArea = styled.textarea`
  width: 100%;

  resize: none;
  margin: 8px 2px 0px;
`;

export const ButtonRed = styled(Button)`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  border-radius: 50px;
  padding: 20px;
  width: 100%;
`;

export const ButtonGrey = styled(ButtonRed)`
  background-color: ${props => props.theme.colors.grey};
  color: ${props => props.theme.colors.white};
`;

export const ModalPosition = styled(Modal)`
  @media ${props => props.theme.breakpoints.xs} {
    position: absolute;
    bottom: 0px;
    width: 100%;

    div.rs-modal-dialog {
      margin: 0px;
    }
  }

  font-family: 'Poppins';
`;

export const RowSpace = styled(Row)`
  margin-bottom: 20px;
`;

export const TitleSection = styled.p`
  font-size: ${props => props.theme.fonts.subtitle};
`;
