import styled from 'styled-components';

export const Container = styled.div`
  margin-right: 30px;

  span {
    text-align: left;
    color: ${props => props.theme.colors.greyLetter};
    text-align: left;
  }

  .status {
    color: ${props => props.theme.colors.red};
    font-weight: bold;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 50px;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Image = styled.img`
  height: 250px;
  width: 425px;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
`;

export const PlatformsContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 15px;
  justify-content: center;
  align-items: center;
`;

export const PlatformContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
`;

export const Platform = styled.img`
  height: 15px;
`;

export const Content = styled.div`
  padding: 25px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0 0 15px 15px;
  height: 250px;
`;

export const Title = styled.h5`
  font-size: ${props => props.theme.fonts.paragraph};
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  max-width: 250px;
`;

export const AdItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;

  .row {
    flex-direction: row;
    display: flex;
    margin-bottom: 10px;
  }

  p {
    margin-left: 5px;
    text-align: left;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const ProgressContainer = styled.div`
  height: 5px;
  border-radius: 12px;
  margin: 10px 0;
  background-color: ${props => props.theme.colors.greyLetter};
`;

export const Progress = styled.div<{ width: number }>`
  width: ${props => `${props.width}%`};
  background-color: red;
  height: 100%;
  border-radius: 12px;
`;
