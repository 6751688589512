import axios from '../lib/axios';
import { notification, notificationError } from '../lib/notification';

export const updateStripeCustomer = async (
  companySelectedId: number | undefined,
  updatedData: string,
) => {
  try {
    const response = await axios.put(`stripe/update-customer`, {
      companyId: companySelectedId,
      billingEmail: updatedData,
    });

    notification({
      type: 'success',
      title: 'Success',
      description: 'Billing email was updated',
    });

    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Billing email could not be updated',
    });
    return error;
  }
};
