/* eslint-disable @typescript-eslint/no-explicit-any */
import { Uploader as UploderRsuite } from 'rsuite';
import { Content } from '../uploader.styles';

interface Props {
  handleFileUpload: (selectedFile: File) => Promise<void>;
  describe: string;
  image: string;
  restrictFiles?: string;
}

const UploaderImage = ({
  handleFileUpload,
  describe,
  image,
  restrictFiles,
}: Props) => {
  const handleChange = (images: string | any[]) => {
    if (images.length && images[images.length - 1].blobFile) {
      handleFileUpload(images[images.length - 1].blobFile);
    }
  };
  return (
    <UploderRsuite
      listType="picture-text"
      autoUpload={false}
      draggable
      onChange={images => handleChange(images)}
      multiple={false}
      fileListVisible={false}
      accept={restrictFiles}>
      <Content>
        <img src={image} alt="Upload your file" />
        <p>{describe}</p>
      </Content>
    </UploderRsuite>
  );
};

export default UploaderImage;
