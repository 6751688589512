/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import  { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Col, FlexboxGrid, Loader, Row } from 'rsuite';
import Add from '../../assets/icons/add.svg';
import SocialCard from '../../components/cards/socialCard/SocialCard';
import DrawerReel from '../../components/drawers/DrawerReel/DrawerReel';
import Reel from '../../components/reel/Reel';
import ScheduledPost from '../../components/scheduledPost/ScheduledPost';
import useReels from '../../hooks/useReels';
import useScheduledReels, {
  KEY_SCHEDULED_REELS,
} from '../../hooks/useScheduledReels';
import { notificationError } from '../../lib/notification';
import { fetchFollowers } from '../../services/followers';
import { removeReel } from '../../services/reels';
import { Container } from '../../styles/generalStyles';
import {
  ButtonAdd,
  FollowersContainer,
  Header,
  HeaderFlexbox,
  ReelsContainer,
  Test,
} from './Reels.styles';

const TABLE_TYPES_REELS = {
  SCHEDULED: 'SCHEDULED',
  PREVIOUS: 'PREVIOUS',
};

const Reels = () => {
  const history = useHistory();
  const { isLoading, isError, isFetching, data: reels } = useReels();
  const {
    isLoading: isLoadingScheduled,
    isError: isErrorScheduled,
    data: scheduledReels,
  } = useScheduledReels();
  const [dataReelSelected, setDataReelSelected] = useState<Reel | null>(null);
  const [showTable, setShowTable] = useState(TABLE_TYPES_REELS.PREVIOUS);

  const {
    isLoading: isLoadingFollowers,
    isError: isErrorFollowers,
    data: followers,
  } = useQuery('followers', fetchFollowers, {
    refetchOnWindowFocus: false,
    onError: (err: Error) => {
      notificationError({ description: err.message, title: 'Error' });
    },
  });

  const queryClient = useQueryClient();

  const { mutate, isLoading: isLoadingremoveReel } = useMutation(
    (selectedReelId: number) => removeReel(selectedReelId),
    {
      onSuccess(response) {
        queryClient.setQueryData(KEY_SCHEDULED_REELS, (oldData: any) => {
          return [
            ...oldData.filter(
              (reel: ScheduledReels) => reel.id !== response.id,
            ),
          ];
        });
      },
    },
  );

  const handleRemoveScheduledPost = (scheduledReelId: number) => {
    mutate(scheduledReelId);
  };

  return (
    <Container>
      <FlexboxGrid align="middle" style={{ height: '80px'}}>
        <Col xs={24} md={17}>
          <FlexboxGrid align="middle">
            <FlexboxGrid.Item
              componentClass={Col}
              xs={24}
              md={5}
              style={{ marginBottom: 20 }}>
              <ButtonAdd
                type="button"
                onClick={() => setShowTable(TABLE_TYPES_REELS.PREVIOUS)}>
                <span>Previous</span>
              </ButtonAdd>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              componentClass={Col}
              xs={24}
              md={12}
              style={{ marginBottom: 20 }}>
              <ButtonAdd onClick={() => history.push('reels/new')}>
                <span>New Reel</span>
                <img src={Add} alt="add reel" />
              </ButtonAdd>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Col>
      </FlexboxGrid>
      <Row style={{ height: 'calc(100% - 80px)',overflow:'hidden'}}>
        <Test style={{ height: '100%'}}>
          <Col xs={24} lg={17} style={{height: '100%' }}>
            {showTable === TABLE_TYPES_REELS.SCHEDULED && (
              <>
                {isLoadingScheduled && <Loader center content="loading" />}
                {!isLoadingScheduled &&
                  !isErrorScheduled &&
                  !isLoadingremoveReel &&
                  scheduledReels && (
                    <>
                      <HeaderFlexbox align="middle" style={{ height: '80px' }}>
                      <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={5}>
                          Social networks
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={5}>
                          Image
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={5}>
                          Text
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={6}>
                          Scheduled date
                        </FlexboxGrid.Item>
                        <FlexboxGrid.Item
                          componentClass={Col}
                          xsHidden
                          smHidden
                          md={3}>
                          Cancel
                        </FlexboxGrid.Item>
                      </HeaderFlexbox>
                      <ReelsContainer style={{ height: 'calc(100% - 80px)'}}>
                        {scheduledReels?.map(post => (
                          <ScheduledPost
                            post={post}
                            handleRemoveScheduledPost={
                              handleRemoveScheduledPost
                            }
                          />
                        ))}
                      </ReelsContainer>
                    </>
                  )}
              </>
            )}
            {showTable === TABLE_TYPES_REELS.PREVIOUS && (
              <>
                {(isLoading || isFetching) && (
                  <Loader center content="loading" />
                )}
                {!isLoading && !isFetching && !isError && reels && (
                  <>
                    <Header>
                      <div className="image-header">Video</div>
                      <div className="text-header">Text</div>
                      <div className="reel-header">Posted</div>
                    </Header>
                    <ReelsContainer style={{ height: 'calc(100% - 80px)'}}>
                    {reels.map(reel => (
                        <Reel
                          key={reel.id}
                          reel={reel}
                          setDataReelSelected={setDataReelSelected}
                        />
                      ))}
                    </ReelsContainer>
                  </>
                )}
              </>
            )}
          </Col>
          {isLoadingFollowers && <Loader content="loading" />}
          <Col xs={24} lg={7} style={{height: 'auto',marginBottom: '40px' }}> 
            <FollowersContainer>
              {!isLoadingFollowers &&
                !isErrorFollowers &&
                followers?.map((totalCard: Followers) => (
                  <SocialCard
                    key={totalCard.type + totalCard.followers}
                    data={totalCard}
                  />
                ))}
            </FollowersContainer>
          </Col>
        </Test>
      </Row>
      <DrawerReel
        showDrawer={dataReelSelected}
        setShowDrawer={setDataReelSelected}
      />
    </Container>
  );
};

export default Reels;
