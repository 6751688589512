import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { FlexboxGrid as RSFlexboxGrid } from 'rsuite';

export const ContentContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  // overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 780px) {
    width: 100%;
    overflow-y: auto;
    margin-bottom: 100px;
  }
`;
export const Title = styled.h2`
  margin-top: 3rem;
  font-family: 'Inter', sans-serif;
  font-size: 35px;
  color: #dd2256;
  text-align: center; /* Center the text */
  margin-bottom: 4rem;

  @media (max-width: 1480px) {
    font-size: 30px;
    padding: 0 60px;
    margin-bottom: 2rem;
  }

  @media (max-width: 1280px) {
    font-size: 22px;
    margin-bottom: 2rem;
    margin-top: 3rem; /* Optional, as it is same as default */
  }

  @media (max-width: 1024px) {
    font-size: 22px;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;
export const Subtitle = styled.p`
  font-family: 'Inter', sans-serif;

  font-size: 25px;
  color: #666;
  margin-bottom: 60px;
  padding: 0 50px;
  text-align: center; /* Center the text */
  @media (max-width: 1480px) {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
    padding: 0 70px;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    padding: 0 10px;
  }

  @media (max-width: 1280px) {
    font-size: 16px;
    padding: 0 10px;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const Titlembile = styled.h2`
  display: none; /* Hide by default */

  @media (max-width: 780px) {
    font-family: 'Inter', sans-serif;

    width: 90%;
    display: block; /* Show on mobile */
    font-size: 26px;
    color: #dd2256;
    font-family: 'Inter', sans-serif;
    text-align: left; /* Align text to the left */
    margin: 0; /* Reset margins if necessary */
    @media (max-width: 380px) {
      width: 90%;
      font-size: 22px;
    }
  }
`;
export const Subtitlemobile = styled.p`
  display: none;
  @media (max-width: 780px) {
    width: 90%;
    font-family: 'Inter', sans-serif;

    display: block;
    font-size: 18px;
    color: #666;
    text-align: left; /* Center the text */
    font-size: 14px;
    margin-bottom: 5px;
  }

  @media (max-width: 380px) {
    width: 100%;
    padding: 0 20px;
    font-size: 12px;
  }
`;

export const socialcontainers = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  background: #fff;
  justify-content: space-between;
  justify-content: space-between;
  align-items: flex-start;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  min-height: 150px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Add a shadow to distinguish it from the content above */
  margin: 0 auto;
  @media (max-width: 780px) {
    display: none;
  }
`;
export const SocialButton = styled.button`
  display: flex;
  margin: 0 auto;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Align items vertically in the center */
  font-family: 'Inter', sans-serif;
  width: 95%;
  background-color: white;
  color: #dd2256;
  border: 1px solid #dd2256;
  padding: 20px; /* Padding around the button */
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
  font-size: 18px; /* Font size for the button text */
  gap: 18px; /* Space between the icon and text */
  margin-bottom: 10px;
  &:hover {
    background-color: #dd2256;
    color: white;
  }

  @media (max-width: 1480px) {
    width: 85%;
    font-size: 16px;
    padding: 15px;
  }
  @media (max-width: 1280px) {
    width: 85%;
    font-size: 16px;
    padding: 15px;
  }
  @media (max-width: 780px) {
    width: 85%;
    margin: 10px auto;
    font-size: 15px;
    padding: 15px;
  }
  @media (max-width: 380px) {
    font-size: 12px;
    padding: 10px;
  }
`;

export const SocialText = styled.span`
  font-family: 'Inter', sans-serif;
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically */
  text-align: center;
  font-size: 18px;

  display: flex;
  justify-content: center;
  align-items: right;

  @media (max-width: 780px) {
    font-size: 18px;
  }
  @media (max-width: 320px) {
    font-size: 14px;
  }
`;

export const BackButtondiv = styled.div`
  display: none;
  width: 100%;
  background-color: #fff;
  @media (max-width: 780px) {
    display: flex;
    position: fixed;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: #fff;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    min-height: 60px;
  }

  @media (max-width: 780px) {
    dispaly: block;
    padding: 0;
    bottom: 0;
    background-color: #fff;
    height: 100px;
  }
  @media (max-width: 380px) {
    padding: 0;
    bottom: 0px;
    background-color: #fff;
    height: 70px;
  }
`;

export const BackButtonnext = styled.button`
  color: #fff;
  width: 65%;
  border: 1px solid #dd2256;
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  &:hover {
    background-color: #c51e4a;
    color: #fff;
  }
  @media (max-width: 780px) {
    width: 65%;
    height: 2.5rem;
    bottom: 30px;
  }

  @media (max-width: 480px) {
    width: 80%;
    height: 3rem;
    font-size: 18px;
    border-radius: 15px;
    position: absolute;
    bottom: 30px;
  }

  @media (max-width: 380px) {
    width: 80%;
    height: 3rem;
    font-size: 18px;
    border-radius: 15px;
    margin-bottom: 30px;
    bottom: 20px;
  }
`;

export const NextButtonContainer = styled.div`
  width: 100%;

  border: 0.1em solid #ffffff;
  margin: 0 0.3em 0.3em 0;
  border-radius: 0.12em;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  transition: all 0.2s;
  margin-bottom: 20px;
  margin-top: 12px;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const Button = styled.button`
  width: 70%; /* Makes the button fill its container */
  font-family: 'Inter', sans-serif;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  color: #fff;
  padding: 14px 0; /* Standardizes padding to ensure the same height */
  border: 1px solid #dd2256;
  border-radius: 15px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 1480px) {
    width: 60%; /* Makes the button fill its container */
    font-size: 14px;
    border-radius: 12px;
    padding: 10px 0; /* Standardizes padding to ensure the same height */
  }
  @media (max-width: 1280px) {
    width: 65%; /* Makes the button fill its container */
    font-size: 12px;
    border-radius: 12px;
    padding: 12px 0; /* Standardizes padding to ensure the same height */
  }
  @media (max-width: 1020px) {
    width: 65%; /* Makes the button fill its container */

    font-size: 12px;
    border-radius: 10px;
    padding: 12px 0; /* Standardizes padding to ensure the same height */
  }
  @media (max-width: 780px) {
    display: none;
  }
`;
export const FlexboxGrid = styled(RSFlexboxGrid)`
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  justify-content: space-between; /* Align items to the top */
  width: 100%; /* Ensure it takes full width of its container */
  border-radius: 8px; /* Optional: Rounded corners */
  @media (max-width: 780px) {
    width: 100%; /* Ensure it takes full width of its container */
  }
  &:hover {
    color: black;
  }
`;

// Define custom styles for FlexboxGrid items
export const FlexboxGridItem = styled(RSFlexboxGrid.Item)`
  // margin: 8px 0;
  padding: 6px;
  background-color: #ffffff; /* Background color for items */
  border-radius: 4px; /* Rounded corners for items */
  width: 95%;
  margin: 0 auto;
  /* Optional: Add hover effect */
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 1480px) {
    width: 85%;
  }
`;
export const LinkForm = styled(Link)`
  /* font-size: ${props => props.theme.fonts.subtitle}; */
  font-size: 12px;
  color: #4a4a4a;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 200;
  font-family: 'Inter', sans-serif;
  border-bottom: 0.2px solid #b2b2bd; /* Light gray line */
  :hover {
    color: ${props => props.theme.colors.red};
  }
`;
export const LinkForm2 = styled(Link)`
  /* font-size: ${props => props.theme.fonts.subtitle}; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  font-size: 22px;
  color: blue;
  cursor: pointer;
  text-decoration: none !important;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  :hover {
    color: ${props => props.theme.colors.red};
  }
  @media (max-width: 1480px) {
    font-size: 18px;
  }
  @media (max-width: 1280px) {
    font-size: 18px;
  }
  @media (max-width: 1024px) {
    font-size: 16px;
  }
  @media (max-width: 780px) {
    font-size: 16px;
  }
`;
