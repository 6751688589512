import { useTheme } from 'styled-components';

import { SpaceBetween, CircleButton } from './ColorsContent.styles';

interface Props {
  componentColor: {
    background: string;
    colorTitle: string;
    colorSubtitle: string;
  };
  typePosition: 'background' | 'colorTitle' | 'colorSubtitle';

  setComponentColor: React.Dispatch<
    React.SetStateAction<{
      background: string;
      colorTitle: string;
      colorSubtitle: string;
    }>
  >;
}

const ColorsContent = ({
  componentColor,
  typePosition,
  setComponentColor,
}: Props) => {
  const theme = useTheme();
  const handleChangeColor = (color: string) => {
    setComponentColor({ ...componentColor, [typePosition]: color });
  };
  return (
    <SpaceBetween>
      <CircleButton
        type="button"
        bgColor={theme.colors.orange}
        onClick={() => handleChangeColor(theme.colors.orange)}
      />
      <CircleButton
        type="button"
        bgColor={theme.colors.blue}
        onClick={() => handleChangeColor(theme.colors.blue)}
      />
      <CircleButton
        type="button"
        bgColor={theme.colors.green}
        onClick={() => handleChangeColor(theme.colors.green)}
      />
      <CircleButton
        type="button"
        bgColor={theme.colors.white}
        onClick={() => handleChangeColor(theme.colors.white)}
      />
      <CircleButton
        type="button"
        bgColor={theme.colors.black}
        onClick={() => handleChangeColor(theme.colors.black)}
      />
    </SpaceBetween>
  );
};

export default ColorsContent;
