import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { LOGIN_PROVIDER_KEY, PROVIDER, TOKEN } from '../lib/globalValues';
import { loginEmail, loginFacebook, loginGoogle } from '../services/auth';

interface PropsStorage {
  user: User;
  access_token: string;
}
const useUser = () => {
  const { setUser, setCompanySelected, setLoginProvider } = useAuth();
  const history = useHistory();
  const queryClient = useQueryClient();

  const storage = ({
    user: apiUser,
    access_token: accessToken,
  }: PropsStorage) => {
    setUser(apiUser);

    setCompanySelected(apiUser.companies[0]);
    localStorage.setItem(TOKEN, accessToken);
  };

  const handleLogout = () => {
    queryClient.invalidateQueries();
    localStorage.removeItem(TOKEN);
  };

  const login = async (email: string, password: string) => {
    const response = await loginEmail(email, password);
    if (response.data) {
      storage(response.data);
      history.push('/user-panel/dashboard');
    }
  };
  const login2 = async (email: string, password: string) => {
    localStorage.removeItem('user');
    localStorage.removeItem(TOKEN);
    
    const response = await loginEmail(email, password);
    
    if (response && response.data) {
      
      storage(response.data);
      return {
        user: response.data.user,
        access_token: response.data.access_token,
      };
    }

    return null;
  };
  

  const loginSocial = async (
    tokenId: string,
    socialProvider: 'facebook' | 'google',
    userID?: string | undefined,
    saveToLocalStorage = false,
  ) => {
    
    let res;
    if (userID) {
      res = await loginFacebook(tokenId, userID);
    } else {
      res = await loginGoogle(tokenId);

    }
    if (res.data) { 
      if (res.data?.register) 
      {

        if (!saveToLocalStorage) {
          setLoginProvider({
            tokenID: tokenId,
            provider: PROVIDER.SOCIAL,
            userID: userID || null,
            socialProvider,
            data: { ...res.data },
          });

          // console.log("loginProvider data set:", {
          //   tokenID: tokenId,
          //   provider: PROVIDER.SOCIAL,
          //   userID: userID || null,
          //   socialProvider,
          //   data: { ...res.data },
          // });
        } else {

          const loginProviderKey = JSON.stringify({
            tokenID: tokenId,
            provider: PROVIDER.SOCIAL,
            userID: userID || null,
            socialProvider,
            data: { ...res.data },
          });
          localStorage.setItem(LOGIN_PROVIDER_KEY, loginProviderKey);
        }
        return res;
      }

      storage(res.data);
    }
    return res;
  };
  

  return { login,login2, loginSocial, handleLogout, storage };
};

export default useUser;
