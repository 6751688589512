import styled from 'styled-components';

export const Title = styled.p`
  text-align: left;
  color: ${props => props.theme.colors.grey};
  font-size: ${props => props.theme.fonts.subtitle};
  font-weight: bold;
  padding: 0px;
`;

export const Value = styled.p`
  text-align: left;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: bold;
  padding: 0px;
`;
