/* eslint-disable @typescript-eslint/no-explicit-any */

import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

export const getAccountsGoogleBusiness = async (code: string) => {
  try {
    const response = await axios.post(`/tools/google-business-account/`, {
      code,
    });

    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description:
        error?.response?.data?.message || 'The business could not be added.',
    });
    return error;
  }
};

export const getCompaniesOfAccountGoogleBusiness = async ({
  tokenId,
  credentials,
}: any) => {
  try {
    const response = await axios.post(`/tools/google-business-specific/`, {
      tokenId,
      credentials,
    });

    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Error',
    });
    return error;
  }
};

export const getReviewsTools = async (companyId: number) => {
  try {
    const getReviews = await axios.get(`/tools/reviews/${companyId}`);

    return getReviews;
  } catch (error) {
    return error;
  }
};

export const replyReview = async ({
  reviewId,
  comment,
  companyId,
}: {
  reviewId: string;
  comment: string;
  companyId: number;
}) => {
  try {
    const getReviews = await axios.post(`/tools/reviews/google`, {
      reviewId,
      comment,
      companyId,
    });
    if (getReviews.data.error) {
      throw new Error(getReviews.data.error);
    }
    return getReviews;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Could not reply to comment.',
    });
    return error;
  }
};
