import { FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 8px 0;
  border-radius: 10px;
  border: 1px solid #d3d3d3;
  padding: 10px 15px;
  cursor: pointer;
`;

export const RoundedFlexbox = styled(FlexboxGrid.Item)<{ color: string }>`
  background: ${props => props.color};
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 10px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  word-break: break-word;
  p {
    margin-top: 8px;
    color: ${props => props.theme.colors.black};
  }
`;

export const SpanBlack = styled.span`
  color: ${props => props.theme.colors.black};
`;
export const SpanLBlack = styled.span`
  color: ${props => props.theme.colors.lightBlack};
`;

export const ActiveDot = styled.span`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: #e74138;
  right: 10px;
`;

export const Button = styled.button`
  color: ${props => props.theme.colors.black};
  height: max-content;
  border-radius: 12px;
  padding: 10px 15px;
`;

export const DateP = styled.p`
  text-align: left;
  font-size: ${props => props.theme.fonts.small};
`;
