import styled, { keyframes } from 'styled-components';

// interface StepCircleProps {
//     active: boolean;
//   }

export const Title = styled.h2`
  font-size: 30px;
  color: #dd2256;
  margin: 0 auto 20px auto;
  padding: 0 8 0px;
  font-family: 'Inter', sans-serif;
  text-align: center; /* Center the text */

  @media (max-width: 780px) {
    font-size: 30px;
    padding: 0 20px;
    margin-bottom: 10px;
  }
  @media (max-width: 1024px) {
    font-size: 30px;
    padding: 0 20px;
    // margin-bottom: 10px;
  }

  @media (max-width: 380px) {
    font-size: 30px;
    margin: 0 auto;
  }
`;
export const Subtitle = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 2rem;
  padding: 0 70px;
  text-align: center;
  width: 90%;
  // margin-bottom:30px;
  margin: 30px auto;
  font-family: 'Inter', sans-serif;

  //  @media (max-width: 1080px) {
  //     font-size: 18px;
  //     padding: 0;
  //     margin-top: 0;
  //     padding:1rem;

  //   }
  @media (max-width: 780px) {
    font-size: 18px;
    padding: 0;
    margin-bottom: 0.2rem;
    margin-top: 0;
    padding: 1rem;
    width: 80%;
  }
  @media (max-width: 380px) {
    font-size: 14px;
  }
`;

export const Subtitlep = styled.p`
  font-size: 16px;
  color: #666;
  margin-bottom: 10px;
  padding: 0 80px;
  @media (max-width: 1280px) {
    margin-bottom: 5px;
    padding: 0 50px;
    margin-bottom: 5px;

    font-size: 12px;
    padding: 0 20px;
  }
  @media (max-width: 780px) {
    margin-bottom: 5px;
    padding: 0 60px;

    font-size: 12px;
  }
`;

export const BackButtondiv = styled.div`
  display: none;

  @media (max-width: 780px) {
    display: flex;
    position: fixed;
    padding-top: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    justify-content: space-between;
    align-items: center;
    // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-height: 110px; /* Set the minimum height here */
    margin-bottom: 30px;
  }

  @media (max-width: 650px) {
    padding: 12px 100px; /* Adjust padding to control spacing around the buttons */
    font-size: 20px;
  }
  @media (max-width: 650px) {
    font-size: 18px;

    padding: 11px 70px; /* Adjust padding to control spacing around the buttons */
  }
  @media (max-width: 480px) {
    font-size: 14px;

    padding: 0px 60px; /* Adjust padding to control spacing around the buttons */
  }
  @media (max-width: 380px) {
    font-size: 12px;

    padding: 5px 50px; /* Adjust padding to control spacing around the buttons */
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  color: #dd2256;
  width: 100%;
  height: 60px;
  border: 1px solid #dd2256;
  border-radius: 20px;
  cursor: pointer;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 100%;
    height: 50px;
    font-size: 14px;
    border-radius: 15px;
  }

  @media (max-width: 480px) {
    width: 140px;
    height: 40px;
    font-size: 14px;
    border-radius: 12px;
  }
`;

export const BackButtonnext = styled.button`
  color: #fff;
  width: 180px;
  height: 70px;
  border: 1px solid #dd2256;
  border-radius: 20px;
  cursor: pointer;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  // padding:20px;
  &:hover {
    background-color: #c51e4a;
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 100%;
    height: 50px;
    font-size: 14px;
    border-radius: 15px;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 50px;
    font-size: 14px;
    border-radius: 12px;
  }
`;

export const StyledInput = styled.input`
  font-family: inter;
  width: 80%;
  margin: 0 auto;
  padding: 1rem 0.5rem;
  margin-bottom: 1.5rem;
  border-radius: 25px;
  border: none;
  background-color: #ffe6eb; // Light pinkish background
  font-size: 1.5rem;
  color: #dd2256;
  outline: none;

  &::placeholder {
    color: #dd2256;
  }

  &:focus {
    border-color: #f857a6;
  }
`;

// Style for the social icon within the button
export const SocialIcon = styled.span`
  margin-right: 10px;
  transition: color 0.3s ease; /* Smooth transition for color change */
`;

// Style for the social icon button
export const SocialIconconnect = styled.button`
  font-family: inter;
  font-size: 16px;
  padding: 10px;
  border-radius: 20px;
  border: none;
  color: #fff;
  background-color: #dd2256;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;

  &:hover {
    background-color: #fff;
    color: #dd2256;
  }
`;
export const SocialButton = styled.button`
  display: flex;
  width: 90%;
  margin-top: 20px;
  margin: 0 auto;
  align-items: center;
  background-color: white;
  color: #dd2256;
  border: 1px solid #dd2256;
  margin-bottom: 15px;
  font-family: inter;
  font-size: 16px;
  padding: 12px;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s ease-in, color 0.3s ease-in;
  &:hover {
    background-color: #dd2256;
    color: white;
  }
  @media (max-width: 1280px) {
    margin-top: 0px;
    font-size: 20px;
    padding: 10px;

    margin-top: 0;
  }
  @media (max-width: 780px) {
    font-size: 20px;
    padding: 10px;
    margin-bottom: 10px;

    margin-top: 0;
  }
`;
export const SocialText = styled.span`
  flex-grow: 1;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;

  @media (max-width: 780px) {
    font-size: 18px;
  }
`;
export const ContentContainer = styled.div`
  padding-bottom: 40px; /* Adjust the padding to ensure space for the fixed button container */
  margin-top: 8rem;
  width: 100%;
  // overflow-y: auto;
  justify-content: center;
  @media (max-width: 780px) {
    margin-top: 2rem;

    padding-bottom: 120px; /* More padding for mobile view */
  }
`;

export const DesktopBackButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  background: #fff;
  align-items: flex-start;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  min-height: 170px;
  margin: 0 auto;
  padding-top: 20px;
  @media (max-width: 780px) {
    display: none;
  }
`;

export const NextButtonContainer = styled.div`
  // flex: 1;
  width: 80%;
  border-radius: 15px;
  margin: 0 auto;
`;

export const Button = styled.button`
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
  font-family: 'Inter', sans-serif;

  color: white;
  border: none;
  border-radius: 15px;
  font-size: 18px;
  cursor: pointer;
  width: 100%;
  padding: 10px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c51e4a;
  }

  @media (max-width: 780px) {
    display: none;
  }
`;
export const BackCancelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%; /* Ensure container takes full width */
  margin-bottom: 25px;
  gap: 15px; /* Add gap between buttons */
`;

export const DesktopBackButton = styled.button`
  background-color: #fff;
  color: #dd2256;
  padding: 15px 35px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #dd2256;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    color: #fff;
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(0); /* Reset lift on click */
  }

  @media (max-width: 1200px) {
    /* Adjust padding and other styles as needed */
  }

  @media (max-width: 945px) {
    padding: 12px 25px;
  }
`;

export const DesktopCancelButton = styled.button`
  background-color: #fff;
  color: #dd2256;
  padding: 15px 35px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #dd2256;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #dd2256;
    color: #fff;
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(0); /* Reset lift on click */
  }

  @media (max-width: 945px) {
    padding: 12px 25px;
  }
`;

export const ToogleToolsWrapper = styled.div<{ backgroundColor?: string }>`
  width: 85%;
  padding-left: 30px;
  margin-top: 10px;
  background-color: ${({ backgroundColor }) => backgroundColor || '#f8f9fa'};
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 auto;

  @media (max-width: 780px) {
    padding: 15px;
    margin-top: 15px;
  }

  @media (max-width: 480px) {
    padding: 10px;
    margin-top: 10px;
  }
`;

export const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const LoaderContainer = styled.div`
  display: inline-block;
  width: 24px;
  height: 24px;
`;

export const Spinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db; /* Change this to your desired color */
  border-radius: 50%;
  width: 100%;
  height: 100%;
  animation: ${spin} 1s linear infinite;
`;
