import { Drawer, FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

export const DrawerBorder = styled(Drawer)`
  max-width: 100%;
  font-family: 'Poppins';

  .rs-drawer-dialog .rs-drawer-content {
    border-radius: 20px 0px 0px 20px;
    padding: 0px 5vw;

    .rs-drawer-header h4 {
      font-size: ${props => props.theme.fonts.title};
      font-weight: bolder;
      color: ${props => props.theme.colors.black};
    }
    .rs-drawer-header button {
      font-size: ${props => props.theme.fonts.bigSubtitle};
      color: ${props => props.theme.colors.grey};
    }
  }
`;

export const DrawerDate = styled.p`
  font-weight: bold;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.subtitle};
  span {
    font-weight: normal;
    color: ${props => props.theme.colors.greyLetter};
  }
`;

export const DrawerTitle = styled(Drawer.Title)`
  min-height: 2em;
`;

export const ReelImage = styled.img`
  width: 100%;
  max-height: 40vh;
  object-fit: cover;
  border-radius: 10px;
`;

export const ImageContainer = styled.div`
  position: relative;
  display: flex;
`;

export const Card = styled(FlexboxGrid)`
  background-color: ${props => props.theme.colors.softGrey};
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;
`;

export const SmallCard = styled(FlexboxGrid.Item)`
  text-align: center;
  > * {
    padding-top: 10px;
  }

  img {
    min-height: 30px;
  }
`;

export const BigTotal = styled.p`
  font-size: ${props => props.theme.fonts.title};
  color: ${props => props.theme.colors.black};
  font-weight: bold;
`;
