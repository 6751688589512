import { Button, ButtonGroup, DatePicker, Loader } from 'rsuite';
import styled from 'styled-components';
import { POSITION } from '../../lib/globalValues';

export const ButtonAdd = styled(Button)`
  width: 100%;
  background-color: ${props => props.theme.colors.red};
  padding: 12px 20px;
  border-radius: 100px;
  color: ${props => props.theme.colors.white};

  span:first-child {
    padding-right: 10px;
    font-weight: bolder;
  }
`;

export const ButtonNotification = styled(Button)`
  background-color: #e74138; //Theme red
  padding: 12px 20;
  color: #ffffff; // Theme white
  margin-top: 15px;
`;

export const ButtonTags = styled(Button)`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  padding: 12px 20px;
  border-radius: 100px;
  margin: 30px 0px 8px;

  &:disabled {
    background-color: #dbdbdb;
    color: #65676b;
  }

  span:first-child {
    padding-right: 10px;
    font-weight: bolder;
  }
`;

const BasicTitle = styled.p`
  color: ${props => props.theme.colors.black};
  font-weight: bolder;
`;

export const TagsDisplayContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  button span {
    color: ${props => props.theme.colors.facebook};
  }

  button:hover span {
    text-decoration: underline;
  }
`;

export const Subtitle = styled(BasicTitle)`
  font-size: ${props => props.theme.fonts.bigSubtitle};
  margin-bottom: 25px;
`;

export const TitleForm = styled(BasicTitle)`
  font-size: ${props => props.theme.fonts.paragraph};
  text-transform: uppercase;
  margin: 25px 0px;
`;

export const TitleButton = styled(Button)`
  margin: 25px 0px;
  padding: 15px;
  background-color: ${props => props.theme.colors.grey};
`;

export const ClickableTitleForm = styled(TitleForm)`
  margin: 0;
  padding: 0;
`;

export const ContainerPreview = styled.div`
  height: 500px;
  width: 500px;
  position: relative;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    max-width: 90vw;
    max-height: 90vw;
  }
`;

export const TopContainer = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
`;

export const DateInputContainer = styled.div`
  display: flex;

  @media only screen and (min-width: 1200px) and (max-width: 1380px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 990px) {
    margin-bottom: 15px;
  }
`;

export const ContainerEdit = styled.div<{
  bgColor: string;
  hasImage: boolean;
}>`
  background-color: ${props =>
    props.hasImage ? props.theme.colors.white : props.bgColor};

  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  figure {
    position: relative;
    ${props => !props.hasImage && 'height: 100%; width: 100%;'}
    margin: 0px
  }
`;

export const DivImg = styled.img<{ sendingImg: boolean }>`
  height: 100%;
  width: 100%;
  object-fit: scale-down;
  max-height: 500px;
  max-width: 500px;

  ${props =>
    props.sendingImg &&
    `
    max-height: 1000px;
    max-width: 1000px;
    height: auto;
    width: auto;
    `}
`;

export const DivPosition = styled.div<{ position: string; align?: string }>`
  position: absolute;
  margin: 50px;
  ${props => props.align && 'width: calc(100% - 100px);'}

  ${props => props.position === POSITION.TOP && 'top: 0px; '}
  ${props => props.position === POSITION.CENTER && 'top: 45%;  '}
  ${props => props.position === POSITION.BOT && 'bottom: 0px; '}
  ${props =>
    props.position === POSITION.CENTER &&
    !props.align &&
    'top: 40%; left: 40%; '}

  

  ${props => props.align === POSITION.LEFT && 'text-align: left;'}
  ${props => props.align === POSITION.CENTER && 'text-align: center;'}
  ${props => props.align === POSITION.RIGHT && 'text-align: right;'}

  overflow: hidden;
`;

export const TextImg = styled.p<{ color?: string }>`
  color: ${props => props.color || props.theme.colors.black};
  font-weight: bold;
`;

export const BigTitleImg = styled(TextImg)`
  font-size: ${props => props.theme.fonts.title};
`;

export const SubtitleImg = styled(TextImg)`
  font-size: ${props => props.theme.fonts.bigSubtitle};
`;

export const DateTimeRounded = styled(DatePicker)`
  border-radius: 50px;
  border: 2px solid ${props => props.theme.colors.greyLetter};
  margin-right: 10px;
  margin-top: 5px;
  width: 200px;
  padding: 4px;

  .rs-picker-toggle {
    background-color: transparent !important;
    border: none !important;

    .rs-picker-toggle-value {
      color: ${props => props.theme.colors.greyLetter};
    }
  }
`;

export const ButtonDashed = styled.button`
  background-color: transparent;
  width: 100%;
  padding: 25px 0px;

  border: 2px dashed ${props => props.theme.colors.grey};

  img {
    transform: scaleX(-1);
  }
  p {
    color: ${props => props.theme.colors.greyLetter};
    font-weight: bold;
  }
`;

export const ButtonGroupCenter = styled(ButtonGroup)`
  left: 50%;
  transform: translateX(-50%);
`;

export const DivForm = styled.div`
  width: max-content;
  max-width: 100%;
  margin: 20px auto;

  div > div {
    margin: 5px 0px;
  }
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;

  .smiley-icon {
    position: absolute;
    bottom: 13px;
    right: 10px;
    z-index: 10;
    cursor: pointer;
  }

  .EmojiPickerReact {
    position: absolute !important;
    transform: translate(0px, 22px);
    right: 0;
    z-index: 9;
  }
`;

export const SimleIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: none;
  resize: none;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.subtitle};
  padding: 5px 10px;
  font-family: Helvetica;
  position: relative;
  border-radius: 8px;
`;

export const CounterTextarea = styled.div`
  float: right;
`;

export const ImgTools = styled.img`
  cursor: pointer;
`;

export const LoaderCenter = styled(Loader)`
  padding: 5px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50px;
  min-height: 25px;
`;

export const ParagraphAdvert = styled.p`
  margin-top: 7px;
`;

export const PreviewBackground = styled.div`
  border-radius: 8px;
  background-color: #1c1c1c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 19px 0px;
  gap: 10px;
  height: 560px;
`;

export const SimplePreviewBackground = styled.div`
  border-radius: 8px;
  background-color: #dbdbdb;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 19px 0px;
  gap: 10px;
  /* border-radius: 12px; */
  height: 560px;

  span:nth-child(1) {
    color: #65676b;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.2rem;
  }
  span:nth-child(2) {
    color: #65676b;
    font-weight: 400;
    line-height: 1.1765;
    font-size: 1.0625rem;
    text-align: center;
    align-items: center;
  }
  @media (max-width: 768px) {
    padding: 15px; /* Adjust padding for smaller screens */
  }
`;

export const PreviewInner = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  width: 290px;
  border: 1px solid rgb(255, 255, 255);
  /* height: 520px; */
  overflow: hidden;
`;

export const VideoContainer = styled.div`
  display: flex;
  min-width: 290px;
  height: 486px;
  overflow: hidden;

  /* &::after {
    content: '';
    display: block;
    padding-top: 56.25%;
  } */

  video {
    width: 100%;
    object-fit: contain;
    width: 100%;
    /* height: 400px; */
  }
`;

export const DisplayVideoContainer = styled('div')`
  display: grid;
  flex: 1;
  place-items: center;
  margin-top: 90px;
`;

export const TimeLineWrapper = styled('div')`
  display: flex;
`;

export const PlayPauseButton = styled('button')`
  cursor: pointer;
  font-size: 1em;
  margin: 0;
  border: none;
  padding: 0;
  height: 50px;
  width: 50px;
  background: #222;
  margin-right: 3px;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TimeLineMain = styled('div')<{ outerWidth: number }>`
  width: ${props => props.outerWidth}px;
  height: 50px;
  background: #222;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
`;

export const InnerWrapper = styled('div')`
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
`;

export const TiktokToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px 0px;
  max-width: 400px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .rs-btn-toggle-checked {
    background-color: ${props => props.theme.colors.red};
  }
`;

export const Label = styled.span`
  font-size: 1em;
  color: #000;
  font-weight: 800;
`;

export const TikTokOptionsContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  border: 1px solid #e3e3e3;
  padding: 10px;
  background-color: #fff;
  transition: height 0.4s ease-in-out, transform 0.4s ease-in-out;
  transform: ${({ isOpen }) =>
    isOpen ? 'translateY(0)' : 'translateY(-10px)'};
  height: ${({ isOpen }) => (isOpen ? '100%' : '75px')};
  margin-top: 30px;
  overflow: hidden;
  border-radius: 8px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 15px;

  .title {
    color: #000;
    font-size: 1em;
    text-transform: uppercase;
    font-weight: bolder;
  }
`;

export const Arrow = styled.div<{ isOpen: boolean }>`
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: ${({ isOpen }) => (isOpen ? 'none' : '6px solid #000')};
  border-bottom: ${({ isOpen }) => (isOpen ? '6px solid #000' : 'none')};
  margin-right: 8px;
  transition: transform 0.3s ease-in-out;
  transform: ${({ isOpen }) => (isOpen ? 'rotate(178deg)' : 'rotate(-90deg)')};
`;

export const TikTokIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const TikTokOptionsContent = styled.div`
  margin-top: 10px;
  opacity: ${({ isOpen }: { isOpen: boolean }) => (isOpen ? '1' : '0')};
  transition: opacity 0.4s ease-in-out;
  pointer-events: ${({ isOpen }: { isOpen: boolean }) =>
    isOpen ? 'auto' : 'none'};
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 25px;

  .rs-picker-toggle-wrapper {
    padding-right: 21px;
    padding-left: 27px;
    width: initial !important;
    height: 100%;
  }

  .rs-picker-menu.rs-picker-select-menu {
    display: none;
  }

  .rs-picker-menu {
    display: none;
  }
`;

export const LabelWrapper = styled.label`
  display: block;
  margin-bottom: 10px;
`;

export const SelectButton = styled.select`
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
`;

export const DropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border-right: 1px solid #999;
  width: 61%;
`;

export const DropDownTitle = styled.div`
  display: flex;
  justify-content: center;
  gap: 5px;
  padding-left: 10px;

  span:nth-child(1) {
    font-size: 1em;
    font-weight: 900;
    color: #000;
  }

  span:nth-child(2) {
    color: red;
    font-size: 1em;
    font-weight: 900;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-top: 10px; */
  width: 100%;
`;

export const CheckboxType = styled.input`
  margin-right: 5px;
`;
