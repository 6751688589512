import { useQuery } from 'react-query';
import { getMediaSizes } from '../services/mediaFiles';

const useMediaSizes = (companyId: number | undefined) => {
  return useQuery('MEDIA_SIZES', () => getMediaSizes(companyId), {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
    enabled: !!companyId,
  });
};

export default useMediaSizes;
