/* eslint-disable @typescript-eslint/no-explicit-any */
import { FlexboxGrid } from 'rsuite';
import ImageIcon from '../../../assets/icons/image.svg';
import VideoIcon from '../../../assets/icons/video.svg';
import { formatBytes } from '../../../lib/format';
import {
  BytesSize,
  ImgBackground,
  ImgBackgroundContainer,
} from './sizeCard.styles';

interface Props {
  sizeName: string;
  size: any;
}

const SizeCard = ({ sizeName, size }: Props) => {
  const typeFile = sizeName === 'sizeImages';

  return (
    <FlexboxGrid align="middle">
      <FlexboxGrid.Item colspan={7}>
        <ImgBackgroundContainer>
          <ImgBackground
            src={typeFile ? ImageIcon : VideoIcon}
            alt={sizeName}
          />
        </ImgBackgroundContainer>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={10}>
        <p>{typeFile ? 'Images' : 'Videos'}</p>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={7}>
        <BytesSize>{formatBytes(size)}</BytesSize>
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export default SizeCard;
