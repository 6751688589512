/* eslint-disable no-console */

import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

export const fileUpload = async ({
  selectedFile,
  type = 'image',
  duration,
  trim,
  video_width,
  video_height,
  socialNetworks = [],
}: {
  selectedFile: File;
  type?: string;
  duration?: number;
  trim?: number[];
  video_width?: number;
  video_height?: number;
  socialNetworks?: string[];
}) => {
  const formData = new FormData();
  formData.append('file', selectedFile);
  if (trim) {
    formData.append('trim', JSON.stringify(trim));
  }
  if (duration) {
    formData.append('duration', JSON.stringify(duration));
  }
  if (video_width && video_height) {
    formData.append('videoWidth', JSON.stringify(video_width));
    formData.append('videoHeight', JSON.stringify(video_height));
  }
  formData.append('functionality', type);
  formData.append('network', socialNetworks.join(','));

  try {
    const response = await axios.post('/media', formData);
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'The image could not be uploaded',
    });
    return error;
  }
};
