import { useState, useEffect } from 'react';

interface WindowDimensions {
  width: number | undefined;
  height: number | undefined;
}

export function useWindowDimensions(): WindowDimensions {
  // Function to calculate height with a 20% reduction
  const calculateHeight = (height: number) => height * 0.9;

  const [dimensions, setDimensions] = useState<WindowDimensions>({
    width: window.innerWidth,
    // Apply the height adjustment directly during the initial state setup
    height: calculateHeight(window.innerHeight),
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: calculateHeight(window.innerHeight),
      });
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty dependency array ensures this effect runs only once

  return dimensions;
}
