import { Button, Modal } from 'rsuite';
import {
  ModalPosition,
  ModalSubtitle,
  ModalTitle,
} from '../modalTools/ModalTools.styles';

interface Props {
  show: boolean;
  description?: string;
  title?: string;
  onClose: () => void;
}

const IdleModal = ({ show, title, description, onClose }: Props) => {
  return (
    <ModalPosition show={show} onHide={onClose}>
      <Modal.Header>
        <ModalTitle>{title || 'Inactivity Warning'}</ModalTitle>
        <ModalSubtitle>
          {description ||
            'You have been inactive for 15 minutes and you will be automatically logged out after 60 seconds unless you click on Go back button'}
        </ModalSubtitle>
      </Modal.Header>
      <Modal.Body />
      <Modal.Footer>
        <Button onClick={onClose} appearance="subtle">
          Go back
        </Button>
      </Modal.Footer>
    </ModalPosition>
  );
};

export default IdleModal;
