import { Formik } from 'formik';
import { useMemo, useState } from 'react';
import { InputGroup } from 'rsuite';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import axios from '../../lib/axios';
import { notification, notificationError } from '../../lib/notification';
import {
  // StepHeaderContainer,
  // CreateAccountTitle,
  // StepIndicator,
  // StepCircle,
  BackButton,
  BackButtonnext,
  // MobileContainer,
  // MobileStepHeaderContainer,
  // MobileImageContainer,
  // MobileContentContainer,
  ContentContainer,
  BackButtondiv,
  DesktopBackButtonContainer,
  NextButtonContainer,
  BackCancelContainer,
  DesktopBackButton,
  Button,
  // LinkForm,
  StyledInput,
  // FlexboxGrid,
  Title,
  Titlembile,
  Subtitlemobile,
  // ErrorMessage,
  Container,
  StyledErrorMessage
} from './DinabiteFOrget.style';
import {
  MobileImageContent,
  LeftPane,
  RightPane,
  ImageContainer,
  StyledImage,
  DinabiteText,
  Subtitle,
} from '../DinabiteSignupPanel/globaldinabite.style';
import signupImage from '../../assets/images/forgetpassword.png';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Not valid email').required('Email is required'),
});

const DinabiteForgetPass = () => {
  const [isLoading, setIsLoading] = useState(false); // Loading state
  const initialValues = useMemo(() => ({
    email: '',
  }), []);

  const history = useHistory();

  const handleCancel = () => {
    history.push('/login'); // Redirect to the login page
  };

  const recoverPass = async (values: { email: string }) => {
    setIsLoading(true); // Start loading

    try {
      const { email } = values;
      await axios.post('/auth/reset-password', { email });
      notification({
        type: 'success',
        title: 'Success',
        description: 'Check your email for instructions',
      });
    } catch (err) {
      notificationError({
        title: 'Error',
        description: err?.response?.data?.message || 'Could not reset password',
      });
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  // const title = 'Reset Your Password';

  return (
    <>
      <Container>
        <LeftPane>
          {/* <StepHeaderContainer></StepHeaderContainer> */}
          <Titlembile>Forget password</Titlembile>
          <MobileImageContent src={signupImage} alt="Step 1 Image" />
          <Formik
            initialValues={initialValues}
            validationSchema={loginSchema}
            onSubmit={recoverPass}
            validateOnMount
          >
            {({ touched, errors, handleChange, handleBlur, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ContentContainer>
                  <Title>Forgot password</Title>
                  <Subtitle>
                    Hey there! Please reset password below!
                  </Subtitle>
                  <Subtitlemobile>
                  Hey there! Please reset password below!
                  </Subtitlemobile>

                  <InputGroup inside style={{ marginBottom: 30 }}>
                    <StyledInput
                      type="email"
                      name="email"
                      placeholder="Your email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isError={!!(touched.email && errors.email)}
                    />
                  </InputGroup>

                  {/* {touched.email && errors.email && (
                    <ErrorMessage>{errors.email}</ErrorMessage>
                  )} */}
                <StyledErrorMessage>
                  {touched.email && errors.email && errors.email}
                </StyledErrorMessage>

                  <DesktopBackButtonContainer>
                    <NextButtonContainer>
                      <Button type="submit" disabled={isLoading}>
                      {isLoading ? 'Loading...' : 'Reset Password'}
                    </Button>
                    </NextButtonContainer>
                    <BackCancelContainer>
                      <DesktopBackButton onClick={handleCancel}>
                        Back To Login
                      </DesktopBackButton>
                    </BackCancelContainer>
                  </DesktopBackButtonContainer>

                  <BackButtondiv>
                    <BackButton onClick={handleCancel}>Back</BackButton>
                    <BackButtonnext type="submit" disabled={isLoading}>
                      {isLoading ? 'Loading...' : 'Reset Password'}
                    </BackButtonnext>
                  </BackButtondiv>
                </ContentContainer>
              </form>
            )}
          </Formik>
        </LeftPane>

        <RightPane>
          <ImageContainer>
            <StyledImage src={signupImage} alt="Illustration" />
            <DinabiteText>
              Dinabite.ai is your personal assistant for growing your online presence with powerful analytics and easy post scheduling. Ready to rock?
            </DinabiteText>
          </ImageContainer>
        </RightPane>
      </Container>
    </>
  );
};

export default DinabiteForgetPass;
