/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import FormSocialSignUp from '../../components/forms/formSignUp/FormSocialSignUp';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';

const SignUp = ({ pageCount, setPageCount }: any) => {
  const [page, setPage] = useState(0);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (page === 1) {
      setPageCount(1);
    }
  }, [page]);

  useEffect(() => {
    if (pageCount === 0) {
      setPage(0);
    }
  }, [pageCount]);

  return (
    <>
      <FormSocialSignUp page={page} setPage={setPage} width={width} />
    </>
  );
};

export default SignUp;
