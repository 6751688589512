import { Modal } from 'rsuite';
import styled from 'styled-components';

export const ModalTitle = styled.p`
  font-weight: bolder;
  font-size: ${props => props.theme.fonts.title};
`;

export const ModalSubtitle = styled.p`
  font-size: ${props => props.theme.fonts.subtitle};
`;

export const ModalPosition = styled(Modal)`
  @media ${props => props.theme.breakpoints.xs} {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
`;
