/* eslint-disable @typescript-eslint/no-unused-vars */

import { ReactNode } from 'react';
import { Notification } from 'rsuite';
import styled from 'styled-components';
import styles2 from './NotificationStyles.module.css'; // Adjust path if needed

interface NotificationProps {
  type: 'info' | 'success' | 'warning' | 'error';
  title: string;
  description: string;
  duration?: number;
}

interface NotificationWithNodesProps {
  type: 'info' | 'success' | 'warning' | 'error';
  title: string;
  description: ReactNode;
  duration?: number;
}

const CustomNotification = styled.div`
  .rs-notification-title .rs-icon {
    font-size: 16px !important;
    width: 24px;
    line-height: 1;
    margin-right: 10px;
  }

  .rs-notification-close {
    font-size: 5px !important; /* Ensure the close icon is also styled */
  }

  .rs-notification-title {
    font-size: 4px !important; /* Adjust the title font size */
  }
`;
export const notification = ({
  type,
  title,
  description,
  duration = 6000,
}: NotificationProps) =>
  Notification[type]({
    title,
    description,
    duration,
    style: {
      fontSize: '12px',
      maxWidth: '250px', // Adjust the maxWidth as needed
    },
    className: CustomNotification, // Apply the styled component
  });

export const notificationWithNodes = ({
  type,
  title,
  description,
  duration = 10000,
}: NotificationWithNodesProps) =>
  Notification[type]({
    title,
    description,
    duration,
    style: {
      fontSize: '12px',
      maxWidth: '250px', // Adjust the maxWidth as needed
    },
    className: CustomNotification, // Apply the styled component
  });

export const notificationError = ({
  title,
  description,
  duration = 5000 /*  When set to 0, it is not automatically turned close. */,
}: {
  title: string;
  description: string;
  duration?: number;
}) =>
  Notification.error({
    title,
    description,
    duration,
    style: {
      fontSize: '12px',
      maxWidth: '250px', // Adjust the maxWidth as needed
    },
    className: CustomNotification, // Apply the styled component
  });
