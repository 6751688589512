import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import logoUrl from '../../assets/images/new-dinabite-logo.png';
import { sendverifyingEmail } from '../../services/sendverifyingemail';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  @media(max-width:780px){
    padding: 5px 35px;
  }
`;

const ModalContent = styled.div`
  background-color: #fef7f7;
  padding: 40px 30px;
  border-radius: 15px;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 30px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
  padding: 0;
  transition: color 0.3s;

  &:hover {
    color: #c41c4b;
  }
`;

const ModalLogo = styled.img`
  width: 100px;
  margin-bottom: 25px;
`;

const ModalMessage = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  line-height: 1.5;
  color: #333;
  margin-bottom: 30px;
`;

const ModalSubMessage = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #999;
  margin-bottom: 20px;
`;

const ModalButton = styled.button`
  padding: 12px 30px;
  background-color: #dd2256;
  color: white;
  border: none;
  border-radius: 25px;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 15px;

  &:hover {
    background-color: #c41c4b;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const ModalFooter = styled.div`
  margin-top: 20px;
  color: #333;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
`;

interface ModalProps {
  message: string;
  onClose: () => void;
  email: string;
  showModal: boolean;
  isLoginPage: boolean; // Accept the login page flag
}

const Modal = ({ message, onClose, email, showModal, isLoginPage }: ModalProps) => {
  const [countdown, setCountdown] = useState(isLoginPage ? 0 : 30);
  const [isResendAllowed, setIsResendAllowed] = useState(isLoginPage);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (showModal) {
      if (isLoginPage) {
        setCountdown(0);
        setIsResendAllowed(true);
      } else {
        setCountdown(30);
        setIsResendAllowed(false);
      }

      timer = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(timer!);
            setIsResendAllowed(true);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [showModal, isLoginPage]);

  const onResend = async () => {
    if (isResendAllowed) {
      setIsLoading(true);
      try {
        await sendverifyingEmail(email);

        // Reset countdown to 60 seconds
        setCountdown(60);
        setIsResendAllowed(false);
        
        // Start countdown
        const timer = setInterval(() => {
          setCountdown(prev => {
            if (prev <= 1) {
              clearInterval(timer);
              setIsResendAllowed(true);
              return 0;
            }
            return prev - 1;
          });
        }, 1000);
        
      } catch (error) {
        // 
      } finally {
        setIsLoading(false);
      }
    }
  };

  const getButtonText = () => {
    if (isLoading) {
      return 'Resending Email...';
    }
    
    if (isResendAllowed) {
      return 'Resend Email';
    }
    
    return `Resend Email in ${countdown}s`;
  };

  return (
    <ModalContainer>
      <ModalContent>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <ModalLogo src={logoUrl} alt="Dinabite Logo" />
        <ModalMessage>
          {message} <strong>{email}</strong>. <br />
          Please follow the link in the email to continue.
        </ModalMessage>
        <ModalSubMessage>Not received the email?</ModalSubMessage>
        <ModalButton onClick={onResend} disabled={!isResendAllowed || isLoading}>
          {getButtonText()}
        </ModalButton>
        <ModalFooter>
          {/* Additional footer content can go here */}
        </ModalFooter>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
