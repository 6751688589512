import { FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

export const SpaceBetween = styled(FlexboxGrid)`
  margin-bottom: 10px;
  button {
    margin: 0px 10px;
    background-color: transparent;
  }
`;
