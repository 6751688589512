import { InputPicker } from 'rsuite';
import styled from 'styled-components';

/* tslint:disable-next-line */
/* @ts-ignore */
export const Input = styled(InputPicker)`
color: ${props => props.theme.colors.black};
font-size: 1em;
margin-bottom: 10px;
`;

export const LoadingContainer = styled.p`
  padding: 5px;
  color: ${props => props.theme.colors.darkGrey};
  text-align: center;
`;
