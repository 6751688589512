/* eslint-disable no-param-reassign */

import { useState } from 'react';
import { Col, Divider, FlexboxGrid, Modal, Rate, Row } from 'rsuite';
import GoogleIcon from '../../../assets/icons/google.svg';
import { formatDate } from '../../../lib/format';
import { getStarRating } from '../../../lib/initGoogle';
import { replyReview } from '../../../services/googleBusinessTools';
import {
  ButtonGrey,
  ButtonRed,
  ImgSizes,
  ModalPosition,
  RedSubtitle,
  RowSpace,
  TextArea,
  TitleSection,
} from './ModalReview.styles';

interface Props {
  toogleShowModalReview: () => void;
  showModalReview: boolean;
  review: Review;
  companyId: number;
  setDataReviews: React.Dispatch<React.SetStateAction<Reviews | null>>;
  dataReviews: Reviews | null;
}

const ModalReview = ({
  toogleShowModalReview,
  showModalReview,
  review,
  companyId,
  dataReviews,
  setDataReviews,
}: Props) => {
  const [comment, setComment] = useState<string>(
    review?.reviewReply?.comment || '',
  );
  const sendReply = async (reviewId: string) => {
    const { data } = await replyReview({ reviewId, comment, companyId });

    if (data) {
      const updateReviews = dataReviews?.reviews.map((elReview: Review) => {
        if (elReview.reviewId === review.reviewId) {
          elReview.reviewReply = {
            comment: data.comment,
            updateTime: data.updateTime,
          };
        }
        return elReview;
      });
      if (updateReviews && dataReviews?.reviews) {
        setDataReviews({
          ...dataReviews,
          reviews: updateReviews,
        });
      }

      toogleShowModalReview();
    }
  };
  return (
    <ModalPosition
      size="lg"
      show={showModalReview}
      onHide={toogleShowModalReview}>
      <Modal.Body>
        <Row style={{ maxWidth: '100%' }}>
          <Col xs={24} md={6}>
            <RowSpace>
              {review.reviewer && (
                <Col xs={12} sm={24}>
                  <img src={GoogleIcon} alt="google" />
                  <ImgSizes
                    src={review.reviewer.profilePhotoUrl}
                    alt="userProfile"
                    height="50px"
                    width="50px"
                  />
                </Col>
              )}
              <Col xs={12} md={18}>
                {review.reviewer && (
                  <RedSubtitle> {review.reviewer.displayName} </RedSubtitle>
                )}

                <Rate
                  defaultValue={getStarRating(review.starRating)}
                  allowHalf
                  readOnly
                  size="xs"
                />
              </Col>
            </RowSpace>
          </Col>
          <Col xs={24} md={18}>
            <FlexboxGrid align="middle" justify="space-between">
              <TitleSection>REVIEW:</TitleSection>
              <Col xsHidden>
                <b>{formatDate(review.createTime)}</b>
              </Col>
            </FlexboxGrid>
            <p>{review.comment}</p>
            <Divider />
            <TitleSection>REPLY:</TitleSection>
            <TextArea
              value={comment}
              onChange={e => setComment(e.target.value)}
              rows={8}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row gutter={5}>
          <Col md={12} />
          <Col xs={12} md={6}>
            <ButtonGrey
              onClick={toogleShowModalReview}
              appearance="subtle"
              size="lg">
              Cancel
            </ButtonGrey>
          </Col>

          <Col xs={12} md={6}>
            <ButtonRed
              onClick={() => sendReply(review.reviewId)}
              appearance="subtle"
              size="lg">
              {review?.reviewReply?.comment ? 'Edit' : 'Send'}
            </ButtonRed>
          </Col>
        </Row>
      </Modal.Footer>
    </ModalPosition>
  );
};

export default ModalReview;
