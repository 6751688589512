import styled from 'styled-components';

export const Container = styled.div`
  /* padding: 0 4rem 0 3rem; */
  height:100%;      
  // overflow:auto;
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    padding: 0rem 1rem;
  }
`;

export const TitleContent = styled.p`
  font-size: ${props => props.theme.fonts.subtitle};
  font-weight: bolder;
  color: ${props => props.theme.colors.black};
  margin: 20px 0;
`;
