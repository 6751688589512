import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

export const getPostCommentsById = async (postId: string | undefined) => {
  try {
    const comments = axios.get(`/posts/fb-comments/${postId}`);
    return comments;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const sendCommentInPostById = async (
  postId: string | undefined,
  message: string | undefined,
) => {
  try {
    const sendComment = axios.post(`/posts/fb-comment/${postId}`, { message });
    return sendComment;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const getLikesByPostId = async (postId: string | undefined) => {
  try {
    const likes = axios.get(`/posts/fb-likes/${postId}`);
    return likes;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the fb-likes',
    });

    return err;
  }
};

export const checkPostLikedByMe = async (postId: string | undefined) => {
  try {
    const {data} = await axios.get(`/posts/fb-likedbyme/${postId}`);
    return data;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the fb-likes',
    });

    return err;
  }
};

export const likeCommentBycommentId = async (commentId: string | undefined) => {
  try {
    const like = axios.post(`/posts/fb-like/${commentId}`);
    return like;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const unLikeCommentBycommentId = async (
  commentId: string | undefined,
) => {
  try {
    const unLike = axios.delete(`/posts/fb-unLike/${commentId}`);
    return unLike;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const sendCommentInCommentById = async (
  commentId: string | undefined,
  message: string | undefined,
) => {
  try {
    const sendComment = axios.post(`/posts/fb-comments/${commentId}`, {
      message,
    });
    return sendComment;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};
