import { useEffect, useRef, useState } from 'react';
import { Loader } from 'rsuite';
import useReels from '../../hooks/useReels';
import Reel2 from '../../components/reel/Reel2';
import { ContentContainer, SeeMoreButton } from './Dashboard.styles';

const REELS_PER_PAGE = 6;

const Reels = ({ handleLoadMore2, onReelsUpdate, update }: any) => {
  const [dataReelSelected, setDataReelSelected] = useState<Reel | null>(null);

  const {
    isLoading: isLoadingReels,
    isError: isErrorReels,
    isFetching: isFetchingReels,
    data: reels,
    refetch: refetchReels,
  } = useReels();

  const initialReels = useRef(reels);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (reels && reels !== initialReels.current) {
      initialReels.current = reels;
      onReelsUpdate(reels);
    }
  }, [reels, onReelsUpdate]);

  useEffect(() => {
    const interval = setInterval(() => refetchReels(), 600 * 1000);
    return () => clearInterval(interval);
  }, [refetchReels]);

  useEffect(() => {
    if (update) {
      refetchReels();
    }
  }, [update]);

  const startIndex = (currentPage - 1) * REELS_PER_PAGE;
  const endIndex = startIndex + REELS_PER_PAGE;
  const currentReels = reels ? reels.slice(startIndex, endIndex) : [];

  if (!initialReels.current && (isLoadingReels || isFetchingReels || update)) {
    return <Loader content="Loading reels..." />;
  }

  return (
    <>
      {!isErrorReels &&
        currentReels.map(reel => (
          <Reel2
            key={reel.id}
            reel={reel}
            setDataReelSelected={setDataReelSelected}
          />
        ))}
      {reels && reels.length >= 4 && (
        <ContentContainer>
          <SeeMoreButton type="button" onClick={handleLoadMore2}>
            See More Reels
          </SeeMoreButton>
        </ContentContainer>
      )}
    </>
  );
};

export default Reels;
