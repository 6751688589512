import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from 'styled-components';
import * as dotenv from 'dotenv';
import routes from './config/routes';
import GlobalStyle from './styles/globalStyles';
import theme from './styles/theme';
import AppRoute from './components/AppRoute';
import { AuthProvider } from './context/AuthContext';

dotenv.config()

const App = () => {
  const queryClient = new QueryClient();
  return (
    <>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
        <GoogleOAuthProvider clientId={process?.env?.REACT_APP_API_GOOGLE || ''}>
          <AuthProvider>
            <Router>
              <Switch>
                {routes.map(route => (
                  <AppRoute
                    key={route.path}
                    path={route.path}
                    component={route.component}
                    isPrivate={route.isPrivate}
                    exact={route.exact}
                  />
                ))}
              </Switch>
            </Router>
            <ReactQueryDevtools initialIsOpen={false} />
          </AuthProvider>
          </GoogleOAuthProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
