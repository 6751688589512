import { Col, FlexboxGrid, Rate, Row } from 'rsuite';
import FacebookIcon from '../../../assets/icons/facebook.svg';
import GoogleIcon from '../../../assets/icons/google.svg';
import useToggle from '../../../hooks/useToggle';
import { formatDate } from '../../../lib/format';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import { getStarRating } from '../../../lib/initGoogle';
import ModalReview from '../../modals/modalReview/ModalReview';
import {
  AlignCol,
  BigDivider,
  Card,
  ColLine,
  ColTopLine,
  ImgSizes,
  RateText,
  Rating,
  RedSubtitle,
  ReplyButton,
  SocialLogo,
  TitleComment,
  TitleGreen,
} from './ReviewCard.styles';

interface Props {
  review: Review;
  companyId: number;
  setDataReviews: React.Dispatch<React.SetStateAction<Reviews | null>>;
  dataReviews: Reviews | null;
  pageId?: string;
}

const ReviewCard = ({
  review,
  companyId,
  dataReviews,
  pageId,
  setDataReviews,
}: Props) => {
  const { isOpen: showModalReview, toggleChange: toogleShowModalReview } =
    useToggle();
  const hasreply = !!review?.reviewReply?.comment;

  const rate = getStarRating(review.starRating);
  return (
    <Card>
      <ModalReview
        toogleShowModalReview={toogleShowModalReview}
        showModalReview={showModalReview}
        review={review}
        companyId={companyId}
        dataReviews={dataReviews}
        setDataReviews={setDataReviews}
      />
      <Row>
        <Col xs={24} md={10}>
          <FlexboxGrid align="middle">
            <AlignCol xs={4} sm={4}>
              <SocialLogo
                src={
                  review?.type === TYPES_OF_SOCIAL_NETWORKS.FACEBOOK
                    ? FacebookIcon
                    : GoogleIcon
                }
                alt="google"
              />
            </AlignCol>
            <AlignCol xs={8} sm={8}>
              {review.reviewer ? (
                <>
                  <ImgSizes
                    src={review.reviewer.profilePhotoUrl}
                    alt="userProfile"
                  />
                  <RedSubtitle> {review.reviewer.displayName} </RedSubtitle>
                </>
              ) : (
                <span>N/A</span>
              )}
            </AlignCol>
            <Rating xs={12} smPush={0} sm={12}>
              {review?.recommendationType ? (
                <RateText type={review.recommendationType}>
                  {review.recommendationType === 'positive'
                    ? 'Recommended'
                    : 'Not Recommended'}
                </RateText>
              ) : (
                <Rate defaultValue={rate} allowHalf readOnly size="xs" />
              )}

              <p>{formatDate(review.createTime)}</p>
            </Rating>
          </FlexboxGrid>
        </Col>

        <ColLine xs={24} md={10} hasreply={hasreply ? 1 : 0}>
          <TitleComment>REVIEW:</TitleComment>
          <span> {review.comment}</span>
          {hasreply && (
            <>
              <BigDivider />
              <TitleGreen>REPLY:</TitleGreen>
              <span> {review.reviewReply.comment}</span>
            </>
          )}
        </ColLine>
        {review.type === 'facebook' ? (
          <ColTopLine xs={24} md={4} hasreply={1}>
            <ReplyButton
              type="button"
              onClick={() =>
                window.open(
                  `https://www.facebook.com/${pageId}/reviews`,
                  '_blank',
                )
              }>
              Reply
            </ReplyButton>
          </ColTopLine>
        ) : (
          <ColTopLine xs={24} md={4} hasreply={!review.type ? 1 : 0}>
            <ReplyButton type="button" onClick={toogleShowModalReview}>
              {review?.reviewReply ? 'Edit reply' : 'Reply'}
            </ReplyButton>
          </ColTopLine>
        )}
      </Row>
    </Card>
  );
};

export default ReviewCard;
