import { useQuery } from 'react-query';
import { fetchReels } from '../services/reels';

// const useReels = () => {
//   return useQuery('REELS', fetchReels, {
//     staleTime: 5000,
//     notifyOnChangePropsExclusions: ['isStale'],
//     refetchOnWindowFocus: false,
//   });
// };
const useReels = () => {
    return useQuery('REELS', fetchReels, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });
  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    'REELS',
    fetchReels,
    {
      staleTime: 5000,
      notifyOnChangePropsExclusions: ['isStale'],
      refetchOnWindowFocus: false,
    }
  );

  return { data, isLoading, isError, isFetching, refetch }; // Include refetch
};
export default useReels;
