import styled from 'styled-components';

export const ArrowButton = styled.button<{ disabled: boolean }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  right: 1%;
  opacity: ${props => (props.disabled ? 0 : 1)};
  user-select: none;
  background-color: transparent;

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    display: none;
  }
`;
