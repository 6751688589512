/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */


import { Divider, Drawer } from 'rsuite';

import {
  DrawerBorder,
  ImageContainer,
  PostImage,
  Card,
  DrawerTitle,
  SmallCard,
  BigTotal,
  DrawerDate,
} from './GooglePreview.styled';

import { formatDate } from '../../../lib/format';
import {
  MEDIA_TYPE,
  TYPES_OF_SOCIAL_NETWORKS,
} from '../../../lib/globalValues';
import HeartIcon from '../../../assets/icons/heart.svg';
import DialogueIcon from '../../../assets/icons/dialogue.svg';
import SharesIcon from '../../../assets/icons/retweet.svg';
import DefaultImage from '../../../assets/images/default.jpg';

const GooglePreview = ({ dataPost, show, size, showDrawer, onHide }: any) => {
  return (
    <DrawerBorder show={show} size={size} onHide={onHide}>
      <DrawerBorder.Header>
        <DrawerTitle>{showDrawer?.text || ''}</DrawerTitle>
        <DrawerDate>
          Date:{' '}
          <span>
            {showDrawer?.publishedAt && formatDate(showDrawer.publishedAt)}
          </span>
        </DrawerDate>
      </DrawerBorder.Header>
      <Divider />
      <Drawer.Body>
        <ImageContainer>
          {showDrawer?.media.type === MEDIA_TYPE.IMAGE && (
            <PostImage src={showDrawer.media.image} alt="Post" />
          )}
          {showDrawer?.media.type === MEDIA_TYPE.VIDEO && (
            <video controls autoPlay style={{ width: '100%' }}>
              <source src={showDrawer.media.video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
          {showDrawer?.media.type === MEDIA_TYPE.TEXT && (
            <PostImage src={DefaultImage} alt="Post" />
          )}
        </ImageContainer>
        {showDrawer?.network !== TYPES_OF_SOCIAL_NETWORKS.GOOGLE && (
          <Card align="middle" justify="space-around">
            <SmallCard>
              <h5>Likes</h5>
              <img src={HeartIcon} alt="likes" />
              <BigTotal>
                {showDrawer?.totalLikes || dataPost?.totalLikes || 0}
              </BigTotal>
            </SmallCard>
            {showDrawer?.network !== TYPES_OF_SOCIAL_NETWORKS.TWITTER && (
              <SmallCard>
                <h5>Comments</h5>
                <img src={DialogueIcon} alt="comments" />
                <BigTotal>
                  {showDrawer?.totalComments || dataPost?.totalComments || 0}
                </BigTotal>
              </SmallCard>
            )}

            {showDrawer?.network !== TYPES_OF_SOCIAL_NETWORKS.INSTAGRAM && (
              <SmallCard>
                <h5>Shares</h5>
                <img src={SharesIcon} alt="shares" />
                <BigTotal>
                  {showDrawer?.totalShares || dataPost?.totalShares || 0}
                </BigTotal>
              </SmallCard>
            )}
          </Card>
        )}
      </Drawer.Body>
    </DrawerBorder>
  );
};

export default GooglePreview;
