import styled from 'styled-components';

export const ImgBackgroundContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${props => props.theme.colors.red};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImgBackground = styled.img`
  padding: 12px;
`;

export const BytesSize = styled.p`
  font-weight: bold;
  font-size: ${props => props.theme.fonts.subtitle};
  color: ${props => props.theme.colors.black};
`;
