import { Link } from 'react-router-dom';
import { Button } from 'rsuite';
import styled from 'styled-components';

export const ForgetFormContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 10px;
  background: #ffffff;
  border-radius: 20px;
  width: 100%;
  height: auto;
  gap: 25px;

  /* @media (max-width: 900px) {
    max-width: 390px;
  } */

  .form-title {
    color: #db1d5a;
    font-weight: 700;
    font-size: 20px;
    line-height: 22.3px;
    bottom: 0;

    @media ${props => props.theme.breakpoints.mm} {
      text-align: center;
    }

    @media ${props => props.theme.breakpoints.xs} {
      font-size: 16px;
    }
  }

  @media ${props => props.theme.breakpoints.mm} {
    margin: 0 auto;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;

  @media ${props => props.theme.breakpoints.mm} {
    justify-content: space-between;
  }

  @media ${props => props.theme.breakpoints.xs} {
    flex-wrap: wrap;
  }
`;

export const ButtonSign = styled(Button)`
  display: flex;
  justify-content: space-around;
  align-items: center;

  background: linear-gradient(104.42deg, #db1d5a 23.85%, #ea4435 87.27%);
  border: 1px solid #ea4435;
  /* background-color: ${props => props.theme.colors.red}; */
  color: ${props => props.theme.colors.white};
  /* font-weight: bolder; */
  border-radius: 10px;
  padding: 14px 0px;
  /* font-size: ${props => props.theme.fonts.subtitle}; */
  max-width: 207px;

  /* margin: 65px auto; */

  span {
    transform: translate(0px, 1px);
    color: #fff;
    font-size: 16px;
  }
  img {
    transform: translate(7px, 0px);
  }
  :hover {
    color: ${props => props.theme.colors.white};
  }

  @media ${props => props.theme.breakpoints.mm} {
    max-width: none;
  }
`;

export const ForgetParagraph = styled(Link)`
  display: flex;
  gap: 5px;
  font-size: ${props => props.theme.fonts.paragraph};
  text-decoration: none !important;

  span:nth-child(1) {
    color: ${props => props.theme.colors.darkGrey};
  }
  span:nth-child(2) {
    color: ${props => props.theme.colors.red};
  }
`;
