/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { useEffect, useState } from 'react';
import countries from '../../../../assets/data/countries.json';
import { ErrorMessage } from '../../Forms.styles';
import { InputContainer } from '../fieldInput/FieldInput.styles';
// import { HiddenGroup } from '../FormSignUp.styles';
import { CountrySelect, Placeholder } from './selectCountry.styles';
import {
  getCountryAndPhoneCode,
  getCurrentPosition,
} from '../../../../services/getUserCountry';

interface Props {
  menuPlacement: boolean;
  name: string;
  page: boolean;
  touched: any;
  errors: any;
  selectedCountry?: string | null;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => void;
  onCountryChange?: (country: string, code: string) => void | null; // Make it optional
}
interface CountryOption {
  value: string;
  label: string;
  phoneCode: string; // Add the phoneCode property here
}

const SelectCountry = ({
  menuPlacement = true,
  name,
  page = true,
  touched,
  errors,
  selectedCountry,
  setFieldValue,
  setFieldTouched,
  onCountryChange, // New prop
}: Props) => {
  const [defaultCountry, setDefaultCountry] =
    useState<string | null | undefined>(null);
  const [defaultPhoneCode, setDefaultPhoneCode] = useState('');

  useEffect(() => {
    if (selectedCountry && defaultCountry !== selectedCountry) {
      const country = countries.find(c => c.value === selectedCountry);
      if (country) {
        setDefaultCountry(selectedCountry);
        setDefaultPhoneCode(country.phoneCode);
        setFieldValue(name, country.value, true);
        if (onCountryChange) {
          onCountryChange(country.value, country.phoneCode); // Call only if it exists
        }
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    const fetchCountryInfo = async () => {
      try {
        const position = await getCurrentPosition();

        const details = await getCountryAndPhoneCode(
          position.coords.latitude,
          position.coords.longitude,
        );
        setDefaultCountry(details.countryName);
        setFieldValue(name, details.countryName, true);
        setDefaultPhoneCode(details.phoneCode);
        // onCountryChange(details.countryName);
        // onCountryChange(details.countryName); // Notify parent component
        // onCountryChange(details.countryName, details.phoneCode); // Pass country and phone code
        if (onCountryChange) {
          onCountryChange(details.countryName, details.phoneCode); 
          // console.log("helooooooooo")
          setFieldValue('country', details.countryName, true); 
          setFieldValue('phonecode', details.phoneCode, true); 

        }

      } catch (error) {
        setDefaultCountry('United Kingdom');
        setDefaultPhoneCode('+44'); 
        setFieldValue(name, 'United Kingdom', true);
        if (onCountryChange) {
          // console.log("error")
          onCountryChange('United Kingdom', '+44'); // Call only if it exists
        }
        // onCountryChange(details.countryName);
      }
    };

    fetchCountryInfo();
  }, [name, setFieldValue, onCountryChange]);

  return (
    <InputContainer>
      <CountrySelect
        value={countries.find(option => option.value === defaultCountry)}
        name={name}
        options={countries}
        onChange={(option: CountryOption) => {
          // Use the CountryOption type here
          setDefaultCountry(option.value);
          setFieldValue(name, option.value, true);
          setDefaultPhoneCode(option.phoneCode);

          if (onCountryChange) {
            onCountryChange(option.value, option.phoneCode); // Pass country and phone code
            setFieldValue('country', option.value, true); 
            setFieldValue('phonecode', option.phoneCode, true); 
          }
        }}
        menuPlacement={menuPlacement ? 'top' : 'bottom'}
        onBlur={() => setFieldTouched(name)}
        error={touched[name] && errors[name]}
        placeholder={<Placeholder>Country*</Placeholder>}
      />
      {touched[name] && errors[name] && (
        <ErrorMessage signUp>{errors[name]}</ErrorMessage>
      )}
    </InputContainer>
  );
};

export default SelectCountry;
