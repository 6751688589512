import PlusIcon from '../../assets/icons/plus.svg';
import { Container } from './RoundedButton.styles';

const RoundedButton = () => {
  return (
    <Container>
      <img src={PlusIcon} alt="Plus" />
    </Container>
  );
};

export default RoundedButton;
