import { useQuery } from 'react-query';
import { fetchScheduledReels } from '../services/reels';

export const KEY_SCHEDULED_REELS = 'SCHEDULED_REELS';
const useScheduledReels = () => {
  return useQuery(KEY_SCHEDULED_REELS, fetchScheduledReels, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });
};

export default useScheduledReels;
