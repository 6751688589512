import { Divider, FlexboxGrid, IconButton } from 'rsuite';
import styled from 'styled-components';

export const StyledFlexboxGrid = styled(FlexboxGrid)`
  height: calc(100vh - 89px);
  // min-height:40rem;
  @media (min-width: 991px) {
    min-height: 40rem;
  }
`;
export const HeaderStyled = styled(FlexboxGrid)`
  /* padding: 2em 0em; */
  height: 89px;
  overflow: none;
  width: 100%;
  position: sticky; /* Keep the tools-wrapper sticky */
  top: 0; /* Stick to the top */
  z-index: 9;
  /* Above other content */
  /* @media ${props => props.theme.breakpoints.sm} {
    padding: 0 10% 1em;
    width: 100%;
    position: fixed;
    // z-index: 1;
    background-color: #fdfdfd !important;
    > div {
      padding-bottom: 0em;
    }
  } */

  /* @media (max-width: 991px) {
    display: none;
  } */

  .tools-wrapper {
    @media (max-width: 991px) {
      overflow: none;

      width: 100%;
      // margin: 15px 0px;
      margin-bottom: 2px;
      position: sticky; /* Keep the tools-wrapper sticky */
      top: 0; /* Stick to the top */
      z-index: 10000; /* Above other content */
    }
  }
`;
export const MainWrpper = styled.div`
  @media (max-width: 991px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const TitleWrpper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 10px 10px 0px;
  margin: 10px 0px 10px 38px;
`;

export const Title = styled.p`
  padding: 10px;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.paragraph};
  font-weight: 550;
  /* text-transform: capitalize; */
`;

export const DisplayUserNameLetter = styled.span<{ hide?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dd2256;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  padding: 5px;
  color: ${props => props.theme.colors.white};
  font-size: ${props => props.theme.fonts.subtitle};
  font-weight: 550;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;

  @media (max-width: 1480px) {
    width: 52px;
    height: 52px;
  }

  @media (max-width: 590px) {
    width: 40px;
    height: 40px;
    margin: 5px 0px;
  }

  ${({ hide }) =>
    hide
      ? `
        @media (max-width: 991px) {
          display: flex;
        }
        @media (min-width: 992px) {
          display: none;
        }
      `
      : `
        @media (max-width: 991px) {
          display: none;
        }
        @media (min-width: 992px) {
          display: flex;
        }
      `}
`;

export const DropDownMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  background: #fff;
  width: 200px;
  height: 210px;
  border-radius: 10px;
  padding: 15px;
  top: 56px;
  right: 0;
  z-index: 999;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  gap: 25px;

  a {
    display: flex;
    align-items: center;
    font-size: 16px;
    text-decoration: none;
    gap: 10px;
    color: #575757;

    .rs-icon {
      font-size: 20px;
      color: #fff;
    }
  }

  button {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #fff;
    background: ${props => props.theme.colors.red};
    margin: 0 auto;
  }
`;

export const DividerSpace = styled(Divider)`
  margin: 0px 10% 20px;
  @media (max-width: 1169px) {
    margin: 80px 0rem 20px;
    // padding-left: 20px;
  }
`;

export const FullDivider = styled.div`
  height: 1px;
  margin-bottom: 20px;
  background-color: ${props => props.theme.colors.grey};
`;

export const MenuButton = styled(IconButton)`
  background: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
`;

export const SpaceMenu = styled(FlexboxGrid.Item)`
  padding: 0px;
  height: 100%;
  background: #fff;
`;

export const CenterFlex = styled(FlexboxGrid.Item)`
  text-align: center;
  position: relative;
  background-color: #fff;
  padding: 20px;

  @media (max-width: 991px) {
    padding: 7px;
    background-color: transparent;
    width: max-content;
  }
`;

export const HamburgerLogo = styled.img`
  position: absolute;
  right: -12px;
  z-index: 9;
  cursor: pointer;
  width: 25px;

  @media (max-width: 991px) {
    display: none;
  }
`;

export const OptionsHeader = styled(FlexboxGrid.Item)`
  /* background: ${props => props.theme.colors.pinkRed}; */
  /* border-radius: 0px; */
  /* border-left: 2px solid ${props => props.theme.colors.white}; */

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    background-color: transparent;
    border-left: none;
  }
`;

export const Wrapper = styled(FlexboxGrid.Item)`
  /* background: ${props => props.theme.colors.pinkRed}; */
  /* backdrop-filter: blur(13px); */
  /* border-left: 2px solid ${props => props.theme.colors.white}; */
  border-radius: 0px;
  margin: 0px;
  height: 100%;
  padding-left: 55px;
  /* padding-left: 59px; */
  overflow-y: auto;

  @media (max-width: 991px) {
    padding-left: 0px;
    min-height: 0vh;
  }
`;

export const OptionsMobile = styled(FlexboxGrid.Item)<{ display: boolean }>`
  width: 100%;
  display: ${props => (props.display ? 'block' : 'none')};
  overflow: hidden; /* Prevents overflow from child elements */
  position: fixed; /* Fix the position to overlay the content */
  top: 6rem; /* Align to the top */
  left: 0; /* Align to the left */
  height: 100vh; /* Set height to full viewport height */
  padding: 0; /* Optional: Adjust padding as needed */
  z-index: 9999; /* Ensure it is above other content */
  /* Optional styling for background and other elements */
  @media (min-width: 992px) {
    /* lg breakpoint (for large screens) */
    display: none; /* Hide on larger screens */
  }
`;

export const ImgLogo = styled.img<{ isMenuClose?: boolean }>`
  max-width: ${props => (props.isMenuClose ? '53%' : '77%')};
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    width: 100%;
  }

  @media (max-width: 991px) {
    display: none;
  }
`;
