import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../lib/globalValues';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const queryString = require('query-string');

const UberEats = () => {
  const getToken = async () => {
    const { code } = queryString.parse(params.search);
    localStorage.setItem(
      TYPES_OF_SOCIAL_NETWORKS.UBER_EATS,
      JSON.stringify(code),
    );

    window.close();
  };

  useEffect(() => {
    getToken();
  }, []);
  const params = useLocation();

  return (
    <div>
      <h1>Loading...</h1>
    </div>
  );
};

export default UberEats;
