import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import axios from '../../lib/axios';
import logo from '../../assets/images/logo.svg';
import { ButtonPage } from '../../components/forms/formSignUp/FormSignUp.styles';
import { Container, Logo, Text } from './signUpSuccess.styles';

interface IState {
  email?: string;
}

const SignUpSuccess = () => {
  const { state } = useLocation<IState>();
  const [resent, setResent] = useState(false);

  return (
    <Container>
      <Logo src={logo} alt="dinabite logo" />
      <Text>We have sent confirmation email to {state?.email}.</Text>
      <Text>Please follow the link in the email to continue.</Text>

      {!resent && (
        <div style={{ marginTop: 50 }}>
          <Text style={{ marginBottom: 20}}>Not received the email?</Text>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonPage
              next
              onClick={async () => {
                const response = await axios.post('/users/resend-email', {
                  email: state?.email || 'jmanzano@soamee.com',
                });
                setResent(true);
                return response;
              }}>
              Resend
            </ButtonPage>
          </div>
        </div>
      )}
    </Container>
  );
};

export default SignUpSuccess;
