import axios from '../lib/axios';
import { notification, notificationError } from '../lib/notification';

export const disableTool = async (toolId: number | undefined) => {
  try {
    if (!toolId) {
      throw new Error('Could not be disabled.');
    }
    const response = await axios.put(`/tools/disabled/${toolId}`);
    notification({
      type: 'success',
      title: 'Success',
      description: 'This social network was disabled',
    });
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Error',
    });
    return error;
  }
};
