import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ReactGA from 'react-ga';
import 'rsuite/dist/styles/rsuite-default.css';
import App from './App';
import { initFacebookSdk } from './lib/initFacebookSdk';

const ErrorFallback = ({ error }: FallbackProps) => {
  return (
    <div>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
    </div>
  );
};

if (process?.env?.REACT_APP_GOOGLE_ANALYTICS) {
  ReactGA.initialize(process?.env?.REACT_APP_GOOGLE_ANALYTICS, {
    debug: process?.env?.NODE_ENV === 'development',
  });
}

const startApp = () => {
  ReactDOM.render(
    <StrictMode>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <App />
      </ErrorBoundary>
    </StrictMode>,
    document.getElementById('root'),
  );
};

initFacebookSdk().then(startApp);
