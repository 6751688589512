import { useEffect } from 'react';
import ReactDOM from 'react-dom';
// import axios from '../../lib/axios';

interface TwitterWindowProps {
  auth_url?: string;
}

// @ts-ignore // eslint-disable-next-line
const TwitterWindow: React.FC<TwitterWindowProps> = ({
  // @ts-ignore
  children,
  auth_url,
}) => {
  let externalWindow: Window | null = null;
  const containerEl: HTMLDivElement = document.createElement('div');

  const openPopupWindow = () => {
    const width = 500;
    const height = 500;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    externalWindow = window.open(
      '',
      'tiktokWindow',
      `width=${width},height=${height},left=${left},top=${top},resizable,scrollbars=yes,status=1`,
    );

    if (!externalWindow) {
      alert('Pop-up blocked! Please allow pop-ups for this website.');
    }
  };

  const twitterLogin = async () => {
    openPopupWindow();

    if (externalWindow) {
      externalWindow?.document.body.appendChild(containerEl);
      // Set the location of the external window to the authentication URL
      externalWindow.location.href = auth_url || '';
    }
  };

  useEffect(() => {
    twitterLogin();
  }, []);

  return ReactDOM.createPortal(children, containerEl);
};

export default TwitterWindow;
