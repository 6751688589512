/* eslint-disable no-restricted-properties */
/* eslint-disable prefer-template */
import { format } from 'date-fns';

export function formatTimeDifference(createdTime: string): string {
  const now: Date = new Date();
  const createdDate: Date = new Date(createdTime);

  const diffMs: number = now.getTime() - createdDate.getTime();
  const diffDays: number = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  const diffHours: number = Math.floor(
    (diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const diffMinutes: number = Math.floor(
    (diffMs % (1000 * 60 * 60)) / (1000 * 60),
  );

  if (diffDays > 0) {
    return `${diffDays}d`;
  }
  if (diffHours > 0) {
    return `${diffHours}h`;
  }
  return `${diffMinutes}m`;
}

export const formatDate = (date: Date) => format(date, 'DD MMMM YYYY - HH:mm');

export const instaFormatDate = (date: Date) => format(date, 'MMMM DD');

export const formatMessageDate = (date: Date) => format(date, 'DD MMM YY');

export const formatMessageDateTime = (date: Date) =>
  format(date, 'DD MMM YY - HH:mm');

export const formatPromotionDate = (date: Date) =>
  format(date, 'DD/MM/YYYY - HH:mm');

export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const substractDateInMs = (dateRange: string) => {
  const date = new Date();
  switch (dateRange) {
    case 'yesterday':
      return date.setDate(date.getDate() - 1);
    case 'last7Days':
      return date.setDate(date.getDate() - 7);
    default:
      return date.setDate(date.getDate() - 30);
  }
};
