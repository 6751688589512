import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';

import HashtagImage from '../../assets/icons/dinno.png';
import {
  Container,
  ContentWrapper,
  TextContainer,
  Welcome,
  Description,
  Button,
  ImageContainer,
  ButtonWrapper,
} from './noconnection.style';

const Noconnection = ({ payment }: any) => {
  const history = useHistory();
  const { user } = useAuth();
  const [blockUser, setBlockUser] = useState(false);

  useEffect(() => {
    if (
      payment?.subscriptionStatus === 'trial_expired' &&
      !payment?.hasAccess
    ) {
      console.log('yes now ');
      setBlockUser(true);
    } else {
      setBlockUser(false);
    }
  }, [payment]);

  const displayFullName = user?.firstName ? `${user.firstName}` : 'User';
  const handleSendToolPage = () => {
    history.push(`/user-panel/tools`);
  };

  const handleSendAccountPage = () => {
    history.push(`/user-panel/account`);
  };

  return (
    <Container>
      <ContentWrapper>
        {blockUser ? (
          <TextContainer>
            <Welcome>Your free Trial expired</Welcome>
            <Description>
              Manage all your marketing from one-easy to-use app with AI support
              and collaboration capabilities.
            </Description>
            <ul>
              <li>Manage social posts and reels and cross post</li>
              <li>View and respond to messages and reviews</li>
              <li>AI content generation</li>
              <li>Launch and manage paid ads</li>
              <li>Advanced analytics</li>
              <li>Multi user and multiple accounts management</li>
            </ul>
            <ButtonWrapper>
              <Button onClick={handleSendAccountPage}>View Plans</Button>
            </ButtonWrapper>
          </TextContainer>
        ) : (
          <TextContainer>
            <Welcome>{`Hey, ${displayFullName}`}</Welcome>
            <Description>Welcome to Dinabite</Description>
            <Description>
              Streamline your social media in one place, share across
              <br /> platforms with ease, and let AI handle the heavy lifting.
            </Description>
            <Description>
              Connect now to discover a simpler way to manage your <br />
              online presence.
            </Description>
            <ButtonWrapper>
              <Button onClick={handleSendToolPage}>Connect your account</Button>
            </ButtonWrapper>
          </TextContainer>
        )}
        <ImageContainer>
          <img src={HashtagImage} alt="Social Media Hashtag" />
        </ImageContainer>
      </ContentWrapper>
    </Container>
  );
};

export default Noconnection;
