import axios from 'axios';
import axiosClient from '../lib/axios';

export const fetchPosts = async (): Promise<Post[]> => {
  try {
    const { data } = await axiosClient.get('/posts');
    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // Handle the error response from axios
      throw new Error(` ${err.response.data.message || err.message}`);
    } else if (err instanceof Error) {
      // Handle other types of errors
      throw new Error(` ${err.message}`);
    } else {
      throw new Error(`Failed to fetch the posts.`);
    }
  }
};

export const fetchScheduledPosts = async (): Promise<ScheduledPosts[]> => {
  try {
    const { data } = await axiosClient.get('/posts/scheduled');
    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // Handle the error response from axios
      throw new Error(` ${err.response.data.message || err.message}`);
    } else if (err instanceof Error) {
      // Handle other types of errors
      throw new Error(`${err.message}`);
    } else {
      throw new Error(`Failed to fetch the scheduled posts.`);
    }
  }
};

export const removePost = async (postId: number): Promise<ScheduledPosts> => {
  try {
    const { data } = await axiosClient.delete(`/posts/${postId}`);
    return data;
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // Handle the error response from axios
      throw new Error(`${err.response.data.message || err.message}`);
    } else if (err instanceof Error) {
      // Handle other types of errors
      throw new Error(` ${err.message}`);
    } else {
      throw new Error(`Failed to remove the post.`);
    }
  }
};

export const removeSocialPost = async (
  postId: string,
  socialNetwork: string,
): Promise<{status: boolean, postId: string, socialNetwork: string}> => {
  try {
    // await new Promise(resolve => setTimeout(resolve, 3000));
    // const data = {[socialNetwork]: true};

    const { data } = await axiosClient.delete(
      `/posts/socialPost/${postId}/${socialNetwork}`,
    );

    if (socialNetwork in data) {
      if (data[socialNetwork] === true) {
        return { status: true, "postId": postId, "socialNetwork": socialNetwork };
      }
      return { status: false, "postId": postId, "socialNetwork": socialNetwork };
    }
    return { status: false, "postId": postId, "socialNetwork": socialNetwork };
  } catch (err) {
    if (axios.isAxiosError(err) && err.response) {
      // Handle the error response from axios
      throw new Error(
        `Failed to delete the post: ${
          err.response.data.message || err.message
        }`,
      );
    } else if (err instanceof Error) {
      // Handle other types of errors
      throw new Error(`Failed to delete the post: ${err.message}`);
    } else {
      throw new Error(`Failed to delete the post.`);
    }
  }
};
