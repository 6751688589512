import { ChangeEvent, useState } from 'react';
import { InputContainer, EyeImage, DivBottomBorder } from './IconInput.styles';
import EyeIcon from '../../../assets/icons/eye.svg';

interface Props {
  icon?: string;
  iconAlt?: string;
  value: string | number;
  placeholder: string;
  error: string | undefined;
  inputType: 'text' | 'password' | 'number' | 'email';
  disabled?: boolean;
  readOnly?: boolean; // Add readOnly prop here
  onChange: (e: string | ChangeEvent) => void;
}

const IconInput = ({
  error,
  icon,
  iconAlt,
  inputType,
  onChange,
  placeholder,
  value,
  disabled = false,
  readOnly,
}: Props) => {
  const [showPassword, setShowPassword] = useState(false);

  if (inputType === 'password') {
    return (
      <InputContainer>
        <div>
          <img src={icon} alt={iconAlt} />
          <input
            type={showPassword ? 'text' : inputType}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
          />
          <EyeImage
            onClick={() => setShowPassword(!showPassword)}
            src={EyeIcon}
            alt="Eye Icon"
          />
        </div>
        <p>{error}</p>
      </InputContainer>
    );
  }

  if (icon && iconAlt) {
    return (
      <InputContainer>
        <div>
          <img src={icon} alt={iconAlt} />
          <input
            type={inputType}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            readOnly={readOnly} // Use readOnly prop here

          />
        </div>
        <p>{error}</p>
      </InputContainer>
    );
  }

  return (
    <DivBottomBorder>
      <input
        type={inputType}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
      />
      <p>{error}</p>
    </DivBottomBorder>
  );
};

export default IconInput;
