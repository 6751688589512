import { useHistory } from 'react-router-dom';
import { FlexboxGrid } from 'rsuite';
import { useAuth } from '../../context/AuthContext';
import { ButtonAdd, Card, ParentContainer } from './PaymentSuccess.styles';

const PaymentSuccess = () => {
  const { companySelected } = useAuth();
  const history = useHistory();
  return (
    <ParentContainer>
      <Card>
        <h2>Your payment has been successful, {companySelected?.name}</h2>
        <FlexboxGrid>
          <ButtonAdd
            type="button"
            onClick={() => history.push('/user-panel/account')}
          >
            <span>Go to account</span>
          </ButtonAdd>
        </FlexboxGrid>
      </Card>
    </ParentContainer>
  );
};

export default PaymentSuccess;
