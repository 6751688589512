/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */

import { useEffect, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { Input, LoadingContainer } from './AddressAutocomplete.styles';
import { getUserCoords } from '../../services/getUserCoords';
import { getAddress } from '../../services/getUserCountry';

interface Props {
  onPlaceSelected: (place: string) => void;
  setCoords: any;
}

interface MenuItem {
  label: string;
  value: string;
}

const AddressAutocomplete = ({ onPlaceSelected, setCoords }: Props) => {
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: process?.env?.REACT_APP_GOOGLE_API_KEY,
    });

  const [items, setItems] = useState<MenuItem[]>([]);
  const [selectedPlace, setSelectedPlace] = useState<string>('');
  const [isUserSearching, setIsUserSearching] = useState<boolean>(false);

  const fetchCurrentLocation = async () => {
    try {
      const geolocationResponse = await getUserCoords();

      if (geolocationResponse && geolocationResponse.location) {
        const { lat, lng } = geolocationResponse.location;
        setCoords({
          latitude: lat,
          longitude: lng,
        });
        const data = await getAddress(lat, lng);

        if (data?.results?.length) {
          const address = data.results[0].formatted_address;
          const locationItem = { label: address, value: address };
          setSelectedPlace(address);
          setItems([locationItem, ...items]);
          onPlaceSelected(address);
        }
      }
    } catch (error) {
      console.error('Error getting current location:', error);
    }
  };

  useEffect(() => {
    if (!isUserSearching) {
      fetchCurrentLocation();
    }
    if (isUserSearching) {
      setCoords(null);
    }
  }, [isUserSearching]);

  useEffect(() => {
    if (placePredictions.length) {
      const suggestions = placePredictions.map(item => ({
        label: item.description,
        value: item.description,
      }));

      setItems(prevItems => [...prevItems, ...suggestions]);
    }
  }, [placePredictions]);

  return (
    <Input
      placeholder="Address"
      block
      data={items}
      labelKey="label"
      valueKey="value"
      value={selectedPlace}
      onSearch={(word: string) => {
        setIsUserSearching(true);
        getPlacePredictions({ input: word, types: ['geocode'] });
      }}
      renderMenu={(menu: MenuItem[]) => {
        if (isPlacePredictionsLoading) {
          return <LoadingContainer>Loading...</LoadingContainer>;
        }
        return menu;
      }}
      onSelect={(value: string) => {
        setSelectedPlace(value);
        onPlaceSelected(value);
      }}
      onChange={(value: string) => {
        if (value === '' || value === null) {
          setSelectedPlace('');
          onPlaceSelected('');
        }
      }}
    />
  );
};

export default AddressAutocomplete;
