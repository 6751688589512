// @ts-nocheck 
import { Col, FlexboxGrid, Row } from 'rsuite';
import { formatNumber, percentageText } from '../../../lib/helpers';
import { BaseCard, DifferenceText, SmallTitle } from './CardRating.styles';

interface Props {
  title: string;
  stats: {
    countReviews: SocialNetworkStatistic;
    countRating: SocialNetworkStatistic;
  };
  noRating?: boolean;
  image: string;
}

const CardRating = ({ title, image, stats, noRating = false }: Props) => {
  const { countReviews, countRating } = stats;
  return (
    <BaseCard>
      <FlexboxGrid align="middle">
        <Col xs={5}>
          <img src={image} alt="ranking google" />
        </Col>
        <Col xs={19}>
          <Row>
            <SmallTitle>{title}</SmallTitle>
          </Row>
          <Row>
            <Col xs={18} md={15}>
              <p>
                <b>{countReviews.currentNumber}</b> Total reviews
              </p>
            </Col>

            <Col xs={6} md={9}>
              <DifferenceText type={countReviews.percentageType}>
                {countReviews.oldNumber === 0
                  ? 'N/A'
                  : percentageText(
                      countReviews.oldNumber,
                      countReviews.percentageDifference,
                      countReviews.percentageType,
                    )}
              </DifferenceText>
            </Col>
          </Row>
          <Row>
            <Col xs={18} md={15}>
              {noRating ? (
                <p>
                  <b>{countRating.currentNumber}</b> Recommended
                </p>
              ) : (
                <>
                  {countRating.currentNumber === 0 ? (
                    <b>N/A</b>
                  ) : (
                    <>
                      <b>
                        {formatNumber(
                          Math.round(countRating.currentNumber * 100) / 100,
                          1,
                        )}
                        /5.0
                      </b>
                      {' Average'}
                    </>
                  )}
                </>
              )}
            </Col>
            <Col xs={6} md={9}>
              <DifferenceText type={countRating.percentageType}>
                {countRating.oldNumber === 0
                  ? 'N/A'
                  : percentageText(
                      countReviews.oldNumber,
                      countReviews.percentageDifference,
                      countReviews.percentageType,
                    )}
              </DifferenceText>
            </Col>
          </Row>
        </Col>
      </FlexboxGrid>
    </BaseCard>
  );
};

export default CardRating;
