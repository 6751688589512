/* eslint-disable @typescript-eslint/no-unused-vars */
import { Formik } from 'formik';
import { useMemo } from 'react';
import { InputGroup } from 'rsuite';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import ForwordIcon from '../../../assets/images/arrow_forward.png';
import axios from '../../../lib/axios';
import { notification, notificationError } from '../../../lib/notification';
import { ErrorMessage, FormContainer, InputIcon } from '../Forms.styles';
import {
  ButtonContainer,
  ButtonSign,
  ForgetFormContainer,
  ForgetParagraph,
} from './FormForgetPass.style';
import { useWindowDimensions } from '../../../hooks/useWindowDimensions';

const loginSchema = Yup.object().shape({
  email: Yup.string().email('Not valid email').required('Email is required'),
});

const FormForgetPass = () => {
  const { width } = useWindowDimensions();

  const initialValues = useMemo(
    () => ({
      email: '',
    }),
    [],
  );

  const recoverPass = async (values: { email: string }) => {
    try {
      const { email } = values;
      await axios.post('/auth/reset-password', { email });
      notification({
        type: 'success',
        title: 'Success',
        description: 'Check your email for instructions',
      });
    } catch (err) {
      notificationError({
        title: 'Error',
        description: err?.response?.data?.message || 'Could not reset password',
      });
    }
  };

  return (
    <ForgetFormContainer>
      <span className="form-title">Reset Your Password</span>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={recoverPass}
        validateOnMount>
        {({ touched, errors }) => (
          <FormContainer>
            <InputGroup inside style={{ marginBottom: 30 }}>
              <InputIcon
                placeholder="Your email"
                type="email"
                name="email"
                error={touched.email && errors.email}
              />
            </InputGroup>

            {touched.email && errors.email && (
              <ErrorMessage signUp>{errors.email}</ErrorMessage>
            )}

            <ButtonContainer>
              <ButtonSign block type="submit">
                <span>Reset Password</span>

                {width && width > 768 && (
                  <img src={ForwordIcon} alt="Forword Icon" />
                )}
              </ButtonSign>
              {width && width > 768 && (
                <ForgetParagraph to="/">
                  <span>Don&apos;t have an account yet?</span>
                  <span>SIGN UP</span>
                </ForgetParagraph>
              )}
            </ButtonContainer>
          </FormContainer>
        )}
      </Formik>
    </ForgetFormContainer>
  );
};

export default FormForgetPass;
