import styled from 'styled-components';
import { Card } from '../Cards.styles';

export const SmallTitle = styled.p`
  color: ${props => props.theme.colors.greyLetter};
  font-size: ${props => props.theme.fonts.small};
  font-weight: bold;

  span {
    color: ${props => props.theme.colors.black};
  }
`;

export const Rating = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.subtitle};
  font-weight: bold;
`;

export const BolderParagraph = styled.p`
  font-weight: bolder;
`;

export const BaseCard = styled(Card)`
  border-radius: 10px;
`;

export const DifferenceText = styled.div<{
  type: 'neutral' | 'positive' | 'negative';
}>`
  text-align: center;
  color: ${props => {
    if (props.type === 'positive') return props.theme.colors.softGreen;

    if (props.type === 'negative') return props.theme.colors.red;

    return props.theme.colors.grey;
  }};
  font-size: ${props => props.theme.fonts.paragraph};
`;
