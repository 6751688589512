import { FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

export const Container = styled(FlexboxGrid)`
  flex: 1;
  background-color: white;
  // padding: 20px 15px;
  border-bottom: thin solid ${props => props.theme.colors.grey};
  display: flex;
  align-items: center;
  height:100%;
`;

export const PostImage = styled.img`
  width: 125px;
  max-height: 75px;
  object-fit: cover;

  @media ${props => props.theme.breakpoints.maxMedium} {
    width: 50px;
    max-height: 50px;
  }
`;

export const PostVideo = styled.video`
  width: 125px;
  max-height: 75px;

  @media ${props => props.theme.breakpoints.maxMedium} {
    width: 50px;
    max-height: 50px;
  }
`;

export const ImageContainer = styled(FlexboxGrid)`
  position: relative;
  display: flex;
  width: 125px;
  max-height: 75px;

  @media ${props => props.theme.breakpoints.maxMedium} {
    width: 50px;
    max-height: 50px;
  }
`;

export const NetworkIcon = styled.img`
  width: 25px;
`;

export const TextContainer = styled.div`
  margin: 0 25px;
  display: flex;
  flex-grow: 1;
`;

export const PostDate = styled.span`
  font-size: 12px;
`;

export const PostText = styled.p`
  font-size: 12px;
  overflow: hidden;
  white-space: break-spaces;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
`;

export const ButtonSpaces = styled.button`
  padding: 10px 15px;
  border-radius: 12px;
`;
