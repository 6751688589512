/* eslint-disable no-plusplus */
export function dataURLtoFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',');

  const mime = 'image/jpeg';

  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const splitUrlImg = (url: string) => {
  const name = url.split('-').reverse();
  const type = name[0].split('.').reverse();

  return { name, type };
};

export const srcToFile = async (src: string) => {
  const { name, type } = splitUrlImg(src);
  const response = await fetch(`${src}?x-request=html`);
  const bufferImg = await response.arrayBuffer();
  const file = await new File([bufferImg], name[0], {
    type: `image/${type[0]}`,
  });

  return file;
};
