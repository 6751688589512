/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Col, FlexboxGrid, Loader, Row } from 'rsuite';
import Rectangle from '../../assets/icons/Rectangle.svg';
import Exit from '../../assets/icons/exit.svg';
import Messages2 from '../../components/messages/Messages2';
import Noconnection from '../../components/noconnection/noconnection';
import axios from '../../lib/axios';
import SelectToolsDropdown from '../../components/selectToolsDropdown/SelectToolsDropdown';

import {
  Contentt,
  Header,
  HeaderNumber,
  HeaderText,
  MessegeContainer,
  PostsContainer,
  PostWrapper,
  ReelsContainer,
  Top,
} from './Dashboard.styles';
import Posts from './Post';
import Reels from './Reels';
import { useAuth } from '../../context/AuthContext';

const TABLE_TYPES_POSTS = {
  SCHEDULED: 'SCHEDULED',
  PREVIOUS: 'PREVIOUS',
};
const TABLE_TYPES_REELS = {
  SCHEDULED: 'SCHEDULED',
  PREVIOUS: 'PREVIOUS',
};

const fetchTools = async () => {
  try {
    const { data } = await axios.get<Tool[]>('/tools');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

const Dashboard = () => {
  const history = useHistory();
  const { user } = useAuth();

  // const { isLoading, isError, isFetching, data: posts } = usePosts()
  const [showTable] = useState(TABLE_TYPES_POSTS.PREVIOUS);
  const [messages, setMessages] = useState<TMessage[]>([]); // State to hold messages

  const [upcomingPosts, setUpComingPosts] = useState([]);
  const [upcomingReels, setUpComingReels] = useState([]);

  const { isLoading: isLoadingTools, data: tools } = useQuery(
    `TOOLS`,
    fetchTools,
    {
      staleTime: 5000,
      notifyOnChangePropsExclusions: ['isStale'],
      refetchOnWindowFocus: false,
    },
  );
  if (isLoadingTools) {
    return <Loader content="Loading..." />;
  }

  const handleLoadMore = () => {
    // console.log('goto next page post');
    history.push(`/user-panel/posts`);
  };
  const handleLoadMore2 = () => {
    // console.log('goto next page reels');
    history.push(`/user-panel/reels`);
  };
  // reels  section

  const payment = user?.companies?.[0]?.payment;

  if (
    payment?.subscriptionStatus === 'trial_expired' &&
    !payment?.hasAccess
  ) {
    return <Noconnection payment={payment} />;
  }

  return (
    <Contentt>
      <Row>
        <FlexboxGrid.Item componentClass={Col} xs={24} md={8}>
          <FlexboxGrid justify="space-around">
            <SelectToolsDropdown />
          </FlexboxGrid>
        </FlexboxGrid.Item>
      </Row>
      <FlexboxGrid
        justify="space-between"
        className="dashboard-grid"
        style={{ height: 'calc(100% - 85px)' }}>
        {tools && tools.length === 0 && (
          <>
            <FlexboxGrid.Item
              componentClass={Col}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              style={{ height: '100%' }}>
              <Noconnection payment={payment} />
            </FlexboxGrid.Item>
          </>
        )}
        {tools && tools.length > 0 && (
          <>
            {/* Posts */}
            <FlexboxGrid.Item
              componentClass={Col}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              style={{ height: '100%' }}>
              <PostsContainer>
                {showTable === TABLE_TYPES_POSTS.PREVIOUS && (
                  <>
                    <Header>
                      <img
                        src={Rectangle}
                        alt="Posts"
                        style={{ width: '30px', height: '30px' }}
                      />
                      <HeaderText>Posts</HeaderText>
                      <HeaderNumber>
                        {upcomingPosts ? upcomingPosts.length : 0}
                      </HeaderNumber>
                    </Header>

                    <Top onClick={handleLoadMore}>
                      <img
                        src={Exit}
                        alt="Delete"
                        style={{ width: '30px', height: '30px' }}
                      />
                    </Top>
                    <PostWrapper>
                      <Posts
                        handleLoadMore={handleLoadMore}
                        onPostsUpdate={setUpComingPosts}
                        update={tools}
                      />
                    </PostWrapper>
                  </>
                )}
              </PostsContainer>
            </FlexboxGrid.Item>
            {/* Reels */}
            <FlexboxGrid.Item
              componentClass={Col}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              style={{ height: '100%' }}>
              <ReelsContainer>
                {showTable === TABLE_TYPES_REELS.PREVIOUS && (
                  <>
                    <Header>
                      <img
                        src={Rectangle}
                        alt="Reels"
                        style={{ width: '30px', height: '30px' }}
                      />
                      <HeaderText>Reels</HeaderText>
                      <HeaderNumber>
                        {upcomingReels ? upcomingReels.length : 0}
                      </HeaderNumber>
                    </Header>

                    <Top onClick={handleLoadMore2}>
                      <img
                        src={Exit}
                        alt="Delete"
                        style={{ width: '30px', height: '30px' }}
                      />
                    </Top>
                    <PostWrapper>
                      <Reels
                        handleLoadMore2={handleLoadMore2}
                        onReelsUpdate={setUpComingReels}
                        update={tools}
                      />
                    </PostWrapper>
                  </>
                )}
              </ReelsContainer>
            </FlexboxGrid.Item>

            {/* Messages Section */}
            <FlexboxGrid.Item
              componentClass={Col}
              xs={24}
              sm={24}
              md={8}
              lg={8}
              style={{ height: '100%' }}>
              <MessegeContainer>
                <Header>
                  <img
                    src={Rectangle}
                    alt="Delete"
                    style={{ width: '30px', height: '30px' }}
                  />
                  <HeaderText>Messages</HeaderText>
                  <HeaderNumber>{messages.length}</HeaderNumber>
                </Header>
                <Top>
                  <img
                    src={Exit}
                    alt="Delete"
                    style={{
                      width: '30px',
                      height: '30px',
                      filter: 'brightness(0) invert(1)',
                    }}
                  />
                </Top>
                <PostWrapper>
                  <Messages2 onMessagesUpdate={setMessages} />
                </PostWrapper>
              </MessegeContainer>
            </FlexboxGrid.Item>
          </>
        )}
      </FlexboxGrid>
    </Contentt>
  );
};

export default Dashboard;
