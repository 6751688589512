import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

export const getPostCommentsById = async (postId: string | undefined) => {
  try {
    const comments = axios.get(`/posts/inst-comments/${postId}`);
    return comments;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const sendCommentInPostById = async (
  postId: string | undefined,
  message: string | undefined,
) => {
  try {
    const sendComment = axios.post(`/posts/inst-comment/${postId}`, {
      message,
    });
    return sendComment;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const likeCommentBycommentId = async (commentId: string | undefined) => {
  try {
    const like = axios.post(`/posts/inst-like-comment/${commentId}`);
    return like;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const unLikeCommentBycommentId = async (
  commentId: string | undefined,
) => {
  try {
    const unLike = axios.delete(`/posts/inst-unLike-comment/${commentId}`);
    return unLike;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};

export const sendCommentInCommentById = async (
  commentId: string | undefined,
  message: string | undefined,
) => {
  try {
    const sendComment = axios.post(`/posts/inst-comments/${commentId}`, {
      message,
    });
    return sendComment;
  } catch (err) {
    notificationError({
      title: 'Error',
      description: `${err}` || 'Error in obtaining the data of the publication',
    });

    return err;
  }
};
