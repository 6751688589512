import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { TOKEN } from './globalValues';

const API_URL = process?.env?.REACT_APP_API_URL;
const DINABITE_API_KEY = process?.env?.REACT_APP_DINABITE_API_KEY;

const config: AxiosRequestConfig = {
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json',
    'x-api-key': DINABITE_API_KEY,
  },
};

const client: AxiosInstance = axios.create(config);

client.interceptors.request.use(axiosConfig => {
  const newConfig = { ...axiosConfig };

  const token = localStorage.getItem(TOKEN);

  if (token) {
    newConfig.headers.Authorization = `Bearer ${token}`;
  }

  return newConfig;
});

client.interceptors.response.use(
  response => response,
  error => responseErrorHandler(error),
);

const responseErrorHandler = (error: AxiosError) => {
  const token = localStorage.getItem(TOKEN);
  if (error.response?.status === StatusCodes.UNAUTHORIZED && token) {
    localStorage.removeItem(TOKEN);
    window.location.reload();
  }

  return Promise.reject(error);
};

export default client;
