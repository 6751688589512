import { useHistory, useLocation } from 'react-router-dom';
import { useState } from 'react';
import logoWhite from '../../assets/images/small-logo.png';
import backArrow from '../../assets/images/arrow_back.png';
import ForgetPass from '../forgetPass/ForgetPass';
import ResetPassword from '../resetPassword/ResetPassword';
import SignUp from '../signUp/SignUp';
import {
  BackLogo,
  BgRow,
  FormWapper,
  HeaderWrapper,
  ImgLogo,
  InnerBgRow,
  BackLogoContainer,
  ImageCarouselContainer,
  LoginFormContainer,
  Paragraph,
  OuterLink,
} from './SignPanel.styles';
import { useWindowDimensions } from '../../hooks/useWindowDimensions';
import ImageCarousel from '../../components/carousel/ImageCarousel';
import LoginForm from '../../components/forms/newLoginForm/LoginForm';
import { useAuth } from '../../context/AuthContext';
import { INITIAL_VALUES_PROVIDER } from '../../lib/globalValues';

const SignPanel = () => {
  const { setLoginProvider } = useAuth();
  const { pathname } = useLocation();
  const history = useHistory();
  const { width, height } = useWindowDimensions();
  const [pageCount, setPageCount] = useState(0);

  const handleSetPage = () => {
    if (pathname === '/') {
      if (pageCount === 1) {
        setPageCount(0);
        setLoginProvider(INITIAL_VALUES_PROVIDER)
      }
    } else if (pathname === '/login') {
      history.push('/');
    }
  };

  return (
    <BgRow>
      <InnerBgRow
        hei={height}
        wid={width}
        cusHei={pathname === '/forget-pass' || pathname === '/' ? '757px' : ''}
        cusDir={
          pathname === '/forget-pass' || pathname === '/' ? 'flex-start' : ''
        }>
        <HeaderWrapper>
          <OuterLink href="https://dinabite.ai">
            <ImgLogo src={logoWhite} alt="dinabite logo" />
          </OuterLink>
          {pathname === '/' && pageCount === 1 && width && width > 768 && (
            <BackLogoContainer onClick={handleSetPage}>
              <span>Back</span>
              <BackLogo src={backArrow} alt="arrow" />
            </BackLogoContainer>
          )}
          {pathname === '/login' && width && width > 768 && (
            <BackLogoContainer onClick={handleSetPage}>
              <span>Back</span>
              <BackLogo src={backArrow} alt="arrow" />
            </BackLogoContainer>
          )}
        </HeaderWrapper>
        <FormWapper>
          <ImageCarouselContainer>
            <ImageCarousel />
          </ImageCarouselContainer>
          <LoginFormContainer>
            {pathname === '/login' && <LoginForm width={width} />}
            {(pathname === '/' || pathname === '/unsubscribe') && (
              <SignUp pageCount={pageCount} setPageCount={setPageCount} />
            )}
            {pathname === '/forget-pass' && <ForgetPass />}
            {pathname === '/reset-password' && <ResetPassword />}
          </LoginFormContainer>
        </FormWapper>
      </InnerBgRow>
      {width && width <= 768 && (
        <>
          {pathname === '/' && (
            <Paragraph to="/login">Already have account? SIGNIN</Paragraph>
          )}
          {pathname === '/login' && (
            <Paragraph to="/">
              Don&apos;t have an account yet? SIGN UP
            </Paragraph>
          )}
        </>
      )}
    </BgRow>
  );
};

export default SignPanel;
