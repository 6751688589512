import axios from '../lib/axios';

const API_URL = process?.env?.REACT_APP_API_URL;

const generateTags = async (postText: string) => {
  try {
    if (postText !== '') {
      const response = await axios.post(`${API_URL}/tags/generate`, {
        postText,
      });
      return response.data;
    }
    return null; // or other default value
  } catch (error) {
    if (
      error.response.data.message ===
      "You'll need to upgrade to a premium plan to activate this feature"
    ) {
      throw new Error(error.response.data.message);
    }
    return [];
  }
};

export default {
  generateTags,
};
