import { Button, List, Modal, Row } from 'rsuite';
import styled from 'styled-components';

export const RedSubtitle = styled.p`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fonts.subtitle};
  font-weight: bold;
`;

export const BolderParagraph = styled.p`
  font-weight: bolder;
`;

export const ImgSizes = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
`;

export const TextArea = styled.textarea`
  width: 100%;

  resize: none;
  margin: 8px 2px 0px;
`;

export const ButtonNotification = styled(Button)`
  background-color: #e74138; //Theme red
  padding: 12px 20;
  color: #ffffff; // Theme white
  margin-top: 15px;
`;

export const ButtonRed = styled(Button)`
  background-color: ${props => props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  border-radius: 50px;
  padding: 20px;
  width: 100%;
`;

export const ButtonGrey = styled(ButtonRed)`
  background-color: ${props => props.theme.colors.grey};
  color: ${props => props.theme.colors.white};
`;

export const ModalPosition = styled(Modal)`
  @media ${props => props.theme.breakpoints.xs} {
    position: absolute;
    bottom: 0px;
    width: 100%;
  }
`;

export const RowSpace = styled(Row)`
  margin-bottom: 20px;
`;

export const TitleSection = styled.p`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: ${props => props.theme.fonts.subtitle};
`;

export const DateP = styled.p`
  font-size: ${props => props.theme.fonts.small};
`;

export const Container = styled.div<{ align: string }>`
  margin: ${props => (props.align === 'right' ? '3% 45% 0 0' : '3% 0 0 45%')};
  border-radius: 15px;
  border: 1px solid ${props => props.theme.colors.lightBlack};
  padding: 10px 15px;
  background-color: ${props =>
    props.align === 'right'
      ? 'rgba(209, 211, 212, 0.25)'
      : props.theme.colors.red};
  color: ${props =>
    props.align === 'right'
      ? props.theme.colors.lightBlack
      : props.theme.colors.white};
  word-break: break-word;
`;

export const SuggestionsContainer = styled.div`
  margin: 3%;
  border-radius: 15px;
  border: 1px solid ${props => props.theme.colors.lightBlack};
  padding: 10px 15px;

  word-break: break-word;
`;

export const StyledList = styled(List)`
  cursor: pointer;
  box-shadow: none;
`;

export const MessageTextAreaWrapper = styled.div`
  position: relative;
  width: 100%;

  .EmojiPickerReact {
    position: absolute !important;
    top: -310px;
    right: 10px;
    z-index: 9;
  }
`;

export const MessageIconSmiley = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 13px;
  right: 10px;
  z-index: 10;
  cursor: pointer;
`;

export const ReplySuggestionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media ${props => props.theme.breakpoints.xs} {
    margin-bottom: 15px;
  }
`;

export const ReplySuggestionButton = styled(Button)`
  background-color: ${props =>
    props.bgColor ? props.theme.colors.darkGrey : props.theme.colors.red};
  color: ${props => props.theme.colors.white};
  font-weight: bold;
  border-radius: 50px;
  padding: 15px;
  width: 35%;

  @media ${props => props.theme.breakpoints.xs} {
    width: 65%;
    font-size: 12px;
  }
`;
