import { Col, Divider } from 'rsuite';
import styled from 'styled-components';

export const RedSubtitle = styled.p`
  color: ${props => props.theme.colors.red};
  font-size: ${props => props.theme.fonts.paragraph};
  font-weight: bold;
`;

export const BolderParagraph = styled.p`
  font-weight: bolder;
`;

export const ImgSizes = styled.img`
  height: 50px;
  width: 50px;
  border-radius: 50px;
`;

export const TitleComment = styled.p`
  font-size: ${props => props.theme.fonts.subtitle};
  text-transform: uppercase;
  display: inline-block;
`;

export const TitleGreen = styled(TitleComment)`
  font-weight: bold;
  color: ${props => props.theme.colors.green};
`;

export const BigDivider = styled(Divider)`
  height: 2px;
  background-color: ${props => props.theme.colors.grey};
`;

export const ReplyButton = styled.button`
  background-color: ${props => props.theme.colors.softGrey};
  min-width: 50px;
  width: 80%;
  padding: 10px 5px;
  border-radius: 15px;
`;

export const ColLine = styled(Col)<{ hasreply: boolean }>`
  min-height: 65px;
  margin-bottom: 10px;

  @media ${props => props.theme.breakpoints.md},
    ${props => props.theme.breakpoints.lg} {
    ${props =>
      props.hasreply && `border-right: 2px solid ${props.theme.colors.grey}; `}
  }
`;

export const ColTopLine = styled(Col)<{ hasreply: boolean }>`
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    ${props =>
      props.hasreply &&
      `border-top: 2px solid ${props.theme.colors.grey}; margin-top: 20px; padding: 20px;`}
  }

  text-align: center;

  ${props => !props.hasreply && `display: none;`}
`;

export const Card = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  font-family: 'Poppins';

  @media ${props => props.theme.breakpoints.xs} {
    padding: 35px 20px;
  }
`;

export const AlignCol = styled(Col)`
  text-align: center;
`;

export const Rating = styled(AlignCol)`
  @media screen and (max-width: 768px) {
    margin: 15px 0;
  }
`;

export const SocialLogo = styled.img`
  max-width: 100px;
  width: 100%;
`;

export const RateText = styled.div<{
  type: 'positive' | 'negative';
}>`
  text-align: center;
  color: ${props => {
    if (props.type === 'positive') return props.theme.colors.softGreen;
    return props.theme.colors.red;
  }};
  font-size: ${props => props.theme.fonts.paragraph};
  font-weight: bold;
`;
