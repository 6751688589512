import { Col, FlexboxGrid, Loader } from 'rsuite';
import FacebookIcon from '../../assets/icons/facebook.svg';
import GoogleIcon from '../../assets/icons/google.svg';
import CardRating from '../../components/cards/cardRating/CardRating';
import ReviewCard from '../../components/cards/reviewCard/ReviewCard';
import useReviews from '../../hooks/useReviews';
import { Container, TitleContent } from '../../styles/generalStyles';
import { ColSpace, Section } from './Reviews.styles';

const Reviews = () => {
  const { loading, dataReviews, setDataReviews, companyId } = useReviews();

  if (loading || !companyId) {
    return <Loader center content="loading" />;
  }

  return (
    <Container>
      <Section align="middle">
        <ColSpace xs={24} md={17}>
          <TitleContent>Reviews</TitleContent>
        </ColSpace>
      </Section>

      <FlexboxGrid>
        {dataReviews?.googleStats && (
          <Col xs={24} md={12} lg={10}>
            <CardRating
              title="LAST 7 DAYS REVIEWS"
              stats={dataReviews?.googleStats}
              image={GoogleIcon}
            />
          </Col>
        )}
        {dataReviews?.facebookStats && (
          <Col xs={24} md={12}>
            <CardRating
              title="LAST 7 DAYS REVIEWS"
              stats={dataReviews?.facebookStats}
              image={FacebookIcon}
              noRating
            />
          </Col>
        )}
      </FlexboxGrid>

      <TitleContent>Latest Reviews</TitleContent>

      {dataReviews?.reviews && dataReviews?.reviews.length !== 0
        ? dataReviews.reviews.map(review => (
            <ReviewCard
              pageId={dataReviews.fbPageId}
              key={review.reviewId}
              review={review}
              companyId={companyId}
              setDataReviews={setDataReviews}
              dataReviews={dataReviews}
            />
          ))
        : 'NO DATA'}
    </Container>
  );
};

export default Reviews;
