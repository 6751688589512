import { useFormik } from 'formik';
import { ChangeEvent, useRef, useState } from 'react';
import { Col, FlexboxGrid, Loader } from 'rsuite';
import { useAuth } from '../../context/AuthContext';
import axios from '../../lib/axios';
import { notification, notificationError } from '../../lib/notification';
import { Container } from '../../styles/generalStyles';
import { Title } from '../delivery/delivery.styles';
import { ButtonAdd } from '../posts/Posts.styles';
import { ErrorMessage, Input, InputFile, InputText, Subtitle, TextArea } from './Help.styles';
import { helpSchema } from './validation';

interface HelpForm {
  email: string;
  message: string;
  file: File | null;
}

const Help = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Specify the type

  const helpForm = useFormik<HelpForm>({
    initialValues: {
      email: user?.email || '',
      message: '',
      file: null, // Initialize file to null
    },
    onSubmit: async ({ email, message, file }) => {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('message', message);

        if (file) {
          formData.append('file', file);
        }

        await axios.post('/mail/support', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        // resetting the Input file to "No file chosen"
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }

        helpForm.resetForm();

        notification({
          type: 'success',
          title: 'Success',
          description: 'Thanks for contacting us!',
        });
      } catch (err) {
        notificationError({
          title: 'Error',
          description: err.response?.data?.message || 'Could not sent email',
        });
      } finally {
        setLoading(false);
      }
    },
    validationSchema: helpSchema,
  });

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file: File | null = e.target.files?.[0] || null;
    // helpForm.setFieldValue('file', file);
    if (file) {
      // Define allowed file types (example: images)
      const allowedTypes = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4', 'video/quicktime', 'video/webm'];
      
      // Check if the selected file type is allowed
      if (allowedTypes.includes(file.type)) {
        // Set the file in formik state
        helpForm.setFieldValue('file', file);
      } else {
        // Clear the file input and show an error message
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        notificationError({
          title: 'Error',
          description: 'Only JPEG, PNG,mp4,webm or GIF files are allowed.',
        });
      }
    } else {
      // Clear the file in formik state if no file is selected
      helpForm.setFieldValue('file', null);
    }

  };

  return (
    <Container>
      <FlexboxGrid align="middle" justify="space-between">
        <FlexboxGrid.Item componentClass={Col} xs={24}>
          <Title>Contact us / Send Feedback </Title>
          <Subtitle>
            Send us a message and will get back to you as soon as we can.
          </Subtitle>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <Col>
        <Input
          placeholder="Email address"
          type="email"
          value={helpForm.values.email}
          onChange={e => helpForm.setFieldValue('email', e.target.value)}
        />
        {helpForm.errors.email && (
          <ErrorMessage>{helpForm.errors.email}</ErrorMessage>
        )}

        <TextArea
          rows={12}
          placeholder="Enter your message..."
          value={helpForm.values.message}
          onChange={e => helpForm.setFieldValue('message', e.target.value)}
        />
          <InputText>
            Attach screenshot
          </InputText>
        <InputFile type="file" ref={fileInputRef} onChange={handleFileChange} />
        {helpForm.errors.message && (
          <ErrorMessage>{helpForm.errors.message}</ErrorMessage>
        )}
      </Col>
      <FlexboxGrid.Item
        componentClass={Col}
        xs={24}
        md={4}
        mdPush={20}
        style={{ padding: 0 }}
      >
        <ButtonAdd onClick={() => helpForm.submitForm()} disabled={loading}>
          {loading ? <Loader content="Loading..." /> : <span>Send</span>}
        </ButtonAdd>
      </FlexboxGrid.Item>
    </Container>
  );
};

export default Help;
