import styled from 'styled-components';
import { Button } from 'rsuite';

export const TikTokUploadContainer = styled.div`
  position: relative;
  /* background-color: rgb(248, 248, 248); */
  height: 100%;
  overflow: auto;
  width: 100%;
  min-width: 0px;
`;

export const ContentContainerResponsive = styled.div`
  max-width: 1200px;
  margin: 0px auto;
`;

export const DivWrapper = styled.div``;

export const MainBody = styled.div`
  width: 100%;
`;

export const Layout = styled.div`
  width: 100%;
  /* background: #f8f8f8; */
`;

export const InnerLayout = styled.div`
  width: 100%;
  margin: 0px auto;
  max-width: 1080px;
  padding-top: 16px;
  padding-bottom: 240px;
  /* background-color: #f8f8f8; */
`;

export const InfoPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 10px;
  border-radius: 8px;
  color: #000;
  overflow: hidden;
  position: relative;
`;

export const InfoPanelBody = styled.div`
  background-color: rgb(255, 255, 255);
  padding: 24px;
  border-radius: 8px;
`;

export const InfoPanelReference = styled.div`
  display: inline-block;
`;

export const InfoTitle = styled.div`
  font-weight: 500;
  letter-spacing: 0.24px;
  max-width: 900px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 24px;

  span {
    color: #000;
    font-size: inherit;
    font-weight: 500;
  }
`;

export const InfoDetails = styled.div`
  display: flex;
  margin-top: 8px;

  span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
`;

export const InfoStatusItem = styled.div`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  gap: 6px;
  margin-top: 10px;

  span {
    font-size: inherit;
    font-weight: 700;
    color: #00c39b;
  }
`;

export const InfoDetailsItem = styled.div`
  display: flex;
  gap: 6px;
  margin-right: 16px;
  line-height: 22px;

  span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
`;

export const InfoDetailLabel = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: #000;

  span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
`;

export const InfoDetailValue = styled.div`
  font-size: 15px;
  font-weight: 500;
  color: #000;

  span {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
  }
`;

export const FormPanelContainer = styled.div`
  width: 100%;
  max-width: 1080px;
  border-radius: 8px;
  background-color: #fff;
  padding: 24px;
`;

export const FormPanelV2Container = styled.div`
  display: flex;
  gap: 45px;
`;

export const FormPanelDescription = styled.div`
  flex: 1 1 0%;
  min-width: 0px;

  h5 {
    margin: 15px 0px 10px;
    font-size: 16px;
  }

  p {
    font-weight: bold;
    color: #000;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .rs-picker-default:not(.rs-picker-disabled) .rs-btn:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover,
  .rs-picker-default:not(.rs-picker-disabled) .rs-btn.active,
  .rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle.active {
    border-color: #000;
  }

  .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
  .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
    color: #000;
    font-weight: 700;
  }
`;

export const FormPanelPreview = styled.div`
  flex: 0 0 305px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  border: none;
  resize: none;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.subtitle};
  padding: 10px 15px;
  font-family: Helvetica;
  position: relative;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
`;

export const CoverPictureContainer = styled.div`
  width: 150px;
  height: 200px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.05);
  position: relative;
  overflow: hidden;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const TiktokToolsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px 0px 0px;
  max-width: 400px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;

  .rs-btn-toggle-checked {
    background-color: ${props => props.theme.colors.red};
  }
`;

export const Label = styled.span`
  font-size: 16px;
  color: #000;
  font-weight: 800;
`;

export const PostButtonContainer = styled.span`
  display: flex;
  margin-top: 24px;
  gap: 16px;
`;

export const TikTokButton = styled(Button)`
  border-radius: 8px;
  font-size: 16px;
  height: 48px;
  line-height: 21px;
  min-width: 124px;
  padding-left: 24px;
  padding-right: 24px;
  background-color: ${props => {
    return props.color ? props.theme.colors.grey : props.theme.colors.red;
  }};
  color: ${props => {
    return props.color ? props.theme.colors.black : props.theme.colors.white;
  }};
  font-weight: 700;
`;

export const ExtraOptions = styled.div`
  display: flex;
  flex-direction: column;

  p {
    font-size: 12px;
    color: #6c757d;
    margin-top: 5px;
  }
`;
export const ToggleContentButton = styled.button`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #000;
  font-size: 16px;
  font-weight: 700;

  span {
    color: #000;
    font-weight: 800;
  }
`;

export const MoreOptions = styled.div`
  /* margin-top: 16px; */
`;

export const AdvancedSettingsText = styled.p`
  font-size: 12px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 10px;
`;

export const FlexRowCheckBox = styled.div`
  display: flex;
  gap: 15px;
  /* margin-top: 10px; */

  .rs-checkbox-checker {
    transform: translate(-10px, 0px);
  }

  .rs-btn-toggle-checked {
    background-color: ${props => props.theme.colors.red};
  }

  .rs-checkbox label {
    font-weight: 800;

    img {
      padding-left: 5px;
      transform: translate(0px, -1px);
    }
  }
`;
