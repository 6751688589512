import axios from '../lib/axios';

export const fetchReels = async (): Promise<Post[]> => {
  try {
    const { data } = await axios.get('/reels');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchScheduledReels = async (): Promise<ScheduledReels[]> => {
  try {
    const { data } = await axios.get('/reels/scheduled');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const removeReel = async (postId: number): Promise<ScheduledReels> => {
  try {
    const { data } = await axios.delete(`/reels/${postId}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
