import React from 'react';
import { SpaceBetween } from './PositionContext.styles';

import TopLeftActiveIcon from '../../assets/icons/position/topLeftActive.svg';

import BotRightActiveIcon from '../../assets/icons/position/botRightActive.svg';

import CenterActiveIcon from '../../assets/icons/position/centerActive.svg';

import LeftTextActiveIcon from '../../assets/icons/align/leftActive.svg';
import CenterTextActiveIcon from '../../assets/icons/align/centerActive.svg';
import RightTextActiveIcon from '../../assets/icons/align/rightActive.svg';

import TopLeftGreyIcon from '../../assets/icons/position/topLeftGrey.svg';

import BotRightGreyIcon from '../../assets/icons/position/botRightGrey.svg';

import CenterGreyIcon from '../../assets/icons/position/centerGrey.svg';

import LeftTextGreyIcon from '../../assets/icons/align/leftGrey.svg';
import CenterTextGreyIcon from '../../assets/icons/align/centerGrey.svg';
import RightTextGreyIcon from '../../assets/icons/align/rightGrey.svg';

import { POSITION } from '../../lib/globalValues';

interface Props {
  componentPosition: {
    alignTitle: string;
    positionTitle: string;
    alignSubtitle: string;
    positionSubtitle: string;
  };
  typePosition:
    | 'alignTitle'
    | 'positionTitle'
    | 'alignSubtitle'
    | 'positionSubtitle';
  setComponentPosition: React.Dispatch<
    React.SetStateAction<{
      alignTitle: string;
      positionTitle: string;
      alignSubtitle: string;
      positionSubtitle: string;
    }>
  >;
  alignText?: boolean;
}

const PositionContent = ({
  typePosition,
  componentPosition,
  setComponentPosition,
  alignText = false,
}: Props) => {
  const handleChange = (position: string) => {
    setComponentPosition({ ...componentPosition, [typePosition]: position });
  };

  if (alignText) {
    return (
      <SpaceBetween align="middle">
        <button type="button" onClick={() => handleChange(POSITION.LEFT)}>
          <img
            src={
              componentPosition[typePosition] === POSITION.LEFT
                ? LeftTextActiveIcon
                : LeftTextGreyIcon
            }
            alt="Left content"
          />
        </button>

        <button type="button" onClick={() => handleChange(POSITION.CENTER)}>
          <img
            src={
              componentPosition[typePosition] === POSITION.CENTER
                ? CenterTextActiveIcon
                : CenterTextGreyIcon
            }
            alt="Right Content"
          />
        </button>

        <button type="button" onClick={() => handleChange(POSITION.RIGHT)}>
          <img
            src={
              componentPosition[typePosition] === POSITION.RIGHT
                ? RightTextActiveIcon
                : RightTextGreyIcon
            }
            alt="Center content"
          />
        </button>
      </SpaceBetween>
    );
  }
  return (
    <SpaceBetween align="middle">
      <button type="button" onClick={() => handleChange(POSITION.TOP)}>
        <img
          src={
            componentPosition[typePosition] === POSITION.TOP
              ? TopLeftActiveIcon
              : TopLeftGreyIcon
          }
          alt="Select the top left position"
        />
      </button>

      <button type="button" onClick={() => handleChange(POSITION.CENTER)}>
        <img
          src={
            componentPosition[typePosition] === POSITION.CENTER
              ? CenterActiveIcon
              : CenterGreyIcon
          }
          alt="Select the center position"
        />
      </button>

      <button type="button" onClick={() => handleChange(POSITION.BOT)}>
        <img
          src={
            componentPosition[typePosition] === POSITION.BOT
              ? BotRightActiveIcon
              : BotRightGreyIcon
          }
          alt="Select the bot right position"
        />
      </button>
    </SpaceBetween>
  );
};

export default PositionContent;
