/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-console */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { useRef, useState, FunctionComponent } from 'react';
import UploaderImage from '../../uploader/uploderImage/UploaderImage';
import UploadIcon from '../../../assets/icons/upload.svg';

interface VideoFrameExtractorProps {
  handleVideoUrl: (data: {
    dur: number;
    fra: string[];
    time: number;
    videoUrl: string;
  }) => void;
  handleFile?: any;
}

const VideoFrameExtractor: FunctionComponent<VideoFrameExtractorProps> = ({
  handleVideoUrl,
  handleFile,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [vidUrl, setVidUrl] = useState<string>('');

  const handleFileUpload = async (selectedFile: File) => {
    handleFile(selectedFile);
    if (selectedFile) {
      const url = URL.createObjectURL(selectedFile);
      setVidUrl(url);
      if (videoRef.current) {
        videoRef.current.src = url;
        videoRef.current.load();
      }
    }
  };

  const captureFrame = (
    currentTime: number,
    frameRate: number,
    duration: number,
    frames: string[],
  ) => {
    if (videoRef.current && canvasRef.current) {
      const video = videoRef.current;
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      if (context && currentTime <= duration) {
        video.currentTime = currentTime;
        const scale = 0.5;
        canvas.width = video.videoWidth * scale;
        canvas.height = video.videoHeight * scale;
        video.onseeked = () => {
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          frames.push(canvas.toDataURL('image/jpeg', 0.65));
          requestAnimationFrame(() => {
            captureFrame(currentTime + frameRate, frameRate, duration, frames);
          });
        };
      } else if (context) {
        const roundedDuration = Math.round(video.duration - 1);
        handleVideoUrl({
          dur: roundedDuration,
          fra: frames,
          time: video.currentTime,
          videoUrl: vidUrl,
        });
      }
    }
  };

  return (
    <div>
      <UploaderImage
        handleFileUpload={handleFileUpload}
        describe="Upload your media files."
        image={UploadIcon}
        restrictFiles="video/*"
      />
    </div>
  );
};

export default VideoFrameExtractor;
