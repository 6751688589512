/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Drawer } from 'rsuite';
import { useEffect, useState } from 'react';
import {
  DrawerBorder,
  SocialLogo,
  Image,
  PreviewWrapper,
  PageImageWrapper,
  PageName,
  ImageContainer,
  PostImage,
  BottomSection,
  LikesSection,
  CountWrapper,
  PageNameWrapper,
  SaveIconWrapper,
  Button,
  InputCommentSection,
  InputSection,
  Input,
  ViewMore,
  IconSmiley,
  CommentSendButton,
  InstagramModalWrapper,
} from './InstagramPreview.style';
import { MEDIA_TYPE } from '../../../lib/globalValues';
import InstagramAroundImage from '../../../assets/icons/instagramRed.svg';
import DefaultImage from '../../../assets/images/default.jpg';
import InstagramGrayHeartImage from '../../../assets/icons/instagramHeartGray.svg';
import InstagramCommentImage from '../../../assets/icons/InstaComment.svg';
import InstagramShareImage from '../../../assets/icons/InstaShare.svg';
import InstagramSaveImage from '../../../assets/icons/instaSave.svg';
import InstagramHeartLike from '../../../assets/icons/instagramHeartLikeLogo.svg';
import smileIcon from '../../../assets/images/smile.png';
import { notification } from '../../../lib/notification';
import {
  getPostCommentsById,
  sendCommentInPostById,
} from '../../../services/instagramComments';
import InstagramCommentModal from './InstagramCommentModal';
import DisplayRecentComments from './DisplayRecentComments';

interface User {
  id: string;
  username: string;
  profile_picture_url: string;
}

interface Reply {
  id: string;
  username: string;
  text: string;
  timestamp: string;
  like_count: number;
  user: User;
}

interface Comment {
  id: string;
  username: string;
  text: string;
  timestamp: string;
  like_count: number;
  user: User;
  replies: Reply[];
}

const InstragramPreview = ({ show, size, showDrawer, onHide }: any) => {
  const [userInput, setUserInput] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [commentsUpdate, setCommentsUpdate] = useState(0);

  const [comments, setComments] = useState<Comment[]>([]);

  const getCommentsDataByPostId = async () => {
    if (showDrawer?.id) {
      const { data } = await getPostCommentsById(showDrawer?.id);
      setComments(data);
    }
  };

  useEffect(() => {
    getCommentsDataByPostId();
  }, [showDrawer?.id, commentsUpdate]);

  const handleModalClose = () => setModalOpen(false);

  const handleInput = (e: any) => {
    const { value } = e.target;
    setUserInput(value);
  };

  const handleSendComment = async () => {
    if (userInput === '') {
      notification({
        type: 'info',
        title: 'Instagram Post Comment',
        description: 'Type Something...!',
      });
    }
    if (userInput !== '' && showDrawer?.id) {
      const res = await sendCommentInPostById(showDrawer?.id, userInput);
      if (res) {
        setUserInput('');
        setCommentsUpdate((prev: any) => prev + 1);
        notification({
          type: 'success',
          title: 'Instagram Post Comment',
          description: 'Comment Posted Successfully.',
        });
      }
    }
  };

  return (
    <DrawerBorder show={show} size={size} onHide={onHide}>
      <SocialLogo>
        <Image src={InstagramAroundImage} alt="icon" />
      </SocialLogo>
      <PreviewWrapper>
        <DrawerBorder.Header>
          <PageImageWrapper>
            <Image src={showDrawer?.pageProfilePicture} alt="Page Image" />
            <PageName>{showDrawer?.pageName || ''}</PageName>
          </PageImageWrapper>
        </DrawerBorder.Header>
        <Drawer.Body>
          <ImageContainer>
            {showDrawer?.media?.type === MEDIA_TYPE.IMAGE && (
              <PostImage src={showDrawer.media?.image} alt="Post" />
            )}
            {showDrawer?.media?.type === MEDIA_TYPE.VIDEO && (
              <video controls autoPlay style={{ width: '100%' }}>
                <source src={showDrawer.media?.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            {showDrawer?.media?.type === MEDIA_TYPE.TEXT && (
              <PostImage src={DefaultImage} alt="Post" />
            )}
          </ImageContainer>
          <BottomSection>
            <LikesSection>
              <Button>
                <img
                  src={
                    showDrawer?.totalLikes > 0
                      ? InstagramHeartLike
                      : InstagramGrayHeartImage
                  }
                  alt="Like Icon"
                />
              </Button>
              <img src={InstagramCommentImage} alt="Comment Icon" />
              <img src={InstagramShareImage} alt="Share Icon" />
              <SaveIconWrapper>
                <img src={InstagramSaveImage} alt="Save Icon" />
              </SaveIconWrapper>
            </LikesSection>

            <CountWrapper>
              {showDrawer?.totalLikes > 0 && (
                <span> {`${showDrawer?.totalLikes} like`}</span>
              )}
              {showDrawer?.totalLikes > 0 && showDrawer?.totalLikes > 1 && (
                <span> {`${showDrawer?.totalLikes} likes`}</span>
              )}
              <PageNameWrapper>
                <span>{showDrawer?.pageName}</span>
                {showDrawer?.text}
              </PageNameWrapper>
              {comments?.length >= 1 && (
                <ViewMore
                  onClick={() =>
                    setModalOpen(true)
                  }>{`View All ${comments?.length} comments`}</ViewMore>
              )}
              <DisplayRecentComments comments={comments} />
            </CountWrapper>
          </BottomSection>
          <InputCommentSection>
            <InputSection>
              <Input
                value={userInput}
                placeholder="Add a comment..."
                onChange={e => handleInput(e)}
              />
              <div>
                {userInput !== '' && (
                  <CommentSendButton onClick={handleSendComment}>
                    Post
                  </CommentSendButton>
                )}
                <IconSmiley src={smileIcon} alt="smiley icon" />
              </div>
            </InputSection>
          </InputCommentSection>
          <InstagramModalWrapper>
            <InstagramCommentModal
              comments={comments}
              show={modalOpen}
              onHide={handleModalClose}
              showDrawer={showDrawer}
              setCommentsUpdate={setCommentsUpdate}
            />
          </InstagramModalWrapper>
        </Drawer.Body>
      </PreviewWrapper>
    </DrawerBorder>
  );
};

export default InstragramPreview;
