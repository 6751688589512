import { FlexboxGrid } from 'rsuite';
import styled from 'styled-components';

export const SpaceBetween = styled(FlexboxGrid)`
  margin-bottom: 10px;
  button {
    margin: 0px 10px;
    @media ${props => props.theme.breakpoints.xs},
      ${props => props.theme.breakpoints.sm} {
      margin: 10px 10px;
    }
  }
`;

export const CircleButton = styled.button<{ bgColor: string }>`
  border-radius: 50px;
  height: 50px;
  width: 50px;
  background-color: ${props => props.bgColor};

  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    height: 35px;
    width: 35px;
  }
`;
