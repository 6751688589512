import 'cropperjs/dist/cropper.css';
import { useEffect, useState } from 'react';
import { CropperSize } from './CropperImage.styles';

interface Props {
  originalUrl: string | null;
  setCropper: React.Dispatch<React.SetStateAction<Cropper | undefined>>;
  cropper: Cropper | undefined;
}

const invalidateSize = (width: number, height: number) => {
  const getSize = width / height;

  return getSize < 0.81 || getSize > 1.89;
};

const CropperImage = ({ originalUrl, setCropper, cropper }: Props) => {
  const [initialValues, setInitialValues] =
    useState<Cropper.SetCropBoxDataOptions | null>(null);

  const [changeCrop, setChangeCrop] =
    useState<Cropper.CropEndEvent<HTMLImageElement> | null>(null);

  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (!cropper) {
      return;
    }

    const getCropBoxData = cropper.getCropBoxData();

    const { width, height } = getCropBoxData;

    if (!invalidateSize(width, height)) {
      setInitialValues(getCropBoxData);
      return;
    }

    if (initialValues) {
      cropper.setCropBoxData(initialValues);
    } else {
      cropper.reset();
    }
  }, [changeCrop]);

  return (
    <CropperSize
      isInvalid={isInvalid}
      zoomTo={0.5}
      initialAspectRatio={1}
      preview=".img-preview"
      src={originalUrl || ''}
      viewMode={1}
      minCropBoxHeight={50}
      minCropBoxWidth={50}
      background={false}
      responsive
      autoCropArea={1}
      checkOrientation={false}
      onInitialized={instance => {
        setCropper(instance);
      }}
      guides
      zoomable={false}
      cropend={(e: Cropper.CropEndEvent<HTMLImageElement>) => setChangeCrop(e)}
      crop={(e: Cropper.CropEvent<HTMLImageElement>) =>
        setIsInvalid(invalidateSize(e.detail.width, e.detail.height))
      }
    />
  );
};

export default CropperImage;
