/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
declare global {
  interface Window {
    fbAsyncInit: () => void;
  }
}

export function initFacebookSdk() {
  return new Promise<void>(resolve => {
    // wait for facebook sdk to initialize before starting the react app
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: process?.env?.REACT_APP_API_FACEBOOK,
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v12.0',
      });
      return resolve();
    };

    // load facebook sdk script
    (function (d, s, id) {
      let js: any = {};
      const fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs?.parentNode?.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
  });
}

export const PRIVILEGIES_SCOPE =
  'public_profile,pages_manage_posts,pages_read_engagement,email,pages_read_user_content,ads_management,ads_read,read_insights,pages_read_engagement,pages_messaging,pages_manage_engagement';

export const PRIVILEGIES_SCOPE_INSTAGRAM =
  'instagram_basic, pages_show_list, instagram_manage_comments, instagram_content_publish,instagram_manage_messages,instagram_manage_insights,pages_read_engagement,pages_show_list,pages_manage_posts,ads_management,business_management';
