import axios from '../lib/axios';
import { notification, notificationError } from '../lib/notification';

export const updateCompany = async (
  id: number,
  updatedData: {
    name: string;
    phone: string;
    address: string;
    country: string;
    city: string;
    zipCode: string;
    webLink: string;
  },
) => {
  try {
    const response = await axios.put(`/companies/${id}`, updatedData);
    notification({
      type: 'success',
      title: 'Success',
      description: 'Company was updated',
    });
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'Company could not be updated',
    });
    return error;
  }
};
