import { Link } from 'react-router-dom';
import { Divider, FlexboxGrid, Content } from 'rsuite';
import styled from 'styled-components';

export const Contentt = styled(Content)`
  width: 100%;
  height: 100%;
`;
export const IconLink = styled(Link)`
  color: ${props => props.theme.colors.grey};
`;

export const FlexboxGridMb = styled(FlexboxGrid)`
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    margin: 2em 0em;
  }
`;
export const StyledFlexboxGrid = styled(FlexboxGrid)`
  /* Your custom styles here */
  position:fixed;height60%
  padding: 20px;
  background-color: #f8f8f8;
`;

export const StyledFlexboxGridItem = styled(FlexboxGrid.Item)`
  height: 50rem; /* Default height */
  overflow-y: auto;

  @media (max-width: 768px) {
    height: 500px; /* Adjust height for smaller screens */
  }

  @media (max-width: 480px) {
    height: 400px; /* Adjust height for mobile devices */
  }
`;
export const StyledFlexboxGridItem2 = styled(FlexboxGrid.Item)`
  height: auto; /* Default height */
  position: relative;
  width: 30%;
  border: 1px solid #ccc; /* Optional: Add border to see the container */
  background: #fff;

  @media (max-width: 768px) {
    width: 100%;
    height: 500px; /* Adjust height for smaller screens */
  }

  @media (max-width: 480px) {
    height: 400px; /* Adjust height for mobile devices */
  }

  /* Optional: Customize the scrollbar for better visibility */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Darker color on hover */
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; /* Background of the scrollbar track */
  }
`;

export const TitleAside = styled.p`
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: bolder;
`;

export const HeaderP = styled.p`
  padding: 10px 0px 30px;
`;

export const SubtitleAside = styled.p`
  color: ${props => props.theme.colors.pinkRed};
  font-size: ${props => props.theme.fonts.paragraph};
  padding: 10px 0px;
`;

export const DividerAside = styled(Divider)`
  margin: 40px 10%;
  background-color: ${props => props.theme.colors.grey};
`;

export const Title = styled.p`
  color: ${props => props.theme.colors.greyLetter};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: 550;
`;

export const Button = styled.button`
  color: #ffffff !important;
  background-color: #e74138 !important;
  font-size: 1.3em;
  padding: 5px;
  font-weight: bolder;
  border-radius: 20px;
  margin-right: 15px;
`;

export const ToolImage = styled.img`
  margin-right: 10px;
`;

export const ToolsContainer = styled.div`
  @media ${props => props.theme.breakpoints.maxMedium} {
    margin: 10px;
  }
`;

export const TitleContent = styled.p`
  font-size: ${props => props.theme.fonts.subtitle};
  font-weight: bolder;
  color: ${props => props.theme.colors.black};
  margin-top: 20px;
  margin-bottom: 10px;
  //  @media (max-width: 768px) {
  //   margin-top: 0px;
  // }

  //  @media (max-width: 480px) {
  //   margin-top: 0px;
  // }
`;

export const PostsContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height:100%;
  cursor: pointer;
  border-radius: 5px;
  /* Responsive Styles */
  @media (max-width: 1200px) {
    padding: 15px;
    // min-height: 70vh;
  }

  @media (max-width: 992px) {
    padding: 10px;
    // min-height: 60vh;
  }
`;

export const Top = styled.div`
  position: relative;
  display: flex;
  justify-content: end; // Changed to space-between for better alignment
  // z-index: 5;
  height:40px;

  @media (max-width: 768px) {

    align-items: flex-start; // Align items to the start
    width: 100%; // Ensure it takes full width
  }
`;

export const Header = styled.div`
width:100%;
  font-family: 'Comformaa', Helvetica, Sans-Serif;
  font-size: 22px;
  position: relative;
  display: flex;
  height:40px;
  justify-content: space-between;
  background: #fff;
  @media (max-width: 768px) {
    align-items: flex-start; // Align items to the start
    width: 100%; // Full width
  }
`;

export const HeaderText = styled.h1`
  font-family: 'Comformaa', Helvetica, Sans-Serif;
  font-size: 22px;
  font-size: 24px;
  font-weight: bold;
  color: #1e1f26;
  text-align: center;
  line-height: 30px;
  @media (max-width: 768px) {
    font-size: 18px;
    text-align: center;
  }
`;

export const HeaderNumber = styled.span`
  font-family: 'Comformaa', Helvetica, Sans-Serif;
  font-size: 22px;
  color: red;
  font-size: 24px;
  font-weight: normal;

  @media (max-width: 768px) {
    font-size: 16px; // Smaller font size on mobile
  }
`;

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto; 
  height: calc(100% - 80px);
  &::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #fff; /* Color of the scrollbar thumb */
    border-radius: 10px; /* Rounded corners */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #fff; /* Darker color on hover */
  }

  &::-webkit-scrollbar-track {
    background: #fff; /* Background of the scrollbar track */
  }
  @media (max-width: 768px) {
    max-height: calc(500px - 100px); /* Adjust height for mobile */
  }

  @media (max-width: 1220px) {
    max-height: 40rem;
  }
  @media (max-width: 1020px) {
    // max-height:40rem;
  }
  @media (max-width: 480px) {
  max-height: 40rem;
  }
`;
export const ContentContainer = styled.div`
  width: 100%;
  background-color: #fff;
  @media (max-width: 780px) {
    width: 100%;
  }
`;
export const SeeMoreButton = styled.button`
  width: 100%;
  background-color: #dd2256; /* Your primary color */
  color: white; /* Text color */
  border: none; /* Remove default border */
  border-radius: 8px;
  padding: 10px 10px; /* Vertical and horizontal padding */
  cursor: pointer; /* Pointer cursor on hover */
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth transitions */
  // margin-bottom:5px;
  &:hover {
    background-color: #c51e4a; /* Darker shade on hover */
    // transform: translateY(-2px); /* Slight lift effect */
  }

  // &:active {
  //   transform: translateY(1px); /* Pressed effect */
  // }

  @media (max-width: 768px) {
    font-size: 14px; /* Smaller font size for mobile */
    padding: 8px 16px; /* Adjust padding for mobile */
  }
`;
export const ReelsContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  height:100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 5px;
  /* Responsive Styles */
  @media (max-width: 1200px) {
    padding: 15px;
    // min-height: 70vh;
  }

  @media (max-width: 992px) {
    padding: 10px;
    // min-height: 60vh;
  }
`;

export const ReelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: calc(50rem - 100px);

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
  }

  @media (max-width: 768px) {
    max-height: calc(500px - 100px);
  }

  @media (max-width: 480px) {
    max-height: calc(400px - 100px);
  }
`;

export const ReelHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
`;

export const ReelSeeMoreButton = styled.button`
  background-color: #dd2256;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c71f4d;
  }

  @media (max-width: 768px) {
    font-size: 14px;
    padding: 8px 16px;
  }
`;
export const MessegeContainer = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  height:100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  border-radius: 5px;
  /* Responsive Styles */
  @media (max-width: 1200px) {
    padding: 15px;
    // min-height: 70vh;
  }

  @media (max-width: 992px) {
    padding: 10px;
    // min-height: 60vh;
  }
`;
