// export const freePlanFeatures = [
//   'Social Media Management: Manage posts, scheduling, analytics, and messaging for platforms like Facebook, Instagram, and TikTok',
//   'Review Management: View and respond to reviews from Facebook and Google in one place',
//   'Unified Messaging: Centralized inbox for all customer messages from social platforms',
//   'Promotions & Ads: Manage Facebook and Instagram ads directly on the platform',
//   'AI Assistance: Generate optimized hashtags, messages, and posts for better content',
//   'Analytics Integration: Track performance metrics',
// ];

export const freePlanFeatures = [
  {
    heading: 'Social Media Management',
    description:
      'Manage posts, scheduling, analytics, and messaging for platforms like Facebook, Instagram, and TikTok',
  },
  {
    heading: 'Review Management',
    description:
      'View and respond to reviews from Facebook and Google in one place',
  },
  {
    heading: 'Unified Messaging',
    description:
      'Centralized inbox for all customer messages from social platforms',
  },
  {
    heading: 'Promotions & Ads',
    description: 'Manage Facebook and Instagram ads directly on the platform',
  },
  {
    heading: 'AI Assistance',
    description:
      'Generate optimized hashtags, messages, and posts for better content',
  },
  {
    heading: 'Analytics Integration',
    description: 'Track performance metrics',
  },
];
