/* eslint-disable @typescript-eslint/no-explicit-any */
import { useRef } from 'react';
import { Content } from '../uploader.styles';

interface Props {
  handleImageChange: any;
  describe: string;
  image: string;
}

const TikTokImageUploader = ({ handleImageChange, describe, image }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <>
      <input
        ref={fileInputRef}
        type="file"
        onChange={handleImageChange}
        accept="image/*"
        multiple
        style={{ display: 'none' }}
      />
      <Content onClick={handleClick}>
        <img src={image} alt="Upload your file" style={{ cursor: 'pointer' }} />
        <p>{describe}</p>
      </Content>
    </>
  );
};

export default TikTokImageUploader;
