import { Container } from './RectangleButton.styles';

interface Props {
  title: string;
  onClick?: () => void;
  disabled?: boolean;
  color?: boolean;
}

const RectangleButton = ({ title, disabled, onClick, color }: Props) => {
  return (
    <Container disabled={disabled} color={color} onClick={onClick}>
      {title}
    </Container>
  );
};

export default RectangleButton;
