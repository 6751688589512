// StyledComponents.tsx
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(120deg, #f3f4f6, #e2e8f0);
  padding: 2rem;
  color: #333;
  font-family: 'Helvetica', sans-serif;
`;

export const Card = styled.div`
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  padding: 2.5rem 2rem;
  max-width: 450px;
  text-align: center;
`;

export const Heading = styled.h1`
  font-size: 2.2rem;
  color: #dd2256;
  margin-bottom: 0.75rem;
  font-weight: 700;
`;

export const SubHeading = styled.h2`
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 1.25rem;
  font-weight: 400;
`;

export const Text = styled.p`
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  font-family: 'Arial', sans-serif;
`;

export const Button = styled.button`
  background: #dd2256;
  color: #ffffff;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;

  &:hover {
    background: #c51c4b;
    transform: scale(1.03);
  }

  &:active {
    background: #b01941;
  }
`;
