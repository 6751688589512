import { useQuery } from 'react-query';
import { fetchPosts } from '../services/posts';

const usePosts = () => {
  return useQuery('POSTS', fetchPosts, {
    staleTime: 5000,
    notifyOnChangePropsExclusions: ['isStale'],
    refetchOnWindowFocus: false,
  });

  const { data, isLoading, isError, isFetching, refetch } = useQuery(
    'POSTS',
    fetchPosts,
    {
      staleTime: 5000,
      notifyOnChangePropsExclusions: ['isStale'],
      refetchOnWindowFocus: false,
    },
  );

  return { data, isLoading, isError, isFetching, refetch };
};

export default usePosts;
