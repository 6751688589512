import styled from 'styled-components';
// import styled, { keyframes } from 'styled-components';

// interface StepCircleProps {
//   active: boolean;
// }

// export const Container = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: row;
//   height: 100vh;
//   overflow-y:auto;
// `;

// export const LeftPane = styled.div`
//   width: 40%;
//   height: 100%;
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   background-color: #fff;
//   overflow-y: auto;
//   overflow-x: hidden;
  
//   @media (max-width: 1024px) {
//     width: 50%; /* Adjust width at 1024px screen width */
//       overflow-y: auto;

//   }

//   @media (max-width: 780px) {
//     width: 100%; /* Full width on small screens */
//       overflow-y: auto;

//   }
// `;

// export const RightPane = styled.div`
//   width: 60%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow-x: hidden;

//   @media (max-width: 1024px) {
//     width: 50%; /* Adjust width at 1024px screen width */
//   }

//   @media (max-width: 780px) {
//     display: none; /* Hide on small screens */
//   }
// `;

// const moveUpDown = keyframes`
//   0%, 100% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(-10px);
//   }
// `;

// export const ImageContainer = styled.div`
//   height: auto;
//   width: 80%;
//   display: flex;
//   flex-direction: column;
//   // position: relative;
//   margin: 0 auto;
//   justify-content: center;

//   img {
//     animation: ${moveUpDown} 2s ease-in-out infinite;
//   }
// `;

// export const StyledImage = styled.img`
//   width:80%;
//     margin:0 auto;

//   max-height: 100%;
//   object-fit: cover;
//   margin-bottom:40px;
//   @media (max-width: 1280px) {
//     width: 80%;
//   }
//   @media (max-width: 1020px) {
//     width: 70%;
//   }
//   @media (max-width: 780px) {
//     width: 60%;
//   }
//   @media (max-width: 780px) {
//     width: 90%;
//   }
// `;

// export const DinabiteText = styled.p`
//   font-size: 1.2rem;
//   height:40px;
//   width:75%;
//   margin:0 8rem;
//   color: grey;
//   text-align: center;
//   font-family: 'Inter', sans-serif;
//   font-size: 16px;

//   @media (max-width: 1480px) {
//     margin-top:40px;
//       margin:0 6rem;
//     font-size: 14px;
//   }
//   @media (max-width: 1280px) {
//     margin-top:40px;
//     font-size: 14px;

//   }
//   @media (max-width: 1024px) {
//   margin-top:60px;
//     font-size: 14px;
//       margin:0 4rem;

//   }
//   @media (max-width: 780px) {
//     display: none;
//   }
// `;

export const StepHeaderContainer = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-family: 'Inter', sans-serif;
    margin-bottom:10px;
      // overflow-y: auto;
    @media (max-width: 640px) {
    margin-bottom:20px;
  }

`;

export const Stepbodycontainer = styled.div`
  width: 100%;
  height: 95%;
  // overflow-y: auto;
  
`;

export const CreateAccountTitle = styled.h1`
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  color: #dd2256;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 1rem;
  }
`;

// export const ImageContainermobile = styled.div`
//   display: none;  // Hide by default
  
//   @media (max-width: 780px) {
//   display:block;
//     max-width: 40%; /* Responsive width */
//     max-height: 20%; /* Maintain aspect ratio */
//   margin: 0 auto; /* Center the image */
  
//   }
//   @media (max-width: 480px) {
//   display:block;
//     max-width: 40%; /* Responsive width */
//     min-height: 15%; /* Maintain aspect ratio */
  
  
//   }
// `;