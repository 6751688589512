/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Button, Col, Modal, Row } from 'rsuite';
import { TYPES_POSTS } from '../../../actions/postsActions';
import VideoIcon from '../../../assets/icons/video.svg';
import { srcToFile } from '../../../lib/encodeImg';
import {
  ModalPosition,
  PreviewVideo,
  RecentImageSelect,
} from './ModalSelectMedia.styles';

interface Props {
  media: Media[];
  toggleShowSelectMedia: () => void;
  showSelectMedia: boolean;
  dispatchPost: any;
  videoNotAcceptedByGoogle: () => void;
  setIsImageSelected: (state: any) => void;
}


const ModalSelectMedia = ({
  media,
  toggleShowSelectMedia,
  showSelectMedia,
  dispatchPost,
  videoNotAcceptedByGoogle,
  setIsImageSelected,
}: Props) => {



  const removeOriginalUrl = async (file: any) => {
    try {
    const fileImg = await srcToFile(file.url);
    const objectToUrl = URL.createObjectURL(fileImg);

    dispatchPost({
      type: TYPES_POSTS.ADD_IMAGE_URL,
      payload: {
        id: file.id,
        urlFile: objectToUrl,
        name: file.name,
      },
    });
  } catch(e) {
   // console.log(e);
  }
  };
  const handleImageSelect = async (file: Media) => {
    try {
    const fileImg = await srcToFile(file.url);

    setIsImageSelected({
      imgFile: fileImg,
      state: true,
    });
  } catch(e) {
    // console.log(e);
  }
}
  return (
    <ModalPosition full show={showSelectMedia} onHide={toggleShowSelectMedia}>
      <Modal.Body>
        <Row style={{ margin: '0px' }}>
          {media
            .slice(0)
            .reverse()
            .map((file: Media) => (
              <Col xs={24} sm={12} md={8} key={file.id}>
                {file.type === 'image' ? (
                  <RecentImageSelect
                    urlImage={file.url}
                    onClick={async () => {
                      removeOriginalUrl(file);
                    
                      dispatchPost({
                        type: TYPES_POSTS.ADD_ID,
                        payload: file.id,
                      });
                      
                      handleImageSelect(file);
                     
                      toggleShowSelectMedia();
                    }}
                  />
                ) : (
                  <PreviewVideo
                    onClick={() => {
                      dispatchPost({
                        type: TYPES_POSTS.ADD_VIDEO_URL,
                        payload: file.url,
                      });
                      videoNotAcceptedByGoogle();
                      dispatchPost({
                        type: TYPES_POSTS.ADD_ID,
                        payload: file.id,
                      });
                      toggleShowSelectMedia();
                    }}>
                    <img src={VideoIcon} alt={file.name} />
                    <video>
                      <source src={file.url} type="video/mp4" />
                    </video>
                  </PreviewVideo>
                )}
              </Col>
            ))
            .reverse()}
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={toggleShowSelectMedia} appearance="subtle">
          Cancel
        </Button>
      </Modal.Footer>
    </ModalPosition>
  );
};

export default ModalSelectMedia;
