import styled from 'styled-components';
// import styled, { keyframes } from 'styled-components';
// import { Link } from 'react-router-dom';
// import { FlexboxGrid as RSFlexboxGrid } from 'rsuite';

// Container Styles
// interface ContainerProps {
//   background?: string;
// }

// export const Container = styled.div<ContainerProps>`
//   display: flex;
//   flex-direction: row;
//   height: 100vh;
//   // background: ${({ background }) => background || 'linear-gradient(45deg, #f857a6, #dd2256)'};
// `;

// export const LeftPane = styled.div`
//   width: 40%;
//   position:relative;
//   display: flex;
//   flex-direction: column;
//   background-color: #fff;
//   overflow-y:auto;
//   overflow-x:hidden;
//   @media (max-width: 1024px) {
//     width: 50%; /* Equal width at 1024px screen width */
//   }

//   @media (max-width: 780px) {
//     width: 100%;
//   }
// `;

// export const RightPane = styled.div`
//   width: 60%;
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow-x: hidden;

//   @media (max-width: 1024px) {
//     width: 50%; /* Adjust width at 1024px screen width */
//   }

//   @media (max-width: 780px) {
//     display: none; /* Hide on small screens */
//   }
// `;

// // Animation
// const moveUpDown = keyframes`
//   0%, 100% {
//     transform: translateY(0);
//   }
//   50% {
//     transform: translateY(-10px);
//   }
// `;

// // Image Container Styles
// export const ImageContainer = styled.div`
//   height: auto;
//   width: 80%;
//   display: flex;
//   flex-direction: column;
//   // position: relative;
//   margin: 0 auto;
//   justify-content: center;

//   img {
//     animation: ${moveUpDown} 2s ease-in-out infinite;
//   }
// `;
// export const StyledImage = styled.img`
//   width: 60%;
//     margin:0 auto;
//   object-fit: cover;
//       margin-bottom:40px;

//   @media (max-width: 1480px) {
//     width: 60%;
//     margin-bottom:20px;
//   }
//   @media (max-width: 1280px) {
//     width: 50%;
//   }
//   @media (max-width: 1020px) {
//     width: 50%;
//   }
//   @media (max-width: 780px) {
//     width: 60%;
//   }
//   @media (max-width: 780px) {
//     width: 90%;
//   }
// `;
// export const DinabiteText = styled.p`
// height:30%;
//   font-size: 1.4rem;
//   color: #dd2256;
//   text-align: center;
//   font-family: 'Inter', sans-serif;

//   @media (max-width: 1480px) {
//     margin-top:40px;

//     font-size: 1.1rem;
//   }
//   @media (max-width: 1280px) {
//     margin-top:40px;

//     font-size: 1.1rem;
//   }
//   @media (max-width: 1024px) {
//   margin-top:60px;
//     font-size: 0.9rem;
//   }
//   @media (max-width: 780px) {
//     display: none;
//   }
// `;

// Header Styles
export const StepHeaderContainer = styled.div`
  width: 100%;
  height: 5%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  font-family: 'Inter', sans-serif;
    margin-bottom:10px;
      // overflow-y: auto;
    @media (max-width: 640px) {
    margin-bottom:20px;
  }

`;
export const CreateAccountTitle = styled.h1`
  font-size: 1rem;
  font-family: 'Inter', sans-serif;
  color: #dd2256;
  text-align: center;
  @media (max-width: 640px) {
    font-size: 1rem;
  }
`;

// export const Title = styled.h2`
//     font-family: 'Inter', sans-serif;

// font-size: 30px;
// color: #DD2256;
// margin: 0 auto 20px auto;
// padding: 0 60px;
// font-family: 'Inter', sans-serif;
// text-align: center; /* Center the text */

// @media (max-width: 780px) {
// display:none;

// }
// `;
// export const Subtitle = styled.p`
//     font-family: 'Inter', sans-serif;

//   font-size: 18px;
//   color: #666;
//   margin-bottom: 10px;
//   padding: 0 70px;
//   text-align: center; /* Center the text */
//  @media (max-width: 1024px) {
//     font-size: 18px;
//     margin-bottom: 5px;
//   padding: 0 50px;
//   }
//   @media (max-width: 780px) {
//   display:none;
//   }

// `;
// export const Titlembile = styled.h2`
//   display: none; /* Hide by default */

//   @media (max-width: 780px) {
//       font-family: 'Inter', sans-serif;

//   width:100%;
//     display: block; /* Show on mobile */
//     font-size: 30px;
//     color: #DD2256;
//     font-family: 'Inter', sans-serif;
//     text-align: left; /* Align text to the left */
//     margin: 0; /* Reset margins if necessary */
//       @media (max-width: 380px) {
//     width:90%;
//   }
//   }
// `;
// export const Subtitlemobile = styled.p`
//   display:none;
//   @media (max-width: 780px) {
//     width:100%;
//     font-family: 'Inter', sans-serif;

//   display:block;
//   font-size: 18px;
//   color: #666;
//   text-align: left; /* Center the text */
//     font-size: 14px;
//     margin-bottom:5px;
//   }

//   @media (max-width: 380px) {
//       padding: 0 20px;
//     font-size: 12px;
//   }
// `;

export const StepIndicator = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
`;

export const StepCircle = styled.div<{ active: boolean }>`
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#DD2256" : "#FFC0CB")};
  transition: background-color 0.3s ease-in-out;
`;


export const BackButtondiv = styled.div`
  display: none;

  @media (max-width: 780px) {
      font-family: 'Inter', sans-serif;

    display: flex;
    width: 78%;
    position: fixed;
    bottom: 0;
    left: 50%;  /* Positioning to the center */
    transform: translateX(-50%);  /* Center alignment */
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 10px;  /* Padding inside the container */
    z-index: 1000;
    min-height: 140px;  /* Set the minimum height here */
  }

  @media (max-width: 480px) {
    padding: 0;  /* Adjust padding for smaller screens */
  }
`;




export const BackButton = styled.button`
  // background-color: transparent;
  background-color: #ffd6df;
  font-family: 'Inter', sans-serif;
  color: #DD2256;
  width: 140px;
  height: 50px;
  font:bold;
  border: 1px solid #DD2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;  /* Margin for spacing between buttons */

  &:hover {
  background: linear-gradient(180deg, #DD2256 0%, #E43C39 100%);
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 140px;
    height: 40px;
    font-size: 18px;
    border-radius: 12px;
    margin: 0 5px;  /* Adjust spacing for smaller screens */
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 50px;
    font-size: 186x;
    border-radius: 15px;
    margin: 0 5px;  /* Adjust spacing for smaller screens */
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px;  /* Slightly smaller font for very small screens */
    border-radius: 15px;
  }
`;

export const BackButtonnext = styled.button`
    font-family: 'Inter', sans-serif;

  color: #fff;
  width: 140px;
  height: 50px;
  border: 1px solid #DD2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;  /* Margin for spacing between buttons */
  background: linear-gradient(180deg, #DD2256 0%, #E43C39 100%);

  &:hover {
    background-color: #C51E4A;
    color: #fff;
  }

  @media (max-width: 780px) {
    width: 140px;
    height: 40px;
    font-size: 18px;
    border-radius: 12px;
    margin: 0 5px;  /* Adjust spacing for smaller screens */
  }
  @media (max-width: 480px) {
    width: 140px;
    height: 50px;
    font-size: 16px;
    border-radius: 15px;
    margin: 0 5px;  /* Adjust spacing for smaller screens */
  }

  @media (max-width: 380px) {
    width: 120px;
    height: 40px;
    font-size: 14px;  /* Slightly smaller font for very small screens */
    border-radius: 15px;
  }
`;


export const ContentContainer = styled.div`
  // flex: 1;
  width: 90%;
  margin:0 auto;
  overflow-y: auto; /* Allow scrolling for content */
  display: flex;
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  
  @media (max-width: 780px) {
  width:80%;
    padding-bottom: 120px;
  }
`;


export const DesktopBackButtonContainer = styled.div`
  display: flex;

  flex-direction: row-reverse;
  justify-content: center; 
  align-items: center; 
  width: 68%;
  // margin: 0 auto;
  background: #fff;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  font-family: 'Inter', sans-serif;
  margin-top:100px;
  gap:20px;
  
  @media (max-width: 1480px) {
    width: 72%;
    justify-content:space-between;
    // margin-right:10px;

  }
  @media (max-width: 780px) {
    display: none;
  }
`;

export const NextButtonContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: start; /* Centers the button inside the container */
  align-items: center; /* Ensures the button is centered vertically */
`;

export const BackCancelContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: end  ; /* Centers the button inside the container */
  align-items: center; /* Ensures the button is centered vertically */
  // margin: 0 10px;
  border: 0.1em solid #FFFFFF;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #FFFFFF;
  text-align: center;
  transition: all 0.2s;

  @media (max-width: 780px) {
    margin-bottom: 20px;
  }
`;

export const Button = styled.button`
  width: 100%; /* Makes the button fill its container */
  background: linear-gradient(180deg, #DD2256 0%, #E43C39 100%);
  color: white;
  padding: 10px 0; /
  border: none;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #c51e4a;
  }

  @media (max-width: 1480px) {
    font-size: 16px;
    border-radius: 10px;
      padding: 8px 0; /* Standardizes padding to ensure the same height */

  }

  @media (max-width: 780px) {
    display: none;
  }
`;

export const DesktopBackButton = styled.button`
  width: 100%; /* Makes the button fill its container */
  font-family: 'Inter', sans-serif;
  background-color: #fff;
  color: #dd2256;
  padding: 10px 0; 
  border: 1px solid #dd2256;
  border-radius: 12px;
  font-size: 18px;
  cursor: pointer;
  margin: 0; /* Removes top margin to prevent height differences */
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #DD2256 0%, #E43C39 100%);
    color: #fff;
  }

  @media (max-width: 1480px) {
    font-size: 16px;
    border-radius: 10px;
    padding: 7px 0; /* Standardizes padding to ensure the same height */

  }

  @media (max-width: 780px) {
    display: none;
  }
`;
export const StyledErrorMessage = styled.div`
  color: red;
  font-size: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  width:65%;
  color:red;
  @media (max-width: 1480px) {
    margin-top: none;
    margin-bottom: none;
    line-height:0px;

  }
  @media (max-width: 780px) {

  }
`;
