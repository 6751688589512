import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent !important;
  padding: 25px 0px !important;
  border: 2px dashed ${props => props.theme.colors.grey} !important;
  p {
    color: ${props => props.theme.colors.greyLetter};
    font-weight: bold;
  }
`;
