import { useEffect, useState } from 'react';
import { DrawerBorder } from './DrawerPost.styles';
import { getFacebookPost } from '../../../services/facebookTools';
import { getTwitterPost } from '../../../services/twitterTools';
import { TYPES_OF_SOCIAL_NETWORKS } from '../../../lib/globalValues';
import FaceBookPreview from '../FaceBookPreview/FaceBookPreview';
import InstragramPreview from '../InstagramPreview/InstagramPreview';
import GooglePreview from '../GooglePreview/GooglePreview';

interface Props {
  showDrawer: null | Post;
  setShowDrawer: React.Dispatch<React.SetStateAction<Post | null>>;
  setUpdatePost: React.Dispatch<React.SetStateAction<number | 0>>;
}

interface DataPost {
  totalComments?: number;
  totalLikes?: number;
  totalShares?: number;
}

const DrawerPost = ({ showDrawer, setShowDrawer, setUpdatePost }: Props) => {
  const [dataPost, setDataPost] = useState<DataPost | null>(null);

  useEffect(() => {
    if (!showDrawer?.id) {
      return;
    }
    const getDataPostFacebook = async () => {
      const { data } = await getFacebookPost(showDrawer.id);
      setDataPost(data);
    };

    const getDataPostTwitter = async () => {
      const { data } = await getTwitterPost(showDrawer.id);
      setDataPost(data);
    };

    if (showDrawer?.network === TYPES_OF_SOCIAL_NETWORKS.FACEBOOK) {
      getDataPostFacebook();
    } else if (showDrawer?.network === TYPES_OF_SOCIAL_NETWORKS.TWITTER) {
      getDataPostTwitter();
    }
  }, [showDrawer]);

  const renderPreview = () => {
    if (showDrawer?.network === 'facebook') {
      return (
        <FaceBookPreview
          show={showDrawer}
          size="md"
          showDrawer={showDrawer}
          onHide={() => setShowDrawer(null)}
          setUpdatePost={setUpdatePost}
        />
      );
    }

    if (showDrawer?.network === 'instagram') {
      return (
        <InstragramPreview
          show={showDrawer}
          size="md"
          showDrawer={showDrawer}
          onHide={() => setShowDrawer(null)}
        />
      );
    }

    return (
      <GooglePreview
        dataPost={dataPost}
        show={showDrawer}
        size="md"
        showDrawer={showDrawer}
        onHide={() => setShowDrawer(null)}
      />
    );
  };

  return <DrawerBorder>{renderPreview()}</DrawerBorder>;
};

export default DrawerPost;
