import FormForgetPass from '../../components/forms/formForgetPass/FormForgetPass';

const ForgetPass = () => {
  return (
    <>
      <FormForgetPass />
    </>
  );
};

export default ForgetPass;
