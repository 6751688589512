/* eslint-disable @typescript-eslint/no-explicit-any */

import { ErrorMessage, IconInInput, InputIcon } from '../../Forms.styles';
import { HiddenGroup } from '../FormSignUp.styles';
import { InputContainer, Label } from './FieldInput.styles';

interface Props {
  icon?: string;
  placeholder: string;
  name: string;
  page: boolean;
  type: string;
  touched: any;
  errors: any;
  label: string;
}

const FieldInput = ({
  icon,
  placeholder,
  name,
  page,
  type,
  touched,
  errors,
  label,
}: Props) => {
  return (
    <InputContainer>
      <Label htmlFor={name}>{label}</Label>
      <HiddenGroup inside page={page ? 1 : 0}>
        {icon && (
          <IconInInput>
            <img src={icon} alt="Icon fields" />
          </IconInInput>
        )}
        <InputIcon
          placeholder={placeholder}
          type={type}
          name={name}
          error={touched[name] && errors[name]}
        />
        {touched[name] && errors[name] && (
          <ErrorMessage signUp>{errors[name]}</ErrorMessage>
        )}
      </HiddenGroup>
    </InputContainer>
  );
};

export default FieldInput;
