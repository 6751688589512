import { useState } from 'react';

export default function useTextarea(initialValue = 0) {
  const [counterTextLength, setCounterTextLength] =
    useState<number>(initialValue);

  const handleCounterTextLength = (textLength: number) =>
    setCounterTextLength(textLength);

  return { counterTextLength, handleCounterTextLength };
}
