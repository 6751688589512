import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 10px;
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const ImagePreview = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
`;

export const RemoveIcon = styled.span`
  position: absolute;
  top: 5px;
  right: 5px;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0px 6px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 13px;
`;
