import { FlexboxGrid, Icon } from 'rsuite';
import styled from 'styled-components';

export const SocialIcon = styled(Icon)`
  color: ${props => props.theme.colors.white};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Container = styled(FlexboxGrid)`
  @media screen and (max-width: 484px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const NumberContainer = styled(FlexboxGrid.Item)`
  @media screen and (max-width: 484px) {
    width: auto;
  }
`;

export const TitleNumber = styled.p`
  text-align: right;
  color: ${props => props.theme.colors.black};
  font-size: ${props => props.theme.fonts.bigSubtitle};
  font-weight: bold;
  padding: 0px;

  @media screen and (max-width: 484px) {
    text-align: center;
    margin: 5px 0;
  }
`;

export const RoundedFlexbox = styled(FlexboxGrid.Item)<{ color: string }>`
  background: ${props => props.color};
  border-radius: 50%;
  height: 50px;
  width: 50px;
`;

export const FooterP = styled.p`
  text-align: right;
  font-size: ${props => props.theme.fonts.small};
`;

export const SpanPositive = styled.span`
  color: ${props => props.theme.colors.green};
`;

export const SpanNegative = styled.span`
  color: ${props => props.theme.colors.red};
`;
