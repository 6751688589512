import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FacebookIcon from '../../assets/icons/facebook.svg';
import GoogleIcon from '../../assets/icons/google.svg';
import InstagramIcon from '../../assets/icons/instagram.png';
import TwitterIcon from '../../assets/icons/x.svg';
import TikTokIcon from '../../assets/icons/TikTok.svg';
import VideoIcon from '../../assets/icons/video.svg';
import DefaultImage from '../../assets/images/default.jpg';
import { useAuth } from '../../context/AuthContext';
import {
  PostContainer,
  ContentContainer,
  ImageContainer,
  ImageTextContainer,
  NetworkIcon,
  PostImage,
  PostText,
  TextContainer,
  VideoImage,
  Networkdiv,
} from './post2.style';

interface Props {
  post: Post;
  setDataPostSelected?: React.Dispatch<React.SetStateAction<Post | null>>;
}

const iconMapper = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  google: GoogleIcon,
  tiktok: TikTokIcon,
};

const Post2 = ({ post, setDataPostSelected }: Props) => {
  const handleClickDescription = (clickedPost: Post) =>
    setDataPostSelected?.(clickedPost);
  const { user } = useAuth();
  const [displayPremiumPlan, setDisplayPremiumPlan] = useState<boolean>(false);
  const history = useHistory();
  useEffect(() => {
    const { subscriptionStatus, invoiceStatus, hasAccess } =
      user?.companies?.[0]?.payment ?? {};

    if (
      (subscriptionStatus === 'active' && invoiceStatus === 'paid') || (subscriptionStatus === 'active_lifetime' && invoiceStatus === 'paid') ||
      (subscriptionStatus === 'trialing' &&
        invoiceStatus === 'paid' &&
        hasAccess) ||
      (subscriptionStatus === 'trial_expired' && !hasAccess)
    ) {
      setDisplayPremiumPlan(true);
    } else {
      setDisplayPremiumPlan(false);
    }
  }, [user]);

  const handleLoadMore = () => {
    history.push(`/user-panel/posts`);
  };
  return (
    <PostContainer>
      <Networkdiv>
        <NetworkIcon src={iconMapper[post.network]} alt="Network" />
      </Networkdiv>
      <ContentContainer>
        <ImageTextContainer onClick={handleLoadMore}>
          <ImageContainer onClick={() => handleClickDescription(post)}>
            {post?.media.image ? (
              <PostImage src={post.media.image} alt="Post" />
            ) : (
              <PostImage src={DefaultImage} alt="Default Post" />
            )}
            {post.media.type === 'video' && (
              <VideoImage src={VideoIcon} alt="Video" />
            )}
          </ImageContainer>
          <TextContainer>
            <PostText>{post.text}</PostText>
          </TextContainer>
        </ImageTextContainer>
        {/* <DateContainer>
            <PostDate>{formatDate(post.publishedAt)}</PostDate>
          </DateContainer> */}
        {/* <CtaContainer>
          
              <IconContainer>
              <Iconstyle
                src={eye} // Make sure to import or define your icon source
                alt="Delete"
              />
            </IconContainer>
            
          {(post.network === 'facebook' || post.network === 'twitter') &&
            (deleteSocialPost.isLoading ? (
              <Loader content="Deleting..." />
            ) : (
             
              <button
                type="button"
                onClick={() => deletePostConfirmation(post.id, post.network)}>
                
              <IconContainer>
                <Iconstyle
                  src={DeleteIcon} // Make sure to import or define your icon source
                  alt="Delete"
                />
              </IconContainer>

              </button>
            ))}
          </CtaContainer> */}
      </ContentContainer>
    </PostContainer>
  );
};

export default Post2;
