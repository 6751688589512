import { Container } from './OutlineButton.style';

interface Props {
  title: string;
  onClick: () => void;
}

const OutlineButton = ({ title, onClick }: Props) => {
  return (
    <Container appearance="ghost" onClick={onClick}>
      {title}
    </Container>
  );
};

export default OutlineButton;
