import { Link } from 'react-router-dom';
import { Col, Row } from 'rsuite';
import styled from 'styled-components';

interface InnerBgRowProps {
  hei: number;
  wid: number;
  cusHei: string;
  cusDir: string;
}

export const BgRow = styled(Row)`
  background-image: linear-gradient(to right, #db1d5a, #ea4435);
  background: ${props => props.image};
  padding: 40px;
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${props => props.theme.breakpoints.xs} {
    min-height: inherit;
    height: 100vh;
    justify-content: initial;
    position:fixed;
    
  }
     
`;

export const InnerBgRow = styled(Row)<InnerBgRowProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* justify-content: end; */
  align-items: center;
  background: #ffffff;
  box-shadow: -8px 8px 12px 0px #00000033;
  width: 100%;
  max-width: 1216px;
  max-height: ${props => (props.wid > 1660 ? `730px` : `${props.hei}px`)};
  margin: 0 auto;
  border-radius: 30px;
  padding: 30px;
  gap: 40px;

  @media (max-width: 1370px) {
    max-height: 700px;
  }

  @media ${props => props.theme.breakpoints.mm} {
    /* justify-content: space-between; */
    max-width: 580px;
    border-radius: 20px;
  }

  @media ${props => props.theme.breakpoints.xs} {
    justify-content: ${props => (props.cusDir ? props.cusDir : '')};
    max-width: 375px;
    padding: 15px;
    gap: 10px;
  }

  @media (max-width: 385px) {
    max-width: 335px;
    max-height: none;
  }
     @media (max-width: 375px) {
      max-height: 98%;
  }
`;

export const FormWapper = styled(Row)`
  display: flex;
  width: 100%;
  gap: 20px;
  position: relative;

  @media (max-width: 1370px) {
    gap: 0px;
  }
`;

export const ImageCarouselContainer = styled('div')`
  display: flex;
  width: 40%;

  @media ${props => props.theme.breakpoints.mm} {
    display: none;
  }
`;

export const LoginFormContainer = styled('div')`
  display: flex;
  width: 60%;

  @media ${props => props.theme.breakpoints.mm} {
    width: 100%;
  }
`;

export const HeaderWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  @media ${props => props.theme.breakpoints.mm} {
    justify-content: center;
    margin-bottom: 20px;
  }
     @media (max-width: 375px) {
         margin-bottom: 0px;

    }
`;

export const OuterLink = styled.a<{ href?: string }>``;

export const BackLogoContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: end;
  padding-right: 30px;
  gap: 10px;
  width: 15%;
  cursor: pointer;

  span {
    font-weight: bold;
    color: #000000;
    font-size: 16px;
    font-family: 'Comformaa', Helvetica, Sans-Serif;
  }

  &:hover {
    text-decoration: none;
    list-style: none;
  }

  @media ${props => props.theme.breakpoints.mm} {
    justify-content: flex-start;
    width: 100%;
    padding: 40px 95px;
  }

  @media (max-width: 689px) {
    padding: 40px 8px;
  }

  @media ${props => props.theme.breakpoints.xs} {
    padding: 0px 20px 30px;
  }
`;

export const BackLogo = styled.img`
  cursor: pointer;
`;

export const HeightCol = styled(Col)`
  height: 220px;
`;

export const ImgLogo = styled.img`
  width: 80%;
  display: block;

  @media ${props => props.theme.breakpoints.mm} {
    width: 85%;
  }

  @media ${props => props.theme.breakpoints.xs} {
    width: 65%;
    margin: 0 auto;
  }
     @media (max-width: 375px) {
         width: 40%;

    }
`;

export const ImgDevices = styled.img`
  width: 100%;
  display: none; /* Hide the image by default */

  @media ${props => props.theme.breakpoints.lg},
    ${props => props.theme.breakpoints.md} {
    /* Show the image on screens with md and lg (md size screens) */
    width: 100%;
    display: block;
  }
  @media ${props => props.theme.breakpoints.xl} {
    transform: translate(190px, 0px) scale(0.89);
  }
`;

export const Footer = styled.div`
  font-size: ${props => props.theme.fonts.paragraph};
  display: flex;
  margin: 35px 0;

  @media ${props => props.theme.breakpoints.xs} {
    flex-direction: column;

    span {
      display: none;
    }
  }

  a {
    text-decoration: none;
    color: ${props => props.theme.colors.lightBlack};
    margin: 0px 5px;
    border-bottom: 1px solid ${props => props.theme.colors.lightBlack};

    @media ${props => props.theme.breakpoints.xs} {
      border-bottom: none;
      margin: 5px 5px;
    }

    &:hover {
      color: ${props => props.theme.colors.red};
      border-bottom: 1px solid ${props => props.theme.colors.red};

      @media ${props => props.theme.breakpoints.xs} {
        border-bottom: none;
      }
    }
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  min-height: 100vh;

  @media ${props => props.theme.breakpoints.sm},
    ${props => props.theme.breakpoints.xs} {
    min-height: 0;
  }
`;

export const Paragraph = styled(Link)`
  width: 100%;
  font-size: ${props => props.theme.fonts.paragraph};
  color: #fff;
  font-weight: 900;

  a {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
    color: #7a7a7a;
  }
  @media ${props => props.theme.breakpoints.mm} {
    margin: 25px;
    text-align: center;
    font-size: 1.1rem;
  }

  @media ${props => props.theme.breakpoints.xs} {
    margin: 25px 0px 0px;
  }

  @media (max-width: 390px) {
    font-size: 0.9rem;
  }
`;
