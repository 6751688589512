/* eslint-disable no-console */

import axios from '../lib/axios';
import { notificationError } from '../lib/notification';

export const uploadMultipleFiles = async ({
  selectedFiles,
  type = 'image',
  socialNetworks = [],
}: {
  selectedFiles: File[];
  type?: string;
  socialNetworks?: string[];
}) => {
  const formData = new FormData();

  selectedFiles.forEach(file => {
    formData.append('files', file);
  });

  formData.append('functionality', type);
  formData.append('network', socialNetworks.join(' '));

  try {
    const response = await axios.post('/media/multiple', formData);
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: 'The images could not be uploaded',
    });
    return error;
  }
};

export const uploadTikTokVideoFiles = async ({
  selectedFiles,
  type = 'video',
  socialNetworks = 'tiktok',
}: {
  selectedFiles: File;
  type?: string;
  socialNetworks?: string | undefined;
}) => {
  const formData = new FormData();

  formData.append('file', selectedFiles);

  formData.append('functionality', type);
  formData.append('network', socialNetworks);

  try {
    const response = await axios.post('/media', formData);
    return response;
  } catch (error) {
    console.log('error', error);
    notificationError({
      title: 'Error',
      description: 'The selected video could not be uploaded',
    });
    return error;
  }
};

export const removeTikTokPost = async (
  postId: number,
): Promise<ScheduledPosts> => {
  try {
    const { data } = await axios.delete(`/posts/${postId}`);
    return data;
  } catch (err) {
    if (err.response) {
      // Handle the error response from axios
      throw new Error(`${err.response.data.message || err.message}`);
    } else if (err instanceof Error) {
      // Handle other types of errors
      throw new Error(` ${err.message}`);
    } else {
      throw new Error(`Failed to remove the post.`);
    }
  }
};

export const fetchTikTok = async (): Promise<Post[]> => {
  try {
    const { data } = await axios.get('/tiktok');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchScheduledTikTok = async (): Promise<ScheduledTikTok[]> => {
  try {
    const { data } = await axios.get('/tiktok/scheduled');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const removeTikTok = async (
  postId: number,
): Promise<ScheduledTikTok> => {
  try {
    const { data } = await axios.delete(`/tiktok/${postId}`);
    return data;
  } catch (err) {
    throw new Error(err);
  }
};

export const fetchFollowers = async (): Promise<Followers[]> => {
  try {
    const { data } = await axios.get('/tools/followers');
    return data;
  } catch (err) {
    throw new Error(err);
  }
};
