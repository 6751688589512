import { Modal } from 'rsuite';
import styled from 'styled-components';

export const RecentImageSelect = styled.div<{
  urlImage: string;
}>`
  background-image: url(${props => props.urlImage});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-radius: 20px;

  cursor: pointer;
  margin: 10px auto;

  width: 100%;
  height: 200px;

  line-height: 200px;
`;

export const ModalPosition = styled(Modal)`
  @media ${props => props.theme.breakpoints.xs},
    ${props => props.theme.breakpoints.sm} {
    position: absolute;
    bottom: 0px;
    width: 100%;

    div.rs-modal-dialog {
      margin: 0px;
    }
  }
`;

export const PreviewVideo = styled.div`
  width: 100%;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  video {
    width: 100%;
  }
`;
