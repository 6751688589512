import axios from '../lib/axios';
import { notificationError, notificationWithNodes } from '../lib/notification';

export const loginEmail = async (email: string, password: string) => {
  try {
    const response = await axios.post('/auth/login', {
      email,
      password,
    });

    return response;
  } catch (error) {
    if (error?.response?.data?.message.startsWith('User')) {
      notificationWithNodes({
        type: 'info',
        title: 'info',
        description: (
          <>
            {error?.response?.data?.message} Login sucessfulyy.
            {' '}
            {/* <a href="/" rel="noopener noreferrer"> */}
            {/* </a>{' '} */}
            
          </>
        ),
        duration: 9000,
      });
    } else {
      notificationError({
        title: 'Error',
        description: error?.response?.data?.message || '',
      });
    }
    
    return error;
  }
};

export const loginFacebook = async (
  accessToken: string,
  userID: string | undefined,
) => {
  try {
    // console.log("accesstoken",accessToken)
    // console.log("userid",userID)
    const response = await axios.post('/auth/account-facebook', {
      accessToken,
      userID,
    });

    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: error?.response?.data?.message || '',
    });
    return error;
  }
};

export const loginGoogle = async (tokenId: string) => {
  try {
    // console.log("token id is ",tokenId)
    const response = await axios.post('/auth/account-google', {
      tokenId,
    });
    // console.log("resonse is ",response)
    return response;
  } catch (error) {
    notificationError({
      title: 'Error',
      description: error?.response?.data?.message || '',
    });
    return error;
  }
};
