/* eslint-disable @typescript-eslint/no-unused-vars */

import { Formik, Field, Form, FieldProps } from 'formik';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import axios from '../../lib/axios';
import { notification, notificationError } from '../../lib/notification';
import {
  ContentContainer,
  BackButtonnext,
  BackButtondiv,
  NextButtonContainer,
  Button,
  Title,
  Subtitle,
  StyledErrorMessage,
  DinabiteText,
  Subtitlemobile,
  Titlembile,
  MobileImageContent,
} from './DinabiteReset.style';
import {
  Container,
  LeftPane,
  RightPane,
  ImageContainer,
  StyledImage,
} from '../DinabiteSignupPanel/globaldinabite.style';
import signupImage from '../../assets/images/forgetpassword.png';
import PasswordInput from '../../components/inputs/PasswordInput/PasswordInput';
import { resetPasswordSchema } from '../../components/forms/formSignUp/validation';

const ResetPassword = () => {
  const [secure, setSecure] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const token = location.search.split('=')[1];

  const validatePassword = (pass: string) => {
    let value = 0;
    const lowerCaseRegExp = /[a-z]/;
    const capitalRegExp = /[A-Z]/;
    const numbersRegExp = /[0-9]/;

    if (lowerCaseRegExp.test(pass)) value += 1;
    if (capitalRegExp.test(pass)) value += 1;
    if (numbersRegExp.test(pass)) value += 1;
    if (pass.length >= 8) value += 1;

    setSecure(value);
  };

  const initialValues = useMemo(
    () => ({
      password: '',
      repeatPassword: '',
    }),
    [],
  );

  const handleSubmit = async (values: { password: string }) => {
    setIsLoading(true);
    try {
      await axios.post('/auth/change-password', {
        token,
        password: values.password,
      });
      notification({
        type: 'success',
        title: 'Success',
        description: 'You can log in with the new password',
      });
      history.push('/login');
    } catch (err) {
      notificationError({
        title: 'Error',
        description:
          err?.response?.data?.message || 'Could not update password',
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <LeftPane>
        <Title>Reset Password</Title>
        <Subtitle>Enter New Password here</Subtitle>
        <Titlembile>Reset Password</Titlembile>
        <MobileImageContent src={signupImage} alt="Step 1 Image" />
        <Subtitlemobile>Enter New Password here</Subtitlemobile>

        <Formik
          initialValues={initialValues}
          validationSchema={resetPasswordSchema}
          onSubmit={handleSubmit}>
          {({
            touched,
            errors,
            values,
            handleSubmit: formikHandleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={formikHandleSubmit}>
              <ContentContainer>
                <Field name="password">
                  {({ field, form }: FieldProps) => (
                    <PasswordInput
                      signUp
                      placeholder="Password"
                      {...field}
                      onChange={e => {
                        field.onChange(e); // Update the form's state
                        form.setFieldTouched(field.name); // Mark the field as touched
                        form.validateField(field.name); // Trigger validation
                      }}
                      onBlur={field.onBlur}
                      // error={form.touched.password && form.errors.password ? form.errors.password : undefined}
                    />
                  )}
                </Field>

                <StyledErrorMessage>
                  {touched.password && errors.password ? errors.password : ''}
                </StyledErrorMessage>

                <Field name="repeatPassword">
                  {({ field, form }: FieldProps) => (
                    <PasswordInput
                      signUp
                      placeholder="Confirm Password"
                      {...field}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      onKeyUp={() => validatePassword(values.repeatPassword)}
                      // error={touched.repeatPassword && errors.repeatPassword ? errors.repeatPassword : undefined}
                    />
                  )}
                </Field>
                <StyledErrorMessage>
                  {touched.repeatPassword && errors.repeatPassword
                    ? errors.repeatPassword
                    : ''}
                </StyledErrorMessage>

                <NextButtonContainer>
                  <Button type="submit" disabled={isLoading || isSubmitting}>
                    {isLoading ? 'Loading...' : 'Reset Password'}
                  </Button>
                </NextButtonContainer>

                <BackButtondiv>
                  <BackButtonnext
                    type="button"
                    onClick={() => history.goBack()}
                    disabled={isLoading || isSubmitting}>
                    {isLoading ? 'Loading...' : 'Back'}
                  </BackButtonnext>
                </BackButtondiv>
              </ContentContainer>
            </Form>
          )}
        </Formik>
      </LeftPane>

      <RightPane>
        <ImageContainer>
          <StyledImage src={signupImage} alt="Illustration" />
          <DinabiteText>
            Dinabite is your personal assistant for growing your online presence
            with powerful analytics and easy post scheduling. Ready to rock?
          </DinabiteText>
        </ImageContainer>
      </RightPane>
    </Container>
  );
};

export default ResetPassword;
