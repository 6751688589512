import styled from 'styled-components';

export const CarouselSliderContainer = styled('div')`
  width: 506px;
  /* height: 574px; */

  .rs-carousel,
  .rs-carousel-slider-item {
    background: #efefef;
    border-radius: 20px;
    height: 443px;
  }

  .rs-carousel-label-wrapper input:checked ~ label {
    background: #dc2058 !important;
    width: 32px;
    height: 10px;
    border-radius: 15px;
  }

  .rs-carousel-placement-top.rs-carousel-shape-bar,
  .rs-carousel-placement-bottom.rs-carousel-shape-bar .rs-carousel-label {
    background: #d0d0d0;
    width: 32px;
    height: 10px;
    border-radius: 15px;
  }

  .rs-carousel-placement-bottom .rs-carousel-toolbar {
    bottom: 60px;
  }

  .rs-carousel-placement-bottom .rs-carousel-toolbar ul {
    gap: 20px;
  }
`;

export const CardWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;

  img {
    object-fit: contain;
  }

  span {
    position: absolute;
    color: #db1d5a;
    font-weight: 700;
    font-size: 20px;
    line-height: 22.3px;
    bottom: 18px;
    text-align: center;
  }
`;
