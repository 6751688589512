import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Inter', sans-serif;
  text-align: center;
  margin-top: 50px;
`;

export const Message = styled.h2`
  margin-top: 180px;
  color: #dd2256;
  font-size: 34px; /* Default font size for larger screens */
  line-height: 1.5; /* Improves readability */

  @media (max-width: 1200px) {
    font-size: 28px; /* Slightly smaller for large tablets */
    margin-top: 150px; /* Adjust margin for better spacing */
  }

  @media (max-width: 900px) {
    font-size: 24px; /* Smaller for medium tablets */
    margin-top: 120px;
  }

  @media (max-width: 600px) {
    font-size: 18px; /* Mobile devices */
    margin-top: 100px;
  }

  @media (max-width: 380px) {
    font-size: 16px; /* Extra small devices */
    margin-top: 80px;
    text-align: center; /* Center text for small screens */
  }
`;


export const ErrorMessage = styled.h2`
  color: #dd2256;
`;

export const BackButtonDiv = styled.div`
  display: none;

  @media (max-width: 780px) {
    display: flex;
    width: 80%;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 1000;
    min-height: 100px;
  }

  @media (max-width: 380px) {
    min-height: 90px;
    padding: 0;
  }
`;

export const BackButtonNext = styled.button`
  color: #fff;
//   width: 140px;
  height: 50px;
  border: 1px solid #dd2256;
  border-radius: 20px;
  font-size: 20px;
  cursor: pointer;
  margin: 0 10px;
  background: linear-gradient(180deg, #dd2256 0%, #e43c39 100%);
    width:90%;
  &:hover {
    background-color: #c51e4a;
  }

  @media (max-width: 780px) {
    // width: 160px;
    height: 40px;
    font-size: 18px;
    border-radius: 15px;
    margin: 0 5px;
  }

  @media (max-width: 380px) {
    // width: 120px;
    height: 40px;
    font-size: 14px;
    border-radius: 15px;
    margin-bottom: 20px;
  }
`;

export const DesktopBackButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  align-items: flex-start;
  z-index: 1000;
  border-radius: 15px 15px 0 0;
  min-height: 170px;
  margin: 0 auto;
  padding-top: 20px;
  margin-top: 10rem;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const NextButtonContainer = styled.div`
  width: 100%;
  border-radius: 15px;
  margin: 0 auto;
`;

export const Button = styled.button`
  background-color: #fff;
  color: #dd2256;
  padding: 15px 90px;
  border-radius: 15px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: 2px solid #dd2256;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease;

  &:hover {
    background: linear-gradient(180deg, #DD2256 0%, #E43C39 100%);
    color: #fff;
    transform: translateY(-2px); /* Slight lift on hover */
  }

  &:active {
    transform: translateY(0); /* Reset lift on click */
  }

  @media (max-width: 1200px) {
    padding: 12px 80px;
  }

  @media (max-width: 945px) {
    padding: 12px 60px;
  }

  @media (max-width: 780px) {
    padding: 10px 20px;
    font-size: 14px;
  }
`;
