import { Button } from 'rsuite';
import styled from 'styled-components';

export const Container = styled(Button)<{ color?: boolean }>`
  background-color: ${props =>
    props.color ? props.theme.colors.white : props.theme.colors.newThemeColor};
  border: 2px solid
    ${props =>
      props.color
        ? props.theme.colors.newThemeColor
        : props.theme.colors.white};
  color: ${props =>
    props.color ? props.theme.colors.newThemeColor : props.theme.colors.white};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.32);
  width: 193px;
  height: 43px;
  border-radius: 10px;
  font-weight: bold;

  &:disabled {
    background-color: ${props => props.theme.colors.grey} !important;
    color: ${props => props.theme.colors.darkGrey};
  }
`;
