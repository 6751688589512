import styled from 'styled-components';

export const Subtitle = styled.p`
  color: ${props => props.theme.colors.darkGrey};
  font-size: ${props => props.theme.fonts.paragraph};
  font-weight: 550;
`;

export const InputText = styled.p`
  color: ${props => props.theme.colors.darkGrey};
  font-size: ${props => props.theme.fonts.paragraph};
  font-weight: 550;
  margin-top:15px;
  margin-bottom:5px;
`;


export const TextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  resize: none;
  margin: 15px 0 5px 0;
  padding: 10px;
  font-size: ${props => props.theme.fonts.paragraph};
`;

export const Input = styled.input`
  width: 100%;
  box-sizing: border-box;
  resize: none;
  margin: 15px 0 5px 0;
  padding: 5px 10px;
  font-size: ${props => props.theme.fonts.paragraph};
`;

export const InputFile = styled.input`
  font-size: ${props => props.theme.fonts.paragraph};
  margin: 5px 0px;
  margin-bottom: 5px;
`;

export const ErrorMessage = styled.p`
  color: ${props => props.theme.colors.red};
  text-align: right;
  margin-bottom: 5px;
`;
